import { Dropzone, FileItem, FileValidated } from "@dropzone-ui/react";
import { DropzoneProps } from "@dropzone-ui/react/build/components/dropzone/components/Dropzone/DropzoneProps";
import { FileItemProps } from "@dropzone-ui/react/build/components/file-item/components/FileItem/FileItemProps";
import _ from "lodash";
import {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormContext } from "../../../../models/FormContext";
import FormInputProps from "../../models/FormInputProps";
import FormValidation from "../FormValidation";

export type FormAttachmentProps = FormInputProps &
  DropzoneProps & {
    fileItemProps?: FileItemProps;
  };

const FormAttachment: FC<FormAttachmentProps> = ({
  id,
  name,
  showRequired,
  onChange,
  invalid,
  validationMessage,
  infoProps,
  fileItemProps,
  requiredMessage,
  onAfterChange,
  ...props
}: FormAttachmentProps): ReactElement => {
  const [value, setValue] = useState<FileValidated[]>([]);
  const { initialRequest, setRequest } = useContext(FormContext);

  const onChangeHandler = useMemo(() => {
    return (files: FileValidated[]) => {
      if (onChange !== undefined) {
        onChange(files);
      } else if (setRequest !== undefined) {
        setRequest((previousRequest) => {
          return _.setWith(
            _.clone(previousRequest),
            name,
            files.map((file) => ({ ...file })),
            _.clone
          );
        });
      } else {
        throw new Error(`No onChange event defined for input field: ${name}`);
      }
      if (onAfterChange) {
        onAfterChange();
      }
    };
  }, [name, onAfterChange, onChange, setRequest]);

  const handleOnChange = useMemo(() => {
    return (files: FileValidated[]) => {
      setValue(files);
      onChangeHandler(files);
    };
  }, [onChangeHandler]);

  useEffect(() => {
    setValue((_.get(initialRequest, name) as FileValidated[]) || []);
  }, [name, initialRequest]);

  return (
    <div>
      <div className={invalid ? "form-invalid-input" : ""}>
        <Dropzone {...props} onChange={handleOnChange} value={value}>
          {value.map((file) => (
            <FileItem
              alwaysActive
              info
              resultOnTooltip
              {...fileItemProps}
              {...file}
              key={file.id}
              onDelete={(targetFileId: number | string | undefined) => {
                const { onDelete } = fileItemProps || {};
                if (onDelete !== undefined) {
                  onDelete(targetFileId);
                } else if (setRequest !== undefined) {
                  setRequest((previousRequest) => {
                    return _.setWith(
                      _.clone(previousRequest),
                      name,
                      [
                        ...(
                          (_.get(previousRequest, name) as FileValidated[]) ||
                          []
                        ).filter(({ id: fileId }) => fileId !== targetFileId),
                      ],
                      _.clone
                    );
                  });
                } else {
                  throw new Error(
                    `No fileItemProps.onDelete event defined for input field: ${name}`
                  );
                }
              }}
            />
          ))}
        </Dropzone>
      </div>
      {validationMessage && <FormValidation message={validationMessage} />}
    </div>
  );
};

FormAttachment.defaultProps = {
  fileItemProps: {},
};

export default FormAttachment;
