/* eslint-disable react/jsx-props-no-spreading */
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Alert, Button, Container, Form, Table } from "react-bootstrap";
import { useTable } from "react-table";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import ManagedContent from "../../../../components/ManagedContent";
import useLoading from "../../../../hooks/useLoading";
import FormInputOption from "../../../../models/salesforce/FormInputOption";
import SalesforceManagedContent from "../../../../models/salesforce/ManagedContent";
import SalesforceResponse from "../../../../models/salesforce/SalesforceResponse";
import FormInputOptionService from "../../../../services/forms/FormInputOptionService";

export type FormInputOptionsProps = {
  options: FormInputOption[];
  setOptions: (
    getOptions: (previousOptions: FormInputOption[]) => FormInputOption[]
  ) => void;
};

const FormInputOptions: FC<FormInputOptionsProps> = ({
  options,
  setOptions,
}: FormInputOptionsProps): ReactElement => {
  const loading = useLoading();
  const [optionToDelete, setOptionToDelete] = useState<FormInputOption>();

  const addOption = () => {
    setOptions((previousOptions: FormInputOption[]) => {
      const maxOrder = _.max(previousOptions.map(({ order }) => order));
      return [
        ...previousOptions,
        {
          id: uuid(),
          label: "",
          value: "",
          order: maxOrder ? maxOrder + 1 : 1,
          clearIdBeforeSave: true,
        },
      ];
    });
  };

  const updateOptionFieldById = useCallback(
    (
      targetId: string,
      fieldName: string,
      fieldValue: string | SalesforceManagedContent
    ) => {
      setOptions((previousOptions: FormInputOption[]) => {
        const targetOption = previousOptions.find(({ id }) => id === targetId);
        if (targetOption === undefined) {
          return previousOptions;
        }
        return _.sortBy(
          [
            ...previousOptions.filter(({ id }) => id !== targetId),
            {
              ...targetOption,
              [fieldName]:
                fieldName === "order"
                  ? parseInt(fieldValue as string, 10)
                  : fieldValue,
            },
          ],
          "order"
        );
      });
    },
    [setOptions]
  );

  const handleConfirmDeleteFormInputOption = async (
    formInputOption: FormInputOption
  ) => {
    loading(async () => {
      try {
        setOptionToDelete(undefined);
        let deleteSuccess = false;
        let responses: SalesforceResponse[] = [];
        if (formInputOption.clearIdBeforeSave) {
          // option is not saved in salesforce so just remove from list
          deleteSuccess = true;
        } else {
          responses = await FormInputOptionService.deleteFormInputOptionById(
            formInputOption.id as string
          );
          if (responses.every(({ success }) => success)) {
            deleteSuccess = true;
          }
        }
        if (deleteSuccess) {
          toast.success("Successfully deleted form input option");
          setOptions((previousOptions: FormInputOption[]) => {
            const targetOption = previousOptions.find(
              ({ id }) => id === formInputOption.id
            );
            if (targetOption === undefined) {
              return previousOptions;
            }
            return previousOptions.filter(
              ({ id }) => id !== formInputOption.id
            );
          });
        } else {
          setOptionToDelete(formInputOption);
          [
            { message: "Failed to delete form input option" },
            ...responses.flatMap(({ errors }) => errors || []),
          ].forEach(({ message }) => {
            toast.error(message);
          });
        }
      } catch {
        toast.error("Failed to delete form input option");
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Order",
        accessor: ({ id, order }: FormInputOption) => {
          const fieldName = "order";
          return (
            <Form.Select
              id={`${id}-${fieldName}`}
              name={fieldName}
              value={order}
              required
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                event.preventDefault();
                event.stopPropagation();
                const { value: fieldValue } = event.target;
                updateOptionFieldById(id as string, fieldName, fieldValue);
              }}
            >
              <option value="" disabled>
                Select an option...
              </option>
              {_.range(
                1,
                (_.max([
                  options.length,
                  _.max(options.map(({ order: optionOrder }) => optionOrder)),
                ]) ?? 0) + 1
              ).map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Form.Select>
          );
        },
      },
      {
        Header: "Label",
        accessor: ({ id, label }: FormInputOption) => {
          const fieldName = "label";
          return (
            <>
              {typeof label === "object" ? (
                <ManagedContent
                  editable
                  managedContent={label as SalesforceManagedContent}
                  onUpdate={(updatedLabel) => {
                    updateOptionFieldById(
                      id as string,
                      fieldName,
                      updatedLabel
                    );
                  }}
                />
              ) : (
                <Form.Control
                  id={`${id}-${fieldName}`}
                  name={fieldName}
                  type="text"
                  value={label as string}
                  required
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    event.preventDefault();
                    event.stopPropagation();
                    const { value: fieldValue } = event.target;
                    updateOptionFieldById(id as string, fieldName, fieldValue);
                  }}
                />
              )}
            </>
          );
        },
      },
      {
        Header: "Value",
        accessor: ({ id, value }: FormInputOption) => {
          const fieldName = "value";
          return (
            <Form.Control
              id={`${id}-${fieldName}`}
              name={fieldName}
              type="text"
              value={value}
              required
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                event.preventDefault();
                event.stopPropagation();
                const { value: fieldValue } = event.target;
                updateOptionFieldById(id as string, fieldName, fieldValue);
              }}
            />
          );
        },
      },
      {
        Header: "Action",
        accessor: (option: FormInputOption) => (
          <div className="d-flex justify-content-center">
            <Button variant="danger" onClick={() => setOptionToDelete(option)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </div>
        ),
      },
    ],
    [options, updateOptionFieldById]
  );

  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: options,
  });

  return (
    <>
      <Form.Label>Options</Form.Label>
      <Container>
        <Table striped bordered hover size="sm" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {rows.length === 0 ? (
              <tr>
                <td colSpan={columns.length}>
                  <Alert variant="info" className="text-center mb-0">
                    No Options Added
                  </Alert>
                </td>
              </tr>
            ) : (
              <>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </Table>
        <div className="d-flex justify-content-center">
          <Button
            variant="info"
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
              event.stopPropagation();
              addOption();
            }}
          >
            Add Option
          </Button>
        </div>
      </Container>
      {optionToDelete && (
        <ConfirmationModal
          id="form-input-options-delete-form-input-option-modal"
          confirmButtonProps={{
            variant: "danger",
          }}
          show={optionToDelete !== undefined}
          title="Delete Form Input Option"
          body="Are you sure you want to delete this form input option?"
          onCancel={() => setOptionToDelete(undefined)}
          onConfirm={() => handleConfirmDeleteFormInputOption(optionToDelete)}
        />
      )}
    </>
  );
};

export default FormInputOptions;
