enum MOCRoles {
  DELEGATED_APPROVER = "Delegated Approver",
  APPROVER = "Approver",
}

export const MOC_APPROVER_ROLES: string[] = [
  MOCRoles.DELEGATED_APPROVER,
  MOCRoles.APPROVER,
];

export default MOCRoles;
