import AppPath from "../../../components/App/models/AppPath";

class EUSHIPPath extends AppPath {
  public static readonly EUSHIP = new AppPath(
    "euship",
    "Student Health Insurance Inquiry",
    "/euship"
  );
}
export default EUSHIPPath;
