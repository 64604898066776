import _ from "lodash";
import {
  ChangeEvent,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormSelectProps as BootstrapFormSelectProps,
  Form,
} from "react-bootstrap";
import { ComponentProps } from "../../../../../../models";
import { FormContext } from "../../../../models/FormContext";
import FormInputProps from "../../models/FormInputProps";
import FormOption from "../../models/FormOption";
import { buildOnChangeHandler } from "../../utils/FormInputUtil";

export type FormSelectProps = FormInputProps &
  Omit<BootstrapFormSelectProps, "id" | "name"> & {
    options: FormOption[];
  };

const renderOptionLabel = (label: string | ComponentProps): string => {
  if (typeof label === "string") {
    return label;
  }
  return label?.content as string;
};

const FormSelect: FC<FormSelectProps> = ({
  id,
  name,
  label,
  type,
  showRequired,
  options,
  optionsEndpoint,
  onChange,
  onAfterChange,
  ...props
}: FormSelectProps): ReactElement => {
  const [value, setValue] = useState<string>("");
  const { initialRequest, setRequest } = useContext(FormContext);

  const selectOptions = useMemo(() => {
    const newOptions = [];
    if (options.length > 0 && options[0].value !== "") {
      newOptions.push({
        value: "",
        label: "Select an option...",
        disabled: true,
      });
    }
    newOptions.push(...options);
    return newOptions;
  }, [options]);

  const onChangeHandler = useMemo(() => {
    return buildOnChangeHandler(onChange, setRequest, name, onAfterChange);
  }, [name, onAfterChange, onChange, setRequest]);

  const handleOnChange = useMemo(() => {
    return (event: ChangeEvent<HTMLSelectElement>) => {
      const {
        target: { value: newValue },
      } = event;
      setValue(newValue);
      onChangeHandler(event);
    };
  }, [onChangeHandler]);

  useEffect(() => {
    setValue(_.get(initialRequest, name) as string);
  }, [initialRequest, name]);

  return (
    <Form.Select
      {...props}
      id={id}
      name={name}
      value={value}
      onChange={handleOnChange}
    >
      {selectOptions.map(
        ({ value: optionValue, label: optionLabel, disabled }) => (
          <option key={optionValue} value={optionValue} disabled={disabled}>
            {renderOptionLabel(optionLabel)}
          </option>
        )
      )}
    </Form.Select>
  );
};

export default FormSelect;
