import _ from "lodash";
import moment from "moment";
import {
  FC,
  ReactElement,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormContext } from "../../../../models/FormContext";
import FormInputProps from "../../models/FormInputProps";
import { parseDate } from "./utils/DateUtil";

type MinDate = ReactDatePickerProps["minDate"];
type MaxDate = ReactDatePickerProps["maxDate"];

export type FormDateProps = FormInputProps &
  Omit<ReactDatePickerProps, "minDate" | "maxDate"> &
  Partial<{
    minDate: string | MinDate;
    maxDate: string | MaxDate;
  }>;

const FormDate: FC<FormDateProps> = ({
  id,
  name,
  showRequired,
  onChange,
  invalid,
  onAfterChange,
  minDate: minDateProp,
  maxDate: maxDateProp,
  ...props
}: FormDateProps): ReactElement => {
  const [value, setValue] = useState<Date>();
  const { initialRequest, setRequest } = useContext(FormContext);

  const minDate: MinDate = useMemo(() => {
    if (_.isString(minDateProp)) {
      return parseDate(minDateProp);
    }
    return minDateProp;
  }, [minDateProp]);

  const maxDate: MaxDate = useMemo(() => {
    if (_.isString(maxDateProp)) {
      return parseDate(maxDateProp);
    }
    return maxDateProp;
  }, [maxDateProp]);

  const onChangeHandler = useMemo(() => {
    return (newValue: Date, event: SyntheticEvent<HTMLInputElement, Event>) => {
      event.preventDefault();
      event.stopPropagation();
      if (onChange !== undefined) {
        onChange(newValue, event);
      } else if (setRequest !== undefined) {
        setRequest((previousRequest) => {
          return _.setWith(_.clone(previousRequest), name, newValue, _.clone);
        });
      } else {
        throw new Error(`No onChange event defined for input field: ${name}`);
      }
      if (onAfterChange) {
        onAfterChange(event);
      }
    };
  }, [name, onAfterChange, onChange, setRequest]);

  const handleOnChange = useMemo(() => {
    return (newValue: Date, event: SyntheticEvent<HTMLInputElement, Event>) => {
      setValue(newValue);
      onChangeHandler(newValue, event);
    };
  }, [onChangeHandler]);

  useEffect(() => {
    const initialValue = _.get(initialRequest, name);
    if (typeof initialValue === "string") {
      setValue(moment(initialValue).toDate());
    } else {
      setValue(initialValue as Date);
    }
  }, [initialRequest, name]);

  return (
    <DatePicker
      {...props}
      id={id}
      className={`form-control${
        props?.className ? ` ${props?.className}` : ""
      }`}
      selected={value}
      onChange={handleOnChange}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};

FormDate.defaultProps = {
  onChange: undefined,
  minDate: undefined,
  maxDate: undefined,
};

export default FormDate;
