import React, { FC, ReactElement, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Loading from "../../modules/shared/src/components/Loading";
import "./styles.scss";

const Home: FC = (): ReactElement => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <section aria-labelledby="home" className="section">
      <Container>
        {loading && <Loading />}
        <h1 id="page-title">MyEmory Home</h1>
        <p id="page-body">
          Welcome to MyEmory. Please click on a link above for the form you wish
          to submit.
        </p>
      </Container>
    </section>
  );
};

export default Home;
