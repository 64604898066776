import { RowData, Table } from "@tanstack/react-table";
import { ReactElement } from "react";
import { Alert } from "react-bootstrap";

export type TableRowNoRecordsFoundProps<TData extends RowData> = {
  table: Table<TData>;
};

const TableRowNoRecordsFound = <T,>({
  table,
}: TableRowNoRecordsFoundProps<T>): ReactElement => {
  if (table.getRowModel().rows.length === 0) {
    return (
      <tr>
        <td className="m-0 p-0" colSpan={table.getVisibleFlatColumns().length}>
          <Alert variant="info" className="mb-0">
            No Records Found
          </Alert>
        </td>
      </tr>
    );
  }
  return <></>;
};

export default TableRowNoRecordsFound;
