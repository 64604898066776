import { AxiosError } from "axios";
import {
  ChangeEvent,
  FC,
  FocusEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Button, Container } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { Form } from "../../../shared/src/components";
import {
  addClassName,
  removeClassName,
  setPropByIds,
} from "../../../shared/src/components/Component/ComponentUtil";
import {
  getRequestByKey,
  resetRequestByKey,
} from "../../../shared/src/components/Form";
import { FormRequest } from "../../../shared/src/components/Form/models/FormRequest";
import useLoading from "../../../shared/src/hooks/useLoading";
import { ComponentProps } from "../../../shared/src/models";
import { initializeComponents } from "../../../shared/src/utils/ComponentInitializer";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { EventFormRequest } from "../../models/EventFormRequest";
import EventReservationRequest from "../../models/api/EventReservationRequest";
import EventService from "../../services/EventService";
import SpeedtypeService from "../../services/SpeedtypeService";
import metadata from "./metadata.json";
import "./styles.scss";

const REQUEST_KEY = "aae-events";

const AAEEventsReservationRequestForm: FC = (): ReactElement => {
  const loading = useLoading();
  const [initialRequest, setInitialRequest] = useState<EventFormRequest>({});
  const [form, setForm] = useState<ComponentProps>();
  const [redirect, setRedirect] = useState<boolean>(false);

  const handleSpeedtypeMessage = (
    speedtypeType: string,
    displayNotFound: boolean,
    displayValid: boolean
  ) => {
    setForm((previousForm) =>
      setPropByIds(
        [`finish-${speedtypeType}-notFound`],
        "className",
        undefined,
        previousForm,
        (currentValue) => {
          const currentClassNames = (currentValue as string) || "";
          if (displayNotFound === true) {
            return removeClassName(currentClassNames, "d-none");
          }
          return addClassName(currentClassNames, "d-none");
        }
      )
    );
    setForm((previousForm) =>
      setPropByIds(
        [`finish-${speedtypeType}-confirmed`],
        "className",
        undefined,
        previousForm,
        (currentValue) => {
          const currentClassNames = (currentValue as string) || "";
          if (displayValid === true) {
            return removeClassName(currentClassNames, "d-none");
          }
          return addClassName(currentClassNames, "d-none");
        }
      )
    );
  };

  const validateSpeedType = useCallback(
    async (event: FocusEvent<HTMLSelectElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const { value } = event.target;
      let speedtypeType = "";
      switch (event.target.id) {
        case "finish-speedtypeEvent":
          speedtypeType = "speedtypeEvent";
          break;
        case "finish-speedtypeAlcohol":
          speedtypeType = "speedtypeAlcohol";
          break;
        default:
          break;
      }
      if (!value) {
        handleSpeedtypeMessage(speedtypeType, false, false);
      } else if (value) {
        try {
          const res = await SpeedtypeService.getSpeedtypeById(value);
          if (res) {
            handleSpeedtypeMessage(speedtypeType, false, true);
          }
        } catch (error) {
          handleSpeedtypeMessage(speedtypeType, true, false);
        }
      }
    },
    []
  );

  const onAfterChangeClientType = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      const clientTypeForSpeedtype = [
        "EMORY_UNIVERSITY_OR_EMORY_HEALTHCARE",
        "ADVANCEMENT_AND_ALUMNI_ENGAGEMENT",
        "STUDENT_PRODUCED_EVENT",
      ];
      const clientTypeForTreasurerAdvisor = ["STUDENT_PRODUCED_EVENT"];
      setForm((previousForm) => {
        return setPropByIds(
          ["finish-row02"],
          "className",
          undefined,
          previousForm,
          (currentValue) => {
            const currentClassNames = (currentValue as string) || "";
            if (clientTypeForSpeedtype.includes(value)) {
              return "mt-3";
            }
            return addClassName(currentClassNames, "d-none");
          }
        );
      });
      setForm((previousForm) => {
        return setPropByIds(
          ["finish-row03"],
          "className",
          undefined,
          previousForm,
          (currentValue) => {
            const currentClassNames = (currentValue as string) || "";
            if (clientTypeForSpeedtype.includes(value)) {
              return "mt-3";
            }
            return addClassName(currentClassNames, "d-none");
          }
        );
      });
      setForm((previousForm) => {
        return setPropByIds(
          ["finish-row04"],
          "className",
          undefined,
          previousForm,
          (currentValue) => {
            const currentClassNames = (currentValue as string) || "";
            if (clientTypeForSpeedtype.includes(value)) {
              return "mt-3";
            }
            return addClassName(currentClassNames, "d-none");
          }
        );
      });
      setForm((previousForm) => {
        return setPropByIds(
          ["finish-row05"],
          "className",
          undefined,
          previousForm,
          (currentValue) => {
            const currentClassNames = (currentValue as string) || "";
            if (clientTypeForSpeedtype.includes(value)) {
              return "mt-3";
            }
            return addClassName(currentClassNames, "d-none");
          }
        );
      });
      setForm((previousForm) => {
        return setPropByIds(
          ["finish-row06"],
          "className",
          undefined,
          previousForm,
          (currentValue) => {
            const currentClassNames = (currentValue as string) || "";
            if (clientTypeForTreasurerAdvisor.includes(value)) {
              return "mt-3";
            }
            return addClassName(currentClassNames, "d-none");
          }
        );
      });
      setForm((previousForm) =>
        setPropByIds(
          ["finish-row07"],
          "className",
          undefined,
          previousForm,
          (currentValue) => {
            const currentClassNames = (currentValue as string) || "";
            if (clientTypeForTreasurerAdvisor.includes(value)) {
              return removeClassName(currentClassNames, "d-none");
            }
            return addClassName(currentClassNames, "d-none");
          }
        )
      );
      setForm((previousForm) =>
        setPropByIds(
          ["finish-row08"],
          "className",
          undefined,
          previousForm,
          (currentValue) => {
            const currentClassNames = (currentValue as string) || "";
            if (clientTypeForTreasurerAdvisor.includes(value)) {
              return removeClassName(currentClassNames, "d-none");
            }
            return addClassName(currentClassNames, "d-none");
          }
        )
      );
      setForm((previousForm) => {
        return setPropByIds(
          ["finish-treasurerAdvisorName"],
          "required",
          undefined,
          previousForm,
          () => {
            if (clientTypeForTreasurerAdvisor.includes(value)) {
              return true;
            }
            return false;
          }
        );
      });
      setForm((previousForm) => {
        return setPropByIds(
          ["finish-treasurerAdvisorEmail"],
          "required",
          undefined,
          previousForm,
          () => {
            if (clientTypeForTreasurerAdvisor.includes(value)) {
              return true;
            }
            return false;
          }
        );
      });
      setForm((previousForm) => {
        return setPropByIds(
          ["finish-treasurerAdvisorPhone"],
          "required",
          undefined,
          previousForm,
          () => {
            if (clientTypeForTreasurerAdvisor.includes(value)) {
              return true;
            }
            return false;
          }
        );
      });
      setInitialRequest(getRequestByKey(REQUEST_KEY));
    },
    []
  );

  const onAfterChangeRadio = useCallback(
    (affectedRow: string, affectedInput: string) =>
      (event: ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setForm((previousForm) => {
          return setPropByIds(
            [affectedRow],
            "className",
            undefined,
            previousForm,
            (currentValue) => {
              const currentClassNames = (currentValue as string) || "";
              if (value === "yes") {
                return "mt-3";
              }
              return addClassName(currentClassNames, "d-none");
            }
          );
        });
        setForm((previousForm) => {
          return setPropByIds(
            [affectedInput],
            "required",
            undefined,
            previousForm,
            () => {
              if (value === "yes") {
                return true;
              }
              return false;
            }
          );
        });
        setInitialRequest(getRequestByKey(REQUEST_KEY));
      },
    []
  );

  const onAfterChangeAdditionalServices = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value: optionValue, checked },
      } = event;
      switch (optionValue) {
        case "SECURITY":
          setForm((previousForm) =>
            setPropByIds(
              ["serviceOfficersCountRow"],
              "className",
              undefined,
              previousForm,
              (currentValue) => {
                const currentClassNames = (currentValue as string) || "";
                if (checked === true) {
                  return removeClassName(currentClassNames, "d-none");
                }
                if (checked === false) {
                  return addClassName(currentClassNames, "d-none");
                }
                return addClassName(currentClassNames, "d-none");
              }
            )
          );
          setForm((previousForm) =>
            setPropByIds(
              ["eventInformation-numberOfOfficersRequired"],
              "required",
              undefined,
              previousForm,
              () => {
                if (checked === true) {
                  return true;
                }
                return false;
              }
            )
          );
          break;
        case "CHIAVARI_CHAIRS":
          setForm((previousForm) =>
            setPropByIds(
              ["chiavariChairsCountRow"],
              "className",
              undefined,
              previousForm,
              (currentValue) => {
                const currentClassNames = (currentValue as string) || "";
                if (checked === true) {
                  return removeClassName(currentClassNames, "d-none");
                }
                if (checked === false) {
                  return addClassName(currentClassNames, "d-none");
                }
                return addClassName(currentClassNames, "d-none");
              }
            )
          );
          setForm((previousForm) =>
            setPropByIds(
              ["eventInformation-numberOfChairsRequired"],
              "required",
              undefined,
              previousForm,
              () => {
                if (checked === true) {
                  return true;
                }
                return false;
              }
            )
          );
          break;
        case "POSTER_BOARDS":
          setForm((previousForm) =>
            setPropByIds(
              ["posterBoardsCountRow"],
              "className",
              undefined,
              previousForm,
              (currentValue) => {
                const currentClassNames = (currentValue as string) || "";
                if (checked === true) {
                  return removeClassName(currentClassNames, "d-none");
                }
                if (checked === false) {
                  return addClassName(currentClassNames, "d-none");
                }
                return addClassName(currentClassNames, "d-none");
              }
            )
          );
          setForm((previousForm) =>
            setPropByIds(
              ["eventInformation-numberOfPosterBoardsRequired"],
              "required",
              undefined,
              previousForm,
              () => {
                if (checked === true) {
                  return true;
                }
                return false;
              }
            )
          );
          break;
        default:
          break;
      }
    },
    []
  );

  const onAfterChangeMaxLength = useCallback(
    async (event: FocusEvent<HTMLSelectElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const { value } = event.target;
      if (value.length === 255) {
        setForm((previousForm) =>
          setPropByIds(
            [`${event.target.id}-charactersRemaining`],
            "className",
            undefined,
            previousForm,
            (currentValue) => {
              const currentClassNames = (currentValue as string) || "";
              return removeClassName(currentClassNames, "d-none");
            }
          )
        );
      } else if (value.length < 255) {
        setForm((previousForm) =>
          setPropByIds(
            [`${event.target.id}-charactersRemaining`],
            "className",
            undefined,
            previousForm,
            (currentValue) => {
              const currentClassNames = (currentValue as string) || "";
              return addClassName(currentClassNames, "d-none");
            }
          )
        );
      }
    },
    []
  );

  const showValidationMessages = useCallback(() => {
    const formAsHtml = document
      .getElementById("aae-events")
      ?.getElementsByTagName("form")[0];
    const previousRequest = getRequestByKey(REQUEST_KEY);
    Array.prototype.forEach.call(formAsHtml, (element) => {
      if (
        (element.type === "radio" &&
          element.required &&
          !previousRequest[element.name]) ||
        (element.required && !element.value)
      ) {
        setForm((previousForm) =>
          setPropByIds(
            [`${element.id}-validationMessage`],
            "className",
            undefined,
            previousForm,
            (currentValue) => {
              const currentClassNames = (currentValue as string) || "";
              return removeClassName(currentClassNames, "d-none");
            }
          )
        );
      }
      if (element.id === "clientInformation-emailConfirm") {
        setForm((previousForm) =>
          setPropByIds(
            ["clientInformation-emailConfirm-noMatchMessage"],
            "className",
            undefined,
            previousForm,
            (currentValue) => {
              const currentClassNames = (currentValue as string) || "";
              if (previousRequest.email !== previousRequest.emailConfirm) {
                return addClassName(currentClassNames, "d-none");
              }
              return removeClassName(currentClassNames, "d-none");
            }
          )
        );
        if (document) {
          document
            .getElementById("clientInformation-emailConfirm")
            ?.scrollIntoView({
              behavior: "smooth",
              block: "end",
            });
        }
      }
    });
  }, []);

  useEffect(() => {
    initializeComponents();
    let formFromJson = metadata as unknown as ComponentProps;

    formFromJson = setPropByIds(
      ["clientInformation-clientType"],
      "onAfterChange",
      onAfterChangeClientType,
      formFromJson
    );

    formFromJson = setPropByIds(
      ["eventInformation-isFoodOrDrinkServed"],
      "onAfterChange",
      onAfterChangeRadio("eventInformation-row8", "eventInformation-caterer"),
      formFromJson
    );

    formFromJson = setPropByIds(
      ["eventInformation-isAlcoholServed"],
      "onAfterChange",
      onAfterChangeRadio("eventInformation-row10", "eventInformation-alcohol"),
      formFromJson
    );

    formFromJson = setPropByIds(
      ["eventInformation-roomSetupDescription"],
      "onAfterChange",
      onAfterChangeMaxLength,
      formFromJson
    );

    formFromJson = setPropByIds(
      ["eventInformation-isAVRequired"],
      "onAfterChange",
      onAfterChangeRadio(
        "eventInformation-row14",
        "eventInformation-avDescription"
      ),
      formFromJson
    );

    formFromJson = setPropByIds(
      ["eventInformation-additionalServices"],
      "onAfterChange",
      onAfterChangeAdditionalServices,
      formFromJson
    );

    formFromJson = setPropByIds(
      ["finish-speedtypeEvent"],
      "onBlur",
      validateSpeedType,
      formFromJson
    );

    formFromJson = setPropByIds(
      ["finish-additionalInformation"],
      "onAfterChange",
      onAfterChangeMaxLength,
      formFromJson
    );

    formFromJson = setPropByIds(
      ["finish-speedtypeAlcohol"],
      "onBlur",
      validateSpeedType,
      formFromJson
    );

    setForm(formFromJson);
  }, [
    onAfterChangeMaxLength,
    onAfterChangeAdditionalServices,
    onAfterChangeClientType,
    onAfterChangeRadio,
    validateSpeedType,
  ]);

  const handleSubmitRequest = useCallback(
    (formRequest: FormRequest) => {
      loading(async () => {
        // save current form state
        setInitialRequest(formRequest);
        // create backend request from UI request object
        const eventReservationRequest = new EventReservationRequest(
          formRequest
        );
        // send API request
        try {
          await EventService.saveEvent(eventReservationRequest);
          setRedirect(true);
        } catch (error) {
          let errorMessage = (error as AxiosError)?.message;
          errorMessage = errorMessage ? ` (${errorMessage})` : "";
          toast.error(
            `Oops, something went wrong. Please click the "Submit" button again. If the problem persists, please call 404-727-6400 or email eaa@emory.edu.${errorMessage}`,
            { autoClose: 10000 }
          );
        }
      });
    },
    [loading]
  );

  useEffect(() => {
    resetRequestByKey(REQUEST_KEY);
  }, []);

  return (
    <section id="aae-events">
      <Header />
      <Container>
        <Form
          content={form}
          initialRequest={initialRequest}
          requestKey={REQUEST_KEY}
          onSubmitRequest={handleSubmitRequest}
          buttons={
            <div
              id="submit-button-row"
              className="d-flex justify-content-center mt-3"
            >
              <Button
                type="submit"
                className="me-2"
                style={{ marginBottom: "2em" }}
                onClick={() => showValidationMessages()}
              >
                Submit
              </Button>
            </div>
          }
        />
      </Container>
      {redirect && <Redirect to="/forms/aae-events/success" />}
      <Footer />
    </section>
  );
};

export default AAEEventsReservationRequestForm;
