import moment from "moment";

export const toYYYYMMDD = (date: moment.MomentInput): string | undefined => {
  if (!date) {
    return undefined;
  }
  return moment(date).format("YYYY-MM-DD");
};

export default {
  toYYYYMMDD,
};
