import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ComponentProps } from "../../models";
import ComponentService from "../../services/forms/ComponentService";
import useLoading from "../useLoading";
import useSafeAsync from "../useSafeAsync";

const useForm = (
  formId: string | undefined
): {
  form: ComponentProps | undefined;
  setForm: Dispatch<SetStateAction<ComponentProps | undefined>>;
  formSections: ComponentProps[];
  refreshForm: () => void;
  getFormSectionById: (formSectionId?: string) => ComponentProps | undefined;
} => {
  const safeAsync = useSafeAsync();
  const [form, setForm] = useState<ComponentProps>();
  const loading = useLoading();

  const refreshForm = useCallback(() => {
    loading(() =>
      safeAsync(ComponentService.getFormComponentById(formId as string)).then(
        (response) => setForm(response as ComponentProps)
      )
    );
  }, [formId, loading, safeAsync]);

  const getFormSectionById = useCallback(
    (formSectionId?: string) => {
      return ((form?.content as ComponentProps[]) || []).find(
        ({ salesforceObject }) => salesforceObject?.id === formSectionId
      );
    },
    [form]
  );

  useEffect(() => {
    if (formId) {
      refreshForm();
    }
  }, [formId, refreshForm]);

  return {
    form,
    setForm,
    formSections: (form?.content as ComponentProps[]) || [],
    refreshForm,
    getFormSectionById,
  };
};

export default useForm;
