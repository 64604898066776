import { FC, ReactElement, useEffect, useMemo } from "react";
import ReactModal from "react-modal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {
  AAEEventsAppRoutes,
  AAEEventsPath,
} from "../../modules/aae-events/models/AAEEventsRoutes";
import { AppRouter } from "../../modules/shared/src/components";
import { AppHeaderLinkType } from "../../modules/shared/src/components/AppHeader/AppHeaderLink";
import {
  useAppInfo,
  useLoggedInUser,
  useMyEmory,
} from "../../modules/shared/src/hooks";
import useLoadingContext from "../../modules/shared/src/hooks/useLoadingContext";
import { MyEmoryContext } from "../../modules/shared/src/models/MyEmoryContext";
import { PageNotFound } from "../../modules/shared/src/pages";
import { initializeComponents } from "../../modules/shared/src/utils/ComponentInitializer";
import Home from "../../pages";
import AppPath from "./models/AppPath";
import "./styles.scss";
import {
  getMOCAppHeaderLinks,
  getMOCAppRoutes,
} from "../../modules/moc/models/MOCRoutes";
import { ShibbService } from "../../modules/shared/src/services";
import Person from "../../modules/shared/src/models/Person";
import useLoading from "../../modules/shared/src/hooks/useLoading";
import ComponentManager from "../../modules/shared/src/utils/ComponentManager";
import MOCCustomComponents from "../../modules/moc/models/MOCCustomComponents";
import useCurrentUser from "../../hooks/useCurrentUser";
import { MOC_APPROVER_ROLES } from "../../modules/moc/models/MOCRoles";
import {
  EUSHIPAppHeaderLinks,
  EUSHIPAppRoutes,
} from "../../modules/euship/models/EUSHIPRoutes";
import EUSHIPPath from "../../modules/euship/models/EUSHIPPath";
import {
  QTMAppHeaderLinks,
  QTMAppRoutes,
} from "../../modules/qtm/models/QTMRoutes";

if (process.env.NODE_ENV !== "test") {
  ReactModal.setAppElement("#root");
}

const App: FC = (): ReactElement => {
  const { loadingWrapper } = useLoadingContext();
  const { version: appVersion } = useAppInfo();
  const { ppid } = useLoggedInUser();
  const currentUser = useCurrentUser();
  const myEmoryContextValue = useMyEmory(ppid, "MyEmory");
  const loading = useLoading();
  const currentUserName =
    [currentUser.firstName, currentUser.lastName]
      .filter((x) => !!x)
      .join(" ") ?? currentUser.netId;
  const { roleNames } = myEmoryContextValue;
  const isApprover = useMemo(
    () =>
      roleNames?.some((role) =>
        MOC_APPROVER_ROLES.includes(role as string)
      ) as boolean,
    [roleNames]
  );
  const isStudent = useMemo(() => {
    return currentUser.contactType === "Student";
  }, [currentUser.contactType]);

  useEffect(() => {
    initializeComponents();
    ComponentManager.addComponents(
      {
        ...MOCCustomComponents,
      },
      true
    );
  }, []);

  useEffect(() => {
    loading(ShibbService.getLoggedInUser()).then((response) => {
      const person = response as Person;
      const userPpid = person?.ppid ?? "";
      if (
        !userPpid &&
        !(
          window.location.pathname.endsWith(
            AAEEventsPath.AAEEventsReservationRequestForm.path
          ) || window.location.pathname.endsWith(EUSHIPPath.EUSHIP.path)
        )
      ) {
        window.location.href = AppPath.ShibbolethLogin.path;
      }
    });
  }, [loading]);

  const links = useMemo(() => {
    const newLinks = [
      {
        ...AppPath.Home,
        type: AppHeaderLinkType.LINK,
      },
      ...EUSHIPAppHeaderLinks,
      ...QTMAppHeaderLinks,
    ];
    if (ppid && !isStudent) {
      newLinks.push(...getMOCAppHeaderLinks(isApprover));
    }
    return newLinks;
  }, [isApprover, ppid, isStudent]);

  const routes = useMemo(() => {
    const newRoutes = [
      {
        ...AppPath.Home,
        exact: true,
        render: () => <Home />,
      },
      ...getMOCAppRoutes(isApprover),
      ...AAEEventsAppRoutes,
      ...EUSHIPAppRoutes,
      ...QTMAppRoutes,
    ];
    newRoutes.push({
      ...AppPath.PageNotFound,
      render: () => <PageNotFound />,
    });
    return newRoutes;
  }, [isApprover]);

  return loadingWrapper(
    <MyEmoryContext.Provider value={myEmoryContextValue}>
      <AppRouter
        title="MyEmory"
        username={currentUserName}
        version={appVersion}
        links={links}
        routes={routes}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </MyEmoryContext.Provider>
  );
};

export default App;
