import _ from "lodash";
import { Layout } from "react-grid-layout";
import { v4 as uuid } from "uuid";
import { ComponentProps } from "../../../models";
import { MAX_COLUMNS_PER_SECTION_ROW } from "./FormGridConfiguration";

export type FormComponentProps = ComponentProps & {
  content?: string | FormComponentProps | FormComponentProps[];
  layout: Layout;
  columnId?: string;
  rowId?: string;
  featureId?: string;
};

export const createFormSectionLayout = (y: number): Layout => {
  return {
    i: uuid(),
    x: 0,
    y,
    w: 1,
    h: 1,
    isResizable: false,
  };
};

export const convertFormRowsToFormComponentProps = (
  rows: ComponentProps[],
  featureId: string
): FormComponentProps[] => {
  return rows.flatMap((row, rowIndex) => {
    const columns = row?.content as FormComponentProps[];
    return columns.map((column, columnIndex) => {
      return {
        ...(column?.content as FormComponentProps),
        columnId: column?.salesforceObject?.id as string,
        rowId: row?.salesforceObject?.id as string,
        featureId,
        layout: {
          i: uuid(),
          x: (columnIndex * MAX_COLUMNS_PER_SECTION_ROW) / columns.length,
          y: rowIndex,
          w:
            columns.length === 0
              ? MAX_COLUMNS_PER_SECTION_ROW
              : MAX_COLUMNS_PER_SECTION_ROW / columns.length,
          h: 1,
        },
      };
    });
  });
};

export const convertFormSectionsToFormComponentProps = (
  formSections: ComponentProps[],
  featureId: string,
  yOffset = 0
): FormComponentProps[] => {
  return formSections.map((formSection, formSectionIndex) => {
    return {
      ...formSection,
      content: convertFormRowsToFormComponentProps(
        formSection?.content as FormComponentProps[],
        featureId
      ),
      layout: createFormSectionLayout(formSectionIndex + yOffset),
    };
  });
};

export const sortFormComponentPropsArray = (
  formComponentPropsArray: FormComponentProps[]
): FormComponentProps[] => {
  return _.sortBy(formComponentPropsArray, [
    ({ layout: { y } }) => y,
    ({ layout: { x } }) => x,
  ]);
};
