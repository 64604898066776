const FormManagerPathSegment = {
  FORM_MANAGER: "/form-manager",
  FORM: "/form",
  FORM_ID_PARAM: "/:formId?",
  FORM_SECTION: "/form-section",
  FORM_SECTION_ID_PARAM: "/:formSectionId?",
};

const optional = (arg: string): string => {
  return `(${arg})?`;
};

const concat = (...args: string[]): string => {
  return args.join("");
};

class FormManagerPath {
  static readonly FORM_MANAGER_APP_ROUTE = FormManagerPathSegment.FORM_MANAGER;

  static readonly FORM_EDITOR_APP_ROUTE = concat(
    FormManagerPathSegment.FORM_MANAGER,
    optional(FormManagerPathSegment.FORM),
    FormManagerPathSegment.FORM_ID_PARAM,
    optional(FormManagerPathSegment.FORM_SECTION),
    FormManagerPathSegment.FORM_SECTION_ID_PARAM
  );

  static formManager(): string {
    return this.FORM_MANAGER_APP_ROUTE;
  }

  static form(formId: string | undefined): string {
    return concat(
      FormManagerPathSegment.FORM_MANAGER,
      FormManagerPathSegment.FORM,
      formId ? `/${formId}` : ""
    );
  }

  static formSection(formId: string, formSectionId: string): string {
    return [
      FormManagerPathSegment.FORM_MANAGER,
      FormManagerPathSegment.FORM,
      `/${formId}`,
      FormManagerPathSegment.FORM_SECTION,
      `/${formSectionId}`,
    ].join("");
  }
}

export default FormManagerPath;
