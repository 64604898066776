import { FC, ReactElement } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import FormInputProps from "../../models/FormInputProps";

export type FormButtonProps = FormInputProps & ButtonProps;

const FormButton: FC<FormButtonProps> = ({
  id,
  name,
  showRequired,
  onChange,
  invalid,
  validationMessage,
  infoProps,
  label,
  type,
  requiredMessage,
  onAfterChange,
  ...props
}: FormButtonProps): ReactElement => {
  return (
    <div className="d-flex justify-content-center">
      <Button id={id || name} {...props}>
        {label as string}
      </Button>
    </div>
  );
};

export default FormButton;
