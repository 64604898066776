import AppPath from "../../../components/App/models/AppPath";

class QTMPath extends AppPath {
  public static readonly QTM = new AppPath(
    "qtm",
    "QTM Student Onboarding",
    "/qtm"
  );
}
export default QTMPath;
