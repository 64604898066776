import React, { FC, ReactElement } from "react";
import { Anchor, Image, Navbar } from "react-bootstrap";
import emoryLogo from "./assets/eu-logo-shield.svg";
import "./styles.scss";

const Header: FC = (): ReactElement => {
  return (
    <nav id="aae-nav">
      <Navbar.Brand as={Anchor} href="https://millerward.emory.edu/">
        <Image src={emoryLogo} alt="Home" />
      </Navbar.Brand>
      <ul id="aae-nav-links">
        <li className="aae-nav-link">
          <a href="https://together.emory.edu/give">Give Now</a>
        </li>
        <li className="aae-nav-link">
          <a href="https://www.engage.emory.edu/s/1705/21/interior.aspx?sid=1705&gid=3&pgid=15630">
            Volunteer
          </a>
        </li>
        <li className="aae-nav-link">
          <a href="https://together.emory.edu/events">Events</a>
        </li>
        <li className="aae-nav-link">
          <a href="https://together.emory.edu/networks">Networks</a>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
