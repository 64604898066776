import { Dispatch, SetStateAction, createContext } from "react";
import Case, { MOCStatus } from "../../../../../models/salesforce/Case";

export type SubmissionTableContextProps = {
  data: Case[];
  setData: Dispatch<SetStateAction<Case[]>>;
  isApprover: boolean;
  statuses: MOCStatus[];
};

export const SubmissionsTableContext =
  createContext<SubmissionTableContextProps>({
    data: [],
    setData: () => {},
    isApprover: false,
    statuses: [],
  });
