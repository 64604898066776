/* eslint-disable react/jsx-props-no-spreading */
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Component } from "../../../../components";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { EditableFormSectionContext } from "../../models/EditableFormSectionContext";
import { FormComponentProps } from "../../models/FormComponentProps";
import FormElementEditModal from "../FormElementEditModal";

export type FormElementProps = {
  id: string;
  element: FormComponentProps;
  handleAddEdit: (element: FormComponentProps) => void;
  handleDelete: () => void;
};

const FormElement: FC<FormElementProps> = ({
  id,
  element,
  handleAddEdit,
  handleDelete,
}: FormElementProps): ReactElement => {
  const { setGridLayoutDraggable } = useContext(EditableFormSectionContext);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [modalElement, setModalElement] = useState<FormComponentProps>({
    tag: "",
    props: {
      type: "",
    },
    layout: {
      i: "",
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    },
    salesforceObject: {},
  });

  useEffect(() => {
    setGridLayoutDraggable(!showDeleteModal && !showEditModal);
  }, [setGridLayoutDraggable, showDeleteModal, showEditModal]);

  const openEditModal = () => {
    setModalElement((previousModalElement) => ({
      ...previousModalElement,
      ...element,
    }));
    setShowEditModal(true);
  };

  const handleConfirmDeleteFormElement = () => {
    setShowDeleteModal(false);
    handleDelete();
  };

  const buttons = (
    <div className="align-self-center">
      <Button
        variant="danger"
        onClick={() => setShowDeleteModal(true)}
        className="me-1"
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </Button>
      <Button variant="info" onClick={openEditModal}>
        <FontAwesomeIcon icon={faPencilAlt} />
      </Button>
    </div>
  );

  return (
    <>
      {element.tag ? (
        <Col className="position-relative p-2">
          <Row>
            <Col>
              <Component
                {...element}
                props={{
                  ...((element?.props as Record<string, unknown>) || {}),
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center mt-2">{buttons}</Col>
          </Row>
        </Col>
      ) : (
        <div className="d-flex justify-content-center h-100">{buttons}</div>
      )}
      {showDeleteModal && (
        <ConfirmationModal
          id={`${id}-form-element-delete-modal`}
          confirmButtonProps={{
            variant: "danger",
          }}
          show={showDeleteModal}
          title="Delete Form Element"
          body="Are you sure you want to delete this form element?"
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={() => handleConfirmDeleteFormElement()}
        />
      )}
      {showEditModal && (
        <FormElementEditModal
          id={`${id}-form-element-edit-modal`}
          show={showEditModal}
          onCancel={() => setShowEditModal(false)}
          onSubmit={(newElement) => {
            handleAddEdit(newElement);
          }}
          element={modalElement}
        />
      )}
    </>
  );
};

export default FormElement;
