import _ from "lodash";
import {
  ChangeEvent,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormCheckProps as BootstrapFormCheckProps,
  Form,
} from "react-bootstrap";
import { ComponentProps } from "../../../../../../models";
import { renderContent } from "../../../../../Component/ComponentUtil";
import { FormContext } from "../../../../models/FormContext";
import FormValidation from "../FormValidation";

export interface FormCheckBoxProps extends BootstrapFormCheckProps {
  id: string;
  name: string;
  value: string;
  required?: boolean;
  optionsEndpoint?: string;
  invalid?: boolean;
  validationMessage?: string | ReactElement;
  onAfterChange?: (event?: ChangeEvent<HTMLInputElement>) => void;
  labelLocation?: "before";
}

const FormCheckBox: FC<FormCheckBoxProps> = ({
  id,
  name,
  required,
  onChange,
  label,
  optionsEndpoint,
  invalid,
  validationMessage,
  onAfterChange,
  labelLocation,
  ...optionProps
}: FormCheckBoxProps): ReactElement => {
  const [checked, setChecked] = useState<boolean>(false);
  const { initialRequest, setRequest } = useContext(FormContext);

  useEffect(() => {
    if (_.get(initialRequest, name) !== undefined) {
      setChecked(_.get(initialRequest, name) as boolean);
    }
  }, [initialRequest, name]);

  const handleOnChange = useMemo(() => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      } else if (setRequest) {
        setRequest((previousRequest) => {
          let newValue: string | boolean = event.target.value;
          if (
            optionProps.type === "checkbox" ||
            optionProps.type === "switch"
          ) {
            newValue = event.target.checked;
          }
          return _.setWith(_.clone(previousRequest), name, newValue, _.clone);
        });
      }
      if (onAfterChange) {
        onAfterChange(event);
      }
      setChecked(event.target.checked);
    };
  }, [name, onAfterChange, onChange, optionProps.type, setRequest]);

  const labelContent = useMemo(() => {
    if (
      typeof label === "object" &&
      !((label as unknown as ComponentProps)?.props as Record<string, unknown>)
        ?.id
    ) {
      return <>{(label as unknown as ComponentProps)?.content as string}</>;
    }

    return renderContent(
      label as unknown as string | ComponentProps | ComponentProps[]
    );
  }, [label]);

  const content = useMemo(() => {
    const formCheckProps = {
      ...optionProps,
      id,
      name,
      required,
      checked,
      onChange: handleOnChange,
    };

    if (labelLocation === "before") {
      return (
        <span>
          <Form.Label htmlFor={id}>{labelContent}</Form.Label>
          <Form.Check {...formCheckProps} />
        </span>
      );
    }

    return <Form.Check {...formCheckProps} label={labelContent} />;
  }, [
    checked,
    handleOnChange,
    id,
    labelContent,
    labelLocation,
    name,
    optionProps,
    required,
  ]);

  return (
    <div className="form-check-box">
      <div className={invalid ? "form-invalid-input" : ""}>{content}</div>
      {validationMessage && <FormValidation message={validationMessage} />}
    </div>
  );
};

FormCheckBox.defaultProps = {
  required: false,
  optionsEndpoint: undefined,
  invalid: false,
  validationMessage: undefined,
  onAfterChange: undefined,
  labelLocation: undefined,
};

export default FormCheckBox;
