import FormInput from "../../../models/salesforce/FormInput";
import SalesforceResponse from "../../../models/salesforce/SalesforceResponse";
import FormSalesforceObjectService from "../FormSalesforceObjectService";

class FormInputService extends FormSalesforceObjectService {
  private static readonly OBJECT_NAME = "form-inputs";

  /**
   * Save form input
   * @param formInput
   * @param depth depth of salesforce nested objects
   * @returns array of salesforce responses
   */
  public static async saveFormInput(
    formInput: FormInput,
    featureId: string | undefined,
    depth: number | undefined = undefined
  ): Promise<SalesforceResponse[]> {
    const updatedFormInput = { ...formInput };
    if (typeof formInput.label === "string") {
      updatedFormInput.label = {
        id: formInput?.labelId,
        content: formInput.label,
        featureId,
      };
    }
    if (updatedFormInput?.options && updatedFormInput.options.length > 0) {
      updatedFormInput.options = updatedFormInput.options.map((option) => {
        return {
          ...option,
          label:
            typeof option?.label === "object"
              ? {
                  ...option.label,
                  featureId,
                }
              : option?.label,
        };
      });
    }
    return super.save<FormInput>(this.OBJECT_NAME, [updatedFormInput], depth);
  }

  /**
   * Delete form input by id
   * @param formInputId
   * @param depth depth of salesforce nested objects
   * @returns array of salesforce responses
   */
  public static async deleteFormInputById(
    formInputId: string,
    depth: number | undefined = undefined
  ): Promise<SalesforceResponse[]> {
    return super.deleteById(this.OBJECT_NAME, [formInputId], depth);
  }
}

export default FormInputService;
