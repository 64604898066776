import React, { FC, ReactElement, useEffect, useMemo, useState } from "react";
import { Breadcrumb, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Form } from "../../../../components";
import { FormRequest } from "../../../../components/Form/models/FormRequest";
import useForm from "../../../../hooks/useForm";
import { ComponentProps } from "../../../../models";
import { FormEditorContext } from "../../models/FormEditorContext";
import FormManagerPath from "../../models/FormManagerPath";
import EditableForm from "../EditableForm";
import EditableFormSection from "../EditableFormSection";
import "./styles.scss";

const FORM_ID_PATH_INDEX = 3;
const FORM_SECTION_ID_PATH_INDEX = 5;

const FormEditor: FC = (): ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const initialRequest = useMemo(() => ({}), []);
  const [request, setRequest] = useState<FormRequest>({});
  const [formId, setFormId] = useState<string>("");
  const [formSectionId, setFormSectionId] = useState<string>();
  const [formSection, setFormSection] = useState<ComponentProps>();
  const { form, refreshForm, getFormSectionById } = useForm(formId);

  useEffect(() => {
    if (formSectionId) {
      setFormSection(getFormSectionById(formSectionId));
    }
  }, [formSectionId, getFormSectionById]);

  useEffect(() => {
    if (location?.pathname) {
      const pathParams = location?.pathname.split("/");

      // {host}/form-manager/form/:formId/form-section/:formSectionId
      const formIdParam = pathParams[FORM_ID_PATH_INDEX];
      const formSectionIdParam = pathParams[FORM_SECTION_ID_PATH_INDEX];

      if (pathParams.length <= FORM_ID_PATH_INDEX || !formIdParam) {
        // if formId is not defined
        history.push(FormManagerPath.formManager());
      }

      if (
        pathParams.length === FORM_SECTION_ID_PATH_INDEX ||
        (pathParams.length > FORM_SECTION_ID_PATH_INDEX && !formSectionIdParam)
      ) {
        // if formSectionId is not defined
        history.push(FormManagerPath.form(formIdParam));
      }

      if (pathParams.length > FORM_SECTION_ID_PATH_INDEX + 1) {
        // {host}/form-manager/form/:formId/form-section/:formSectionId/*
        history.push(
          FormManagerPath.formSection(formIdParam, formSectionIdParam)
        );
      }

      setFormId(formIdParam);
      setFormSectionId(formSectionIdParam);
    }
  }, [history, location]);

  const formEditorBreadcrumb = (
    <Breadcrumb.Item
      active
    >{`Form (${form?.salesforceObject?.name})`}</Breadcrumb.Item>
  );

  const formSectionEditorBreadcrumb = (
    <>
      <Breadcrumb.Item
        linkAs={Link}
        linkProps={{ to: FormManagerPath.form(formId) }}
      >{`Form (${form?.salesforceObject?.name})`}</Breadcrumb.Item>
      <Breadcrumb.Item
        active
      >{`Form Section (${formSection?.salesforceObject?.name})`}</Breadcrumb.Item>
    </>
  );

  const editorBreadcrumb = formSectionId
    ? formSectionEditorBreadcrumb
    : formEditorBreadcrumb;

  const formPreview = (
    <>
      {!!((form?.content as ComponentProps[]) || [])?.length && (
        <Form
          content={form}
          initialRequest={initialRequest}
          setRequest={setRequest}
          onSubmit={(event) => {
            event.preventDefault();
            event.stopPropagation();
            // eslint-disable-next-line no-console
            console.log(request);
          }}
        />
      )}
    </>
  );

  const formSectionPreview = (
    <>
      {!!((formSection?.content as ComponentProps[]) || [])?.length && (
        <Form
          content={formSection}
          initialRequest={initialRequest}
          setRequest={setRequest}
          onSubmit={(event) => {
            event.preventDefault();
            event.stopPropagation();
            // eslint-disable-next-line no-console
            console.log(request);
          }}
        />
      )}
    </>
  );

  return (
    <FormEditorContext.Provider
      value={{
        formId,
        formSectionId,
        form,
        refreshForm,
        formSection,
        initialRequest,
        setRequest,
      }}
    >
      <Container className="form-editor">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: FormManagerPath.formManager() }}
          >
            Form Manager
          </Breadcrumb.Item>
          {editorBreadcrumb}
        </Breadcrumb>
        <Row className="mb-3">
          <Col>
            <h1>{formSectionId ? "Form Section Editor" : "Form Editor"}</h1>
          </Col>
        </Row>
        <Tabs defaultActiveKey="editor">
          <Tab eventKey="editor" title="Editor">
            {formSectionId ? <EditableFormSection /> : <EditableForm />}
          </Tab>
          <Tab eventKey="preview" title="Preview">
            {formSectionId ? formSectionPreview : formPreview}
          </Tab>
        </Tabs>
      </Container>
    </FormEditorContext.Provider>
  );
};

export default FormEditor;
