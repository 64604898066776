import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useSafeAsync from "../../../../shared/src/hooks/useSafeAsync";
import useLoading from "../../../../shared/src/hooks/useLoading";
import Case, { MOCStatus } from "../../../models/salesforce/Case";
import MOCRequestService from "../../../services/MOCRequestService";

const useMOCRequestsByCurrentUser = (
  isApprover: boolean,
  statuses: MOCStatus[]
): [Case[], Dispatch<SetStateAction<Case[]>>] => {
  const safeAsync = useSafeAsync();
  const loading = useLoading();
  const [mocRequests, setMocRequests] = useState<Case[]>([]);

  useEffect(() => {
    safeAsync(
      loading(
        MOCRequestService.getMocCasesByIsApproverAndStatuses(
          isApprover,
          statuses
        )
      ).then((response) => {
        setMocRequests(response as Case[]);
      })
    );
  }, [loading, safeAsync, isApprover, statuses]);

  return [mocRequests, setMocRequests];
};
export default useMOCRequestsByCurrentUser;
