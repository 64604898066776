import { Dispatch, SetStateAction, createContext } from "react";
import { MOCFormRequest } from "../../../models/MOCFormRequest";
import type { AuthorizedAdultFormRequest } from "../../../models/AuthorizedAdultFormRequest";

export type MOCFormContextProps = {
  initialRequest: MOCFormRequest;
  setInitialRequest: Dispatch<SetStateAction<MOCFormRequest>>;
  requestKey: string;
  authorizedAdults: AuthorizedAdultFormRequest[];
  externalAuthorizedAdults: AuthorizedAdultFormRequest[];
  mocRequestId: string;
};

export const MOCFormContext = createContext<MOCFormContextProps>({
  initialRequest: {},
  setInitialRequest: () => {},
  requestKey: "",
  authorizedAdults: [],
  externalAuthorizedAdults: [],
  mocRequestId: "",
});
