import { useEffect, useState } from "react";
import MyEmoryContact from "../models/salesforce/MyEmoryContact";
import { useLoggedInUser, useSafeAsync } from "../modules/shared/src/hooks";
import { Person } from "../modules/shared/src/models";
import useLoading from "../modules/shared/src/hooks/useLoading";
import MyEmoryContactService from "../services/MyEmoryContactService";

const useCurrentUser = (): Person & MyEmoryContact => {
  const safeAsync = useSafeAsync();
  const loggedInUser = useLoggedInUser();
  const [currentUser, setCurrentUser] = useState<Person>(loggedInUser);
  const loading = useLoading();

  useEffect(() => {
    if (loggedInUser && loggedInUser?.ppid) {
      safeAsync(
        loading(MyEmoryContactService.getContactByPpid(loggedInUser?.ppid))
          .then((x) => x as MyEmoryContact)
          .then((contact) => {
            if (contact) {
              setCurrentUser((previousCurrentUser) => {
                return {
                  ...previousCurrentUser,
                  ...loggedInUser,
                  ...contact,
                };
              });
            }
          })
      );
    } else {
      setCurrentUser(loggedInUser);
    }
  }, [loading, loggedInUser, safeAsync]);

  return currentUser;
};

export default useCurrentUser;
