export type YesNoUndefinedType = "yes" | "no" | undefined;

class YesNoType {
  private static ALL_VALUES: YesNoType[] = [];

  static readonly YES = new YesNoType("yes", "Yes");

  static readonly NO = new YesNoType("no", "No");

  constructor(
    public readonly value: YesNoUndefinedType,
    public readonly label: string,
    public readonly inline: boolean = true,
    public readonly className: string = "ms-2"
  ) {
    YesNoType.ALL_VALUES.push(this);
  }

  static values(): YesNoType[] {
    return YesNoType.ALL_VALUES;
  }

  static valueOf(targetValue: string | undefined): YesNoType | undefined {
    return YesNoType.ALL_VALUES.find(({ value }) => value === targetValue);
  }

  static parseBoolean(value: string | undefined): boolean | undefined {
    return value === this.YES.value;
  }

  static parseValue(booleanValue: boolean | undefined): YesNoUndefinedType {
    if (booleanValue === true) {
      return this.YES.value;
    }
    if (booleanValue === false) {
      return this.NO.value;
    }
    return undefined;
  }
}

export default YesNoType;
