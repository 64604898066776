import { createContext } from "react";
import UserAccess from "./salesforce/UserAccess";

export type MyEmoryContextProps = UserAccess & {
  roleNames: string[];
};

export const MyEmoryContext = createContext<MyEmoryContextProps>({
  roleNames: [],
});
