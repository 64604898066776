import MyEmoryContact from "../../models/salesforce/MyEmoryContact";
import { BaseService } from "../../modules/shared/src/services";

class MyEmoryContactService extends BaseService {
  private static readonly BASE_PATH = "/api/myemory/v0/contacts";

  public static async getContactByPpid(ppid: string): Promise<MyEmoryContact> {
    const response = await super.get<MyEmoryContact>({
      url: `${this.BASE_PATH}/${ppid}`,
    });
    return response.data;
  }
}

export default MyEmoryContactService;
