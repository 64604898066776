/* eslint-disable react/jsx-props-no-spreading */
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Alert, Button, Container, Form, Table } from "react-bootstrap";
import { useTable } from "react-table";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import useLoading from "../../../../hooks/useLoading";
import FormInputProperty from "../../../../models/salesforce/FormInputProperty";
import SalesforceResponse from "../../../../models/salesforce/SalesforceResponse";
import FormInputPropertyService from "../../../../services/forms/FormInputPropertyService";

export type FormInputPropertiesProps = {
  properties: FormInputProperty[];
  setProperties: (
    getProperties: (
      previousProperties: FormInputProperty[]
    ) => FormInputProperty[]
  ) => void;
};

const FormInputProperties: FC<FormInputPropertiesProps> = ({
  properties,
  setProperties,
}: FormInputPropertiesProps): ReactElement => {
  const loading = useLoading();
  const [propertyToDelete, setPropertyToDelete] = useState<FormInputProperty>();

  const addProperty = () => {
    setProperties((previousProperties: FormInputProperty[]) => {
      return [
        ...previousProperties,
        {
          id: uuid(),
          propName: "",
          propValue: "",
          clearIdBeforeSave: true,
        },
      ];
    });
  };

  const updatePropertyFieldById = useCallback(
    (targetId: string, fieldName: string, fieldValue: string) => {
      setProperties((previousProperties: FormInputProperty[]) => {
        const targetOptionIndex = previousProperties.findIndex(
          ({ id }) => id === targetId
        );
        if (targetOptionIndex === -1) {
          return previousProperties;
        }
        const newProperties = [...previousProperties];
        newProperties[targetOptionIndex] = {
          ...newProperties[targetOptionIndex],
          [fieldName]: fieldValue,
        };
        return newProperties;
      });
    },
    [setProperties]
  );

  const handleConfirmDeleteFormInputProperty = async (
    formInputProperty: FormInputProperty
  ) => {
    loading(async () => {
      try {
        setPropertyToDelete(undefined);
        let deleteSuccess = false;
        let responses: SalesforceResponse[] = [];
        if (formInputProperty.clearIdBeforeSave) {
          // option is not saved in salesforce so just remove from list
          deleteSuccess = true;
        } else {
          responses =
            await FormInputPropertyService.deleteFormInputPropertyById(
              formInputProperty.id as string
            );
          if (responses.every(({ success }) => success)) {
            deleteSuccess = true;
          }
        }
        if (deleteSuccess) {
          toast.success("Successfully deleted form input property");
          setProperties((previousProperties: FormInputProperty[]) => {
            const targetProperties = previousProperties.find(
              ({ id }) => id === formInputProperty.id
            );
            if (targetProperties === undefined) {
              return previousProperties;
            }
            return previousProperties.filter(
              ({ id }) => id !== formInputProperty.id
            );
          });
        } else {
          setPropertyToDelete(formInputProperty);
          [
            { message: "Failed to delete form input option" },
            ...responses.flatMap(({ errors }) => errors || []),
          ].forEach(({ message }) => {
            toast.error(message);
          });
        }
      } catch {
        toast.error("Failed to delete form input option");
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Prop Name",
        accessor: ({ id, propName }: FormInputProperty) => {
          const fieldName = "propName";
          return (
            <Form.Control
              id={`${id}-${fieldName}`}
              name={fieldName}
              type="text"
              value={propName}
              required
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                event.preventDefault();
                event.stopPropagation();
                const { value: fieldValue } = event.target;
                updatePropertyFieldById(id as string, fieldName, fieldValue);
              }}
            />
          );
        },
      },
      {
        Header: "Prop Value",
        accessor: ({ id, propValue }: FormInputProperty) => {
          const fieldName = "propValue";
          return (
            <Form.Control
              id={`${id}-${fieldName}`}
              name={fieldName}
              type="text"
              value={propValue}
              required
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                event.preventDefault();
                event.stopPropagation();
                const { value: fieldValue } = event.target;
                updatePropertyFieldById(id as string, fieldName, fieldValue);
              }}
            />
          );
        },
      },
      {
        Header: "Action",
        accessor: (property: FormInputProperty) => (
          <div className="d-flex justify-content-center">
            <Button
              variant="danger"
              onClick={() => setPropertyToDelete(property)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </div>
        ),
      },
    ],
    [updatePropertyFieldById]
  );

  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: properties,
  });

  return (
    <>
      <Form.Label>Properties</Form.Label>
      <Container>
        <Table striped bordered hover size="sm" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {rows.length === 0 ? (
              <tr>
                <td colSpan={columns.length}>
                  <Alert variant="info" className="text-center mb-0">
                    No Properties Added
                  </Alert>
                </td>
              </tr>
            ) : (
              <>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </Table>
        <div className="d-flex justify-content-center">
          <Button
            variant="info"
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
              event.stopPropagation();
              addProperty();
            }}
          >
            Add Property
          </Button>
        </div>
      </Container>
      {propertyToDelete && (
        <ConfirmationModal
          id="form-input-properties-delete-form-input-property-modal"
          confirmButtonProps={{
            variant: "danger",
          }}
          show={propertyToDelete !== undefined}
          title="Delete Form Input Property"
          body="Are you sure you want to delete this form input property?"
          onCancel={() => setPropertyToDelete(undefined)}
          onConfirm={() =>
            handleConfirmDeleteFormInputProperty(propertyToDelete)
          }
        />
      )}
    </>
  );
};

export default FormInputProperties;
