import { CacheAxiosResponse } from "axios-cache-interceptor/dist/cache/axios";
import Person from "../../models/Person";
import BaseService from "../BaseService";
import Contact from "../../models/salesforce/Contact";

export type LoggedInUser = Person &
  Partial<{
    contact: Contact;
  }>;

interface ShibbAttribute {
  loggedInUser: LoggedInUser;
}

export interface ShibbAttributeResponse {
  shibbAttribute: ShibbAttribute;
}

class ShibbService extends BaseService {
  static getShibbAttributes(): Promise<
    CacheAxiosResponse<ShibbAttributeResponse>
  > {
    return super.get<ShibbAttributeResponse>({ url: "/api/shibb-attributes" });
  }

  public static async getLoggedInUser(): Promise<LoggedInUser | undefined> {
    const { data } = await ShibbService.getShibbAttributes();
    return data?.shibbAttribute?.loggedInUser;
  }
}

export default ShibbService;
