import _ from "lodash";
import {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Select, { Props as SelectProps } from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import CreatableSelect from "react-select/creatable";
import { useSafeAsync } from "../../../../../../hooks";
import { ComponentProps } from "../../../../../../models";
import { BaseService } from "../../../../../../services";
import { FormContext } from "../../../../models/FormContext";
import FormInputProps from "../../models/FormInputProps";
import FormOption from "../../models/FormOption";
import { formatOptionsUrl } from "../../utils/FormInputUtil";

export type ReactSelectFormOption<T> = Omit<FormOption<T>, "disabled"> &
  Partial<{
    isDisabled: boolean;
  }>;

export type FormSearchSelectProps = Omit<FormInputProps, "options"> &
  Omit<SelectProps, "id" | "name" | "onChange" | "options"> & {
    disabled?: boolean;
    options: FormOption[];
    onChange?: (selectedOptions: FormOption | FormOption[]) => void;
    asyncMinSearchLength?: number;
    value?: FormOption | FormOption[];
    creatable?: boolean;
    async?: boolean;
  };

const renderOptionLabel = (label: string | ComponentProps): string => {
  if (typeof label === "string") {
    return label;
  }
  return label?.content as string;
};

const convertFormOptionToReactSelectFormOption = <T,>(
  option: FormOption<T>
) => {
  const modifiedOption: ReactSelectFormOption<T> = {
    ...option,
    label: renderOptionLabel(option.label),
  };
  if (option.disabled) {
    modifiedOption.isDisabled = true;
  }
  return modifiedOption;
};

const FormSearchSelect: FC<FormSearchSelectProps> = ({
  id,
  name,
  label,
  type,
  showRequired,
  options,
  optionsEndpoint,
  onChange,
  disabled,
  asyncMinSearchLength,
  onAfterChange,
  value: valueProp,
  creatable,
  async,
  ...otherProps
}: FormSearchSelectProps): ReactElement => {
  const safeAsync = useSafeAsync();
  const [value, setValue] = useState<FormOption | FormOption[]>();
  const {
    initialRequest,
    getRequest,
    setRequest,
    disabled: disabledByContext,
    isPrintMode,
    optionsByFieldNameMap,
  } = useContext(FormContext);
  const [selectOptions, setSelectOptions] = useState<
    ReactSelectFormOption<unknown>[]
  >([]);

  const onChangeHandler = useMemo(() => {
    return (selectedOptions: FormOption | FormOption[]) => {
      if (onChange !== undefined) {
        onChange(selectedOptions);
      } else if (setRequest !== undefined) {
        setRequest((previousRequest) => {
          return _.setWith(
            _.clone(previousRequest),
            name,
            selectedOptions,
            _.clone
          );
        });
      } else {
        throw new Error(`No onChange event defined for input field: ${name}`);
      }
      if (onAfterChange) {
        onAfterChange();
      }
    };
  }, [name, onAfterChange, onChange, setRequest]);

  const handleOnChange = useMemo(() => {
    return (selectedOptions: unknown) => {
      setValue(selectedOptions as FormOption | FormOption[]);
      onChangeHandler(selectedOptions as FormOption | FormOption[]);
    };
  }, [onChangeHandler]);

  const loadOptions = useCallback(
    async (searchText: string): Promise<FormOption[]> => {
      if (
        !optionsEndpoint ||
        (asyncMinSearchLength && searchText.length < asyncMinSearchLength)
      ) {
        return [];
      }

      const formattedOptionsEndpoint = formatOptionsUrl(
        optionsEndpoint,
        getRequest ? getRequest() : initialRequest
      );

      const response = await BaseService.get<FormOption[]>({
        url: formattedOptionsEndpoint,
        config: {
          params: {
            searchText,
          },
        },
      });
      return response?.data || [];
    },
    [asyncMinSearchLength, getRequest, initialRequest, optionsEndpoint]
  );

  useEffect(() => {
    if ((options ?? []).length > 0) {
      setSelectOptions(
        (options ?? []).map(convertFormOptionToReactSelectFormOption)
      );
    } else if ((optionsByFieldNameMap?.[name] ?? []).length > 0) {
      setSelectOptions(
        (optionsByFieldNameMap?.[name] ?? []).map(
          convertFormOptionToReactSelectFormOption
        )
      );
    } else if ((_.get(optionsByFieldNameMap, name) ?? []).length > 0) {
      setSelectOptions(
        (_.get(optionsByFieldNameMap, name) ?? []).map(
          convertFormOptionToReactSelectFormOption
        )
      );
    } else if (
      optionsEndpoint &&
      (optionsByFieldNameMap?.[optionsEndpoint] ?? []).length > 0
    ) {
      setSelectOptions(
        (optionsByFieldNameMap?.[optionsEndpoint] ?? []).map(
          convertFormOptionToReactSelectFormOption
        )
      );
    } else if (optionsEndpoint && !asyncMinSearchLength) {
      safeAsync(loadOptions(""))
        .then((loadedOptions) => loadedOptions as FormOption[])
        .then((loadedOptions) => {
          setSelectOptions(
            loadedOptions.map(
              ({ disabled: isDisabled, ...loadedOptionProps }) => {
                return {
                  ...loadedOptionProps,
                  isDisabled,
                };
              }
            )
          );
        });
    } else {
      setSelectOptions([]);
    }
  }, [
    asyncMinSearchLength,
    loadOptions,
    name,
    options,
    optionsByFieldNameMap,
    optionsEndpoint,
    safeAsync,
  ]);

  const renderNoOptionsMessage = useCallback(
    ({ inputValue }) => {
      if (asyncMinSearchLength && inputValue.length < asyncMinSearchLength) {
        return `Enter at least ${asyncMinSearchLength} characters to search`;
      }
      return "No options";
    },
    [asyncMinSearchLength]
  );

  useEffect(() => {
    if (valueProp) {
      setValue(valueProp);
    } else {
      setValue(_.get(initialRequest, name) as FormOption | FormOption[]);
    }
  }, [initialRequest, valueProp, name]);

  const props = useMemo(() => {
    return {
      isDisabled: disabledByContext || disabled,
      isClearable: true,
      classNamePrefix: "react-select",
      id,
      name,
      value,
      ...(otherProps ?? {}),
      onChange: handleOnChange,
    };
  }, [
    disabled,
    disabledByContext,
    handleOnChange,
    id,
    name,
    otherProps,
    value,
  ]);

  if (isPrintMode) {
    if (Array.isArray(value)) {
      return (
        <div className="printable-form-control form-control p-0">
          {value.length > 0 ? (
            value.map(({ label: optionLabel }) => (
              <div key={optionLabel} className="border p-1">
                {optionLabel}
              </div>
            ))
          ) : (
            <>&nbsp;</>
          )}
        </div>
      );
    }
    return (
      <div className="printable-form-control form-control">
        {(value as FormOption)?.label || <>&nbsp;</>}
      </div>
    );
  }

  if (async || (optionsEndpoint && asyncMinSearchLength)) {
    if (creatable) {
      return (
        <AsyncCreatableSelect
          key={optionsEndpoint}
          cacheOptions
          defaultOptions
          loadOptions={loadOptions}
          {...props}
          noOptionsMessage={renderNoOptionsMessage}
        />
      );
    }

    return (
      <AsyncSelect
        key={optionsEndpoint}
        cacheOptions
        defaultOptions
        loadOptions={loadOptions}
        {...props}
        noOptionsMessage={renderNoOptionsMessage}
      />
    );
  }

  if (creatable) {
    return <CreatableSelect {...props} options={selectOptions} />;
  }

  return <Select {...props} options={selectOptions} />;
};

FormSearchSelect.defaultProps = {
  disabled: false,
  onChange: undefined,
  asyncMinSearchLength: undefined,
  value: undefined,
  creatable: false,
  async: false,
};

export default FormSearchSelect;
