import { FC, ReactElement, useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { CellContext } from "@tanstack/react-table";
import { toast } from "react-toastify";
import AppPath from "../../../../../../../../components/App/models/AppPath";
import Case, { MOCStatus } from "../../../../../../models/salesforce/Case";
import useLoading from "../../../../../../../shared/src/hooks/useLoading";
import MOCRequestService from "../../../../../../services/MOCRequestService";
import { SubmissionsTableContext } from "../../models/SubmissionsTableContext";
import MOCPath from "../../../../../../models/MOCPath";
import "./styles.scss";
import FormTextarea from "../../../../../../../shared/src/components/Form/components/FormInput/components/FormTextarea";

const getPath = (id: string | undefined) => {
  if (id) {
    const pathParams = { id };
    return MOCPath.MOC.generatePath(pathParams);
  }
  return AppPath.PageNotFound.path;
};
const Actions: FC<CellContext<Case, unknown>> = ({
  row: {
    original: { id, ...row },
  },
}): ReactElement => {
  const { setData, isApprover, statuses } = useContext(SubmissionsTableContext);
  const history = useHistory();
  const path = useMemo(() => getPath(id), [id]);
  const [show, setShow] = useState(false);
  const [action, setAction] = useState("");
  const loading = useLoading();
  const [rejectionReason, setRejectionReason] = useState("");
  const buttonText = useMemo(() => {
    return isApprover || row.status === MOCStatus.CLOSED ? "View" : "Edit";
  }, [isApprover, row.status]);
  const buttonVariant = useMemo(() => {
    return isApprover || row.status === MOCStatus.CLOSED ? "info" : "primary";
  }, [isApprover, row.status]);

  const handleShow = (buttonAction: string) => {
    setAction(buttonAction);
    setShow(true);
  };

  const handleStatusChange = async (status: string) => {
    const newData = { ...row, status, id, rejectionReason };
    try {
      await loading(
        MOCRequestService.validateAndSave(newData, "Update Status")
      );
      history.push(MOCPath.MOCSubmissionsApprover.path);
      toast.success("MOC Request updated successfully");
      setData(
        await MOCRequestService.getMocCasesByIsApproverAndStatuses(
          isApprover,
          statuses
        )
      );
    } catch {
      toast.error("Failed to update MOC Request");
    }
    setShow(false);
  };

  return (
    <div className="moc-submissions__submissions-table__actions">
      <Button variant={buttonVariant} onClick={() => history.push(path)}>
        {buttonText}
      </Button>
      {isApprover && row.status === MOCStatus.NEW && (
        <>
          <Button
            variant="success"
            onClick={() => handleShow(MOCStatus.APPROVED)}
          >
            Approve
          </Button>
          <Button
            variant="danger"
            onClick={() => handleShow(MOCStatus.REJECTED)}
          >
            Reject
          </Button>
        </>
      )}
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Mark request as {action}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to mark the
          <b>{` MOC-${row.caseNumber} ${row.programName} `}</b>
          program as <b>{action}</b>?
          {action === MOCStatus.REJECTED && (
            <FormTextarea
              id="rejectionReason"
              label="Rejection Reason"
              name="rejectionReason"
              type="textarea"
              onChange={(e) => setRejectionReason(e.target.value)}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={action === MOCStatus.APPROVED ? "success" : "danger"}
            onClick={() => handleStatusChange(action)}
          >
            {action === MOCStatus.APPROVED ? "Approve" : "Reject"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Actions;
