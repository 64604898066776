import React, { FC, ReactElement, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Header from "../../components/Header";
import "./styles.scss";

const AAEEventsReservationRequestFormSuccess: FC = (): ReactElement => {
  const [secondsToRedirect, setSecondsToRedirect] = useState(10);
  const id = React.useRef(0);
  const clear = () => {
    window.clearInterval(id.current);
  };
  useEffect(() => {
    id.current = window.setInterval(() => {
      setSecondsToRedirect((time) => time - 1);
    }, 1000);
    return () => clear();
  }, []);

  useEffect(() => {
    if (secondsToRedirect === 0) {
      window.location.replace("https://millerward.emory.edu/");
    }
  }, [secondsToRedirect]);

  return (
    <>
      <Header />
      <Container id="confirmation-message">
        <h1>Success!</h1>
        <h2>Your reservation request has been received.</h2>
        <h3>
          You will be contacted by an event coordinator to discuss the specifics
          of your event.
        </h3>
        <p>
          You will now be re-directed back to the Miller Ward Alumni House home
          page. ({secondsToRedirect} seconds)
        </p>
      </Container>
    </>
  );
};

export default AAEEventsReservationRequestFormSuccess;
