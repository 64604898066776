import { RowData, Table, flexRender } from "@tanstack/react-table";
import { FC, ReactElement } from "react";
import { Anchor } from "react-bootstrap";
import TableHeaderSortArrow from "../../../../../components/TableHeaderSortArrow";
import FormControlFilter from "../../filters/components/FormControlFilter";
import { FilterInputProps } from "../../filters/models/FilterInputProps";

export type TableHeaderProps<TData extends RowData> = {
  table: Table<TData>;
  wrap?: boolean;
};

export type ColumnMeta<TData extends RowData> = Partial<{
  filter: FC<FilterInputProps<TData>>;
}>;

const TableHeader = <T,>({
  table,
  wrap,
}: TableHeaderProps<T>): ReactElement => {
  return (
    <thead className="table-header">
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <th key={header.id} className="align-top">
              <span className={`${wrap ? "" : " text-nowrap"}`}>
                {header.column.getCanSort() ? (
                  <Anchor
                    className="text-reset text-decoration-none cursor-pointer select-none"
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    <TableHeaderSortArrow
                      canSort={header.column.getCanSort()}
                      isSorted={!!header.column.getIsSorted()}
                      isSortedDesc={header.column.getIsSorted() === "desc"}
                      {...header}
                    />
                  </Anchor>
                ) : (
                  <>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </>
                )}
              </span>
              {header.column.getCanFilter() ? (
                <>
                  {(header.column.columnDef.meta as ColumnMeta<T> | undefined)
                    ?.filter ? (
                    (header.column.columnDef.meta as ColumnMeta<T>).filter?.({
                      table,
                      columnId: header.column.id,
                    })
                  ) : (
                    <FormControlFilter
                      table={table}
                      columnId={header.column.id}
                    />
                  )}
                </>
              ) : null}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

TableHeader.defaultProps = {
  wrap: false,
};

export default TableHeader;
