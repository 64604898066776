import { createContext, Dispatch, SetStateAction } from "react";

export type EditableFormSectionContextProps = {
  setGridLayoutDraggable: Dispatch<SetStateAction<boolean>>;
};

export const EditableFormSectionContext =
  createContext<EditableFormSectionContextProps>({
    setGridLayoutDraggable: () => {},
  });
