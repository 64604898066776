import { FC, ReactElement, useMemo } from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import SubmissionsTable from "./components/SubmissionsTable";
import ManagedContent from "../../../shared/src/components/ManagedContent";
import ManagedContentName from "../../models/ManagedContentName";
import FeatureName from "../../../../components/App/models/FeatureName";
import MOCPath from "../../models/MOCPath";
import AppPath from "../../../../components/App/models/AppPath";
import { MOCStatus } from "../../models/salesforce/Case";

const MOCSubmissions: FC = (): ReactElement => {
  const statuses = useMemo<MOCStatus[]>(
    () => [MOCStatus.NEW, MOCStatus.APPROVED],
    []
  );
  const otherStatuses = useMemo<MOCStatus[]>(
    () => [MOCStatus.CLOSED, MOCStatus.REJECTED],
    []
  );
  return (
    <Container>
      <Row>
        <Col>
          <Breadcrumb>
            <>
              {[AppPath.Home, MOCPath.MOCSubmissions].map(
                (path, index, { length }) =>
                  path.toBreadcrumbItem(index === length - 1)
              )}
            </>
          </Breadcrumb>
        </Col>
      </Row>
      <ManagedContent
        name={ManagedContentName.ProgramsInvolvingMinorsDashboard}
        featureName={FeatureName.MinorsOnCampusRegistrationForm}
      />
      <Row>
        <Col>
          <SubmissionsTable isApprover={false} statuses={statuses} />
        </Col>
      </Row>
      <Row>
        <Col>
          <ManagedContent
            name={ManagedContentName.ProgramsInvolvingMinorsClosedNoAction}
            featureName={FeatureName.MinorsOnCampusRegistrationForm}
          />
          <SubmissionsTable isApprover={false} statuses={otherStatuses} />
        </Col>
      </Row>
    </Container>
  );
};

export default MOCSubmissions;
