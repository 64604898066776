import { History } from "history";
import {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Anchor,
  Dropdown,
  DropdownButton,
  Image,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import ContentManagementUtil from "../../utils/ContentManagementUtil";
import { ManagedContentContext } from "../ManagedContent/models/ManagedContentContext";
import AppHeaderLink, { AppHeaderLinkType } from "./AppHeaderLink";
import { emoryLogo, emoryTitle, toggleIcon } from "./assets";
import "./styles.scss";

export interface AppHeaderProps {
  title: string;
  username?: string;
  version: string;
  links: AppHeaderLink[];
  enableHandleScroll?: boolean;
  usernameDropdownItems?: ReactElement;
  renderUsernameDropdownItems?: (history: History) => ReactElement;
  className?: string;
}

const AppHeader: FC<AppHeaderProps> = ({
  title,
  username,
  version,
  links,
  enableHandleScroll = false,
  usernameDropdownItems,
  renderUsernameDropdownItems,
  className,
}: AppHeaderProps): ReactElement => {
  const history = useHistory();
  const { isContentManager } = useContext(ManagedContentContext);
  const [showHeaderTop, setShowHeaderTop] = useState(true);

  const handleScroll = useCallback(() => {
    /* istanbul ignore next */
    if (showHeaderTop !== window.scrollY < 50) {
      setTimeout(() => {
        setShowHeaderTop(!showHeaderTop);
      }, 50);
    }
  }, [showHeaderTop]);

  useEffect(() => {
    if (enableHandleScroll && handleScroll) {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
    return undefined;
  }, [enableHandleScroll, handleScroll]);

  const getLinkByType = (link: AppHeaderLink): ReactElement => {
    switch (link.type) {
      case AppHeaderLinkType.BUTTON: {
        return (
          <Nav.Link as="button" key={link.id} onClick={link.onClick}>
            <>{link.label}</>
          </Nav.Link>
        );
      }
      case AppHeaderLinkType.LINK: {
        return (
          <Nav.Link key={link.id} as={Link} to={link.path as string}>
            <>{link.label}</>
          </Nav.Link>
        );
      }
      case AppHeaderLinkType.TEXT: {
        return (
          <Nav.Item key={link.id}>
            <>{link.label}</>
          </Nav.Item>
        );
      }
      case AppHeaderLinkType.DROPDOWN: {
        return (
          <NavDropdown
            id={`header-menu-dropdown-${link.id}`}
            key={link.id}
            title={<>{link.label}</>}
          >
            <>{(link.childLinks ?? []).map(getLinkByType)}</>
          </NavDropdown>
        );
      }
      case AppHeaderLinkType.DROPDOWN_DIVIDER: {
        return <NavDropdown.Divider key={link.id} />;
      }
      case AppHeaderLinkType.DROPDOWN_ITEM: {
        return (
          <NavDropdown.Item key={link.id} as={Link} to={link.path as string}>
            <>{link.label}</>
          </NavDropdown.Item>
        );
      }
      case AppHeaderLinkType.DROPDOWN_TEXT: {
        return (
          <span key={link.id} className="dropdown-item">
            <>{link.label}</>
          </span>
        );
      }
      default: {
        /* istanbul ignore next */
        throw new Error(`Invalid HeaderLinkType: ${link}`);
      }
    }
  };

  return (
    <div className="app-header">
      <Navbar
        expand="md"
        sticky="top"
        collapseOnSelect
        className={`${className ?? "mb-4"} ${
          showHeaderTop ? "scroll-top" : "scroll-down"
        }`}
      >
        <Navbar.Brand as={Link} to="/">
          <Image src={emoryLogo} alt="Home" />
        </Navbar.Brand>
        <div className="header">
          <div className="header-top">
            <div>
              <Navbar expand="sm" className="h-auto">
                <Navbar.Collapse>
                  <Image
                    width="175px"
                    src={emoryTitle}
                    alt="Emory University"
                  />
                </Navbar.Collapse>
              </Navbar>
              <h3 className="header-title">{title}</h3>
            </div>
          </div>
          <Navbar.Collapse className="header-bottom" id="main-nav">
            <Nav className="me-auto">
              <>{links.map(getLinkByType)}</>
            </Nav>
            <Nav className="ms-auto">
              {username && (
                <DropdownButton
                  id="header-dropdown-username"
                  title={username}
                  align="end"
                >
                  <>
                    {usernameDropdownItems}
                    {renderUsernameDropdownItems?.(history)}
                    {isContentManager && (
                      <Dropdown.Item
                        as={Anchor}
                        onClick={() => {
                          ContentManagementUtil.setEnableContentManagementMode(
                            !ContentManagementUtil.isContentManagementModeEnabled()
                          );
                        }}
                      >
                        {ContentManagementUtil.isContentManagementModeEnabled()
                          ? "Disable"
                          : "Enable"}{" "}
                        Content Management
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item>{version}</Dropdown.Item>
                  </>
                </DropdownButton>
              )}
            </Nav>
          </Navbar.Collapse>
        </div>
        <Navbar.Toggle aria-controls="main-nav">
          <img src={toggleIcon} alt="Toggle Button" />
        </Navbar.Toggle>
      </Navbar>
    </div>
  );
};

AppHeader.defaultProps = {
  username: undefined,
  enableHandleScroll: false,
  usernameDropdownItems: undefined,
  renderUsernameDropdownItems: undefined,
  className: undefined,
};

export default AppHeader;
