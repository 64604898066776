import { ReactElement, ReactNode } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, generatePath } from "react-router-dom";

class AppPath {
  public static readonly Home = new AppPath("home", "Home", "/");

  public static readonly PageNotFound = new AppPath(
    "page-not-found",
    "Page Not Found",
    "/*"
  );

  public static readonly ShibbolethLogin = new AppPath(
    "shibboleth-login",
    "Shibboleth Login",
    "/Shibboleth.sso/Login"
  );

  public static readonly ShibbolethLoginEuship = new AppPath(
    "shibboleth-login-euship",
    "Shibboleth Login",
    "/Shibboleth.sso/Login?target=/euship"
  );

  constructor(
    public readonly id: string,
    public readonly label: string | ReactNode,
    public readonly path: string
  ) {}

  public generatePath(
    pathParams?: Record<string, number | string | undefined>
  ): string {
    if (pathParams) {
      return generatePath(this.path, pathParams);
    }
    return this.path;
  }

  public cloneWithPathParams(
    pathParams: Record<string, string | undefined>
  ): AppPath {
    return new AppPath(this.id, this.label, this.generatePath(pathParams));
  }

  public toBreadcrumbItem(active = false): ReactElement {
    if (active) {
      return (
        <Breadcrumb.Item active key={this.id}>
          <>{this.label}</>
        </Breadcrumb.Item>
      );
    }
    return (
      <Breadcrumb.Item
        key={this.id}
        linkAs={Link}
        linkProps={{ to: this.path }}
      >
        <>{this.label}</>
      </Breadcrumb.Item>
    );
  }
}

export default AppPath;
