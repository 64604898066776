import AppHeaderLink, {
  AppHeaderLinkType,
} from "../../shared/src/components/AppHeader/AppHeaderLink";
import { AppRouteProps } from "../../shared/src/models";
import MOC from "../pages/MOC";
import MOCSubmissions from "../pages/MOCSubmissions";
import MOCSubmissionsApprover from "../pages/MOCSubmissionsApprover";
import MOCPath from "./MOCPath";

export const MOCAppHeaderLinks: AppHeaderLink[] = [
  {
    ...MOCPath.MOCSubmissions,
    type: AppHeaderLinkType.LINK,
  },
];

export const MOCAppRoutes: AppRouteProps[] = [
  {
    ...MOCPath.MOC,
    exact: false,
    render: () => <MOC />,
  },
  {
    ...MOCPath.MOCSubmissions,
    exact: true,
    render: () => <MOCSubmissions />,
  },
];

export function getMOCAppHeaderLinks(isApprover: boolean): AppHeaderLink[] {
  const newLinks = [...MOCAppHeaderLinks];
  if (isApprover) {
    newLinks.push({
      ...MOCPath.MOCSubmissionsApprover,
      type: AppHeaderLinkType.LINK,
    });
  }
  return newLinks;
}

export function getMOCAppRoutes(isApprover: boolean): AppRouteProps[] {
  const newRoutes = [...MOCAppRoutes];
  if (isApprover) {
    newRoutes.push({
      ...MOCPath.MOCSubmissionsApprover,
      exact: true,
      render: () => <MOCSubmissionsApprover />,
    });
  }
  return newRoutes;
}
