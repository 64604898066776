import EventReservationRequest from "../models/api/EventReservationRequest";
import { EventReservationResponse } from "../models/api/EventReservationResponse";
import { BaseService } from "../../shared/src/services";

class EventService extends BaseService {
  private static readonly BASE_PATH = "/api/aae/v1/events";

  public static async saveEvent(
    data: EventReservationRequest
  ): Promise<EventReservationResponse> {
    const response = await this.post<
      EventReservationResponse,
      EventReservationRequest
    >({
      url: this.BASE_PATH,
      data,
    });
    return response.data || {};
  }
}

export default EventService;
