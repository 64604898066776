import { FC, ReactElement } from "react";
import { Form } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import FormLabel from "../../../FormLabel";
import { OtherFormMultiSelectProps } from "../../models/FormOtherProps";
import FormMultiSelect from "../FormMultiSelect";
import FormValidation from "../FormValidation";

const OtherFormMultiSelect: FC<OtherFormMultiSelectProps> = (
  props: OtherFormMultiSelectProps
): ReactElement => {
  const { targetValue, ...formMultiSelectProps } = props;
  const { label, validationMessage, invalid } = formMultiSelectProps;

  return (
    <Form.Group controlId={formMultiSelectProps?.id || uuid()}>
      <>
        <FormLabel
          label={label as string}
          inline
          required={formMultiSelectProps?.required}
          requiredMessage={formMultiSelectProps?.requiredMessage}
        />
        <div className={invalid ? "form-invalid-input" : ""}>
          <FormMultiSelect {...formMultiSelectProps} />
        </div>
        {validationMessage && <FormValidation message={validationMessage} />}
      </>
    </Form.Group>
  );
};

export default OtherFormMultiSelect;
