/* eslint-disable react/jsx-props-no-spreading */
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  ElementType,
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Container,
  Row,
  Table,
} from "react-bootstrap";
import { Link, LinkProps } from "react-router-dom";
import { Column, useSortBy, useTable } from "react-table";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import TableHeaderSortArrow from "../../components/TableHeaderSortArrow";
import { useSafeAsync } from "../../hooks";
import useLoading from "../../hooks/useLoading";
import { AppRouteProps } from "../../models";
import Form from "../../models/salesforce/Form";
import FormService from "../../services/forms/FormService";
import FormAddModal from "./components/FormAddModal";
import FormEditor from "./components/FormEditor";
import FormManagerPath from "./models/FormManagerPath";

const FormManagerButton = {
  ADD_FORM: "form-manager-add-form-button",
};

const FormManager: FC = (): ReactElement => {
  const safeAsync = useSafeAsync();
  const [forms, setForms] = useState<Form[]>([]);
  const loading = useLoading();
  const [showFormAddModal, setShowFormAddModal] = useState<boolean>(false);
  const [formIdToDelete, setFormIdToDelete] = useState<string>();
  const enableDeleteButton = false;

  const handleSubmitAddForm = async (form: Form) => {
    loading(async () => {
      try {
        setShowFormAddModal(false);
        const responses = await FormService.saveForm(form);
        if (responses.every(({ success }) => success)) {
          toast.success("Successfully added form");
          setForms((previousForms) => {
            return [...previousForms, form];
          });
          setShowFormAddModal(false);
        } else {
          setShowFormAddModal(true);
          [
            { message: "Failed to add form" },
            ...responses.flatMap(({ errors }) => errors || []),
          ].forEach(({ message }) => {
            toast.error(message);
          });
        }
      } catch {
        toast.error("Failed to add form");
      }
    });
  };

  const handleConfirmDeleteForm = async (formId: string) => {
    loading(async () => {
      try {
        setFormIdToDelete(undefined);
        const responses = await FormService.deleteFormById(formId);
        if (responses.every(({ success }) => success)) {
          toast.success("Successfully deleted form");
          setForms((previousForms) =>
            previousForms.filter(({ id }) => id !== formId)
          );
          setFormIdToDelete(undefined);
        } else {
          setFormIdToDelete(formId);
          [
            { message: "Failed to delete form" },
            ...responses.flatMap(({ errors }) => errors || []),
          ].forEach(({ message }) => {
            toast.error(message);
          });
        }
      } catch {
        toast.error("Failed to delete form");
      }
    });
  };

  useEffect(() => {
    loading(() =>
      safeAsync(FormService.getAllForms()).then((response) =>
        setForms(response as Form[])
      )
    );
  }, [loading, safeAsync]);

  const columns: Column<Form>[] = useMemo(
    () => [
      {
        Header: "Form Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Version",
        accessor: "version",
      },
      {
        Header: "Feature",
        id: "feature",
        accessor: ({ feature }: Form) => feature?.name,
      },
      {
        Header: "Feature Id",
        accessor: "featureId",
      },
      {
        Header: "Action",
        disableSortBy: true,
        accessor: ({ id }: Form) => (
          <div className="d-flex justify-content-center">
            <Button
              variant="info"
              className="me-2"
              as={
                ((linkProps: LinkProps) => (
                  <Link {...linkProps} to={FormManagerPath.form(id)} />
                )) as ElementType & keyof JSX.IntrinsicElements
              }
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
            {enableDeleteButton && (
              <Button variant="danger" onClick={() => setFormIdToDelete(id)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            )}
          </div>
        ),
      },
    ],
    [enableDeleteButton]
  );

  const defaultSortBy = useMemo(() => {
    return [
      { id: "version", desc: true },
      { id: "feature", desc: false },
      { id: "name", desc: false },
    ];
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: forms,
        initialState: {
          sortBy: defaultSortBy,
        },
      },
      useSortBy
    );

  return (
    <>
      <Container className="form-manager">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Form Manager</Breadcrumb.Item>
        </Breadcrumb>
        <Row className="mb-3">
          <Col>
            <h1>Form Manager</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover size="sm" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <TableHeaderSortArrow {...column} />
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.length === 0 ? (
                  <tr>
                    <td colSpan={columns.length}>
                      <Alert variant="info" className="text-center mb-0">
                        No Options Added
                      </Alert>
                    </td>
                  </tr>
                ) : (
                  <>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="d-flex justify-content-center">
            <Button
              id={FormManagerButton.ADD_FORM}
              variant="primary"
              className="me-2"
              onClick={() => setShowFormAddModal(true)}
            >
              Add Form
            </Button>
          </Col>
        </Row>
      </Container>
      {showFormAddModal && (
        <FormAddModal
          id="form-manager-add-form-modal"
          show={showFormAddModal}
          onCancel={() => setShowFormAddModal(false)}
          onSubmit={handleSubmitAddForm}
        />
      )}
      {formIdToDelete && (
        <ConfirmationModal
          id="form-manager-delete-form-modal"
          confirmButtonProps={{
            variant: "danger",
          }}
          show={formIdToDelete !== undefined}
          title="Delete Form"
          body="Are you sure you want to delete this form?"
          onCancel={() => setFormIdToDelete(undefined)}
          onConfirm={() => handleConfirmDeleteForm(formIdToDelete)}
        />
      )}
    </>
  );
};

export default FormManager;

export const FormManagerAppRoutes: AppRouteProps[] = [
  {
    id: "form-manager",
    path: FormManagerPath.FORM_MANAGER_APP_ROUTE,
    exact: true,
    render: () => <FormManager />,
  },
  {
    id: "form-editor",
    path: FormManagerPath.FORM_EDITOR_APP_ROUTE,
    exact: false,
    render: () => <FormEditor />,
  },
];
