import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ComponentProps } from "../../models";
import ComponentService from "../../services/forms/ComponentService";
import useLoading from "../useLoading";
import useSafeAsync from "../useSafeAsync";

const useFormByFeatureName = (
  featureName: string | undefined
): {
  form: ComponentProps | undefined;
  setForm: Dispatch<SetStateAction<ComponentProps | undefined>>;
  formSections: ComponentProps[];
  refreshForm: () => void;
} => {
  const safeAsync = useSafeAsync();
  const [form, setForm] = useState<ComponentProps>();
  const loading = useLoading();

  const refreshForm = useCallback(() => {
    loading(() =>
      safeAsync(
        ComponentService.getFormComponentByFeatureName(featureName as string)
      ).then((response) => setForm(response as ComponentProps))
    );
  }, [featureName, loading, safeAsync]);

  useEffect(() => {
    if (featureName) {
      refreshForm();
    }
  }, [featureName, refreshForm]);

  return {
    form,
    setForm,
    formSections: (form?.content as ComponentProps[]) || [],
    refreshForm,
  };
};

export default useFormByFeatureName;
