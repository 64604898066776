class FormInputType {
  private static ALL_VALUES: FormInputType[] = [];

  static readonly ATTACHMENT = new FormInputType("attachment", "Attachment");

  static readonly BUTTON = new FormInputType("button", "Button");

  static readonly CHECKBOX = new FormInputType("checkbox", "Checkbox", true);

  static readonly CHECKLIST = new FormInputType("checklist", "Checklist", true);

  static readonly CHECKLIST_OTHER_MULTISELECT = new FormInputType(
    "checklistothermultiselect",
    "Checklist-Other-Multi-Select",
    true
  );

  static readonly DATE = new FormInputType("date", "Date");

  static readonly EMAIL = new FormInputType("email", "Email");

  static readonly MULTISELECT = new FormInputType(
    "multiselect",
    "Multi-Select",
    true
  );

  static readonly MULTISELECT_OTHER = new FormInputType(
    "multiselectother",
    "Multi-Select-Other",
    true
  );

  static readonly NUMBER = new FormInputType("number", "Number");

  static readonly PHONE = new FormInputType("tel", "Phone");

  static readonly RADIO = new FormInputType("radio", "Radio", true);

  static readonly SEARCH_SELECT = new FormInputType(
    "searchselect",
    "Search-Select",
    true
  );

  static readonly SEARCH_SELECT_OTHER = new FormInputType(
    "searchselectother",
    "Search-Select-Other",
    true
  );

  static readonly SELECT = new FormInputType("select", "Select", true);

  static readonly SELECT_OTHER = new FormInputType(
    "selectother",
    "Select-Other",
    true
  );

  static readonly TEXT = new FormInputType("text", "Text");

  static readonly TEXTAREA = new FormInputType("textarea", "Textarea");

  static readonly TIME = new FormInputType("time", "Time");

  static readonly TOGGLE = new FormInputType("switch", "Toggle");

  static readonly YES_NO_RADIO = new FormInputType(
    "yes-no-radio",
    "Yes-No Radio"
  );

  constructor(
    public readonly value: string,
    public readonly label: string,
    public readonly hasOptions: boolean = false
  ) {
    FormInputType.ALL_VALUES.push(this);
  }

  static values(): FormInputType[] {
    return FormInputType.ALL_VALUES;
  }

  static getByLabel(targetLabel: string): FormInputType | undefined {
    return FormInputType.ALL_VALUES.find(({ label }) => label === targetLabel);
  }
}

export default FormInputType;
