/* eslint-disable react/jsx-props-no-spreading */
import _ from "lodash";
import { FC, ReactElement, useContext, useMemo } from "react";
import { Form } from "react-bootstrap";
import { FormContext } from "../../models/FormContext";
import FormLabel from "../FormLabel";
import FormAttachment, {
  FormAttachmentProps,
} from "./components/FormAttachment";
import FormButton, { FormButtonProps } from "./components/FormButton";
import FormCheckBox from "./components/FormCheckBox";
import FormCheckList, { FormCheckListProps } from "./components/FormCheckList";
import { FormCheckProps } from "./components/FormCheckList/FormCheck";
import FormCheckListOtherMultiSelect, {
  FormCheckListOtherMultiSelectProps,
} from "./components/FormCheckListOtherMultiSelect";
import FormControl, { FormControlProps } from "./components/FormControl";
import FormDate, { FormDateProps } from "./components/FormDate";
import FormMultiSelect, {
  FormMultiSelectProps,
} from "./components/FormMultiSelect";
import FormMultiSelectOther, {
  FormMultiSelectOtherProps,
} from "./components/FormMultiSelectOther";
import FormSearchSelect, {
  FormSearchSelectProps,
} from "./components/FormSearchSelect";
import FormSearchSelectOther, {
  FormSearchSelectOtherProps,
} from "./components/FormSearchSelectOther";
import FormSelect, { FormSelectProps } from "./components/FormSelect";
import FormSelectOther, {
  FormSelectOtherProps,
} from "./components/FormSelectOther";
import FormTextarea, { FormTextareaProps } from "./components/FormTextarea";
import FormValidation from "./components/FormValidation";
import FormInputProps from "./models/FormInputProps";

const FormInput: FC<FormInputProps> = (props: FormInputProps): ReactElement => {
  const {
    id,
    label,
    type,
    showRequired,
    required,
    invalid,
    validationMessage,
    infoProps,
    requiredMessage,
    ...otherProps
  } = props;

  const {
    getRequest,
    validateFields,
    alwaysValidateFields,
    optionsByFieldNameMap,
  } = useContext(FormContext);

  const hasOptionsFromContext = useMemo(() => {
    const { name } = otherProps;
    return (
      (optionsByFieldNameMap?.[name] ?? []).length > 0 ||
      (_.get(optionsByFieldNameMap, name) ?? []).length > 0
    );
  }, [optionsByFieldNameMap, otherProps]);

  const componentProps: FormInputProps = useMemo(() => {
    type ValidateEventHandler = "onBlur" | "onAfterChange";
    let validateEventHandlerName: ValidateEventHandler = "onBlur";
    if (
      [
        "attachment",
        "button",
        "checkbox",
        "radio",
        "switch",
        "checklist",
        "checklistothermultiselect",
        "multiselect",
        "multiselectother",
        "searchselect",
        "searchselectother",
        "select",
        "selectother",
      ].includes(type)
    ) {
      validateEventHandlerName = "onAfterChange";
    }
    return {
      ...props,
      [validateEventHandlerName]: async (event: unknown) => {
        const { name, [validateEventHandlerName]: validateEventHandler } =
          props;
        if (
          ((invalid && validationMessage) || alwaysValidateFields) &&
          getRequest &&
          validateFields
        ) {
          await validateFields(getRequest(), name);
        }
        if (validateEventHandler) {
          validateEventHandler(event);
        }
      },
    };
  }, [
    alwaysValidateFields,
    getRequest,
    invalid,
    props,
    type,
    validateFields,
    validationMessage,
  ]);

  const componentByType = useMemo(() => {
    switch (type) {
      case "attachment":
        return <FormAttachment {...(componentProps as FormAttachmentProps)} />;
      case "button":
        return <FormButton {...(componentProps as FormButtonProps)} />;
      case "checkbox":
      case "radio":
      case "switch":
        if (
          (componentProps as FormCheckListProps)?.options?.length ||
          (componentProps as FormCheckListProps)?.optionsEndpoint ||
          hasOptionsFromContext
        ) {
          return <FormCheckList {...(componentProps as FormCheckListProps)} />;
        }
        return <FormCheckBox {...(componentProps as FormCheckProps)} />;
      case "checklist":
        return <FormCheckList {...(componentProps as FormCheckListProps)} />;
      case "checklistothermultiselect":
        return (
          <FormCheckListOtherMultiSelect
            {...(componentProps as FormCheckListOtherMultiSelectProps)}
          />
        );
      case "date":
        return <FormDate {...(componentProps as FormDateProps)} />;
      case "multiselect":
        return (
          <FormMultiSelect {...(componentProps as FormMultiSelectProps)} />
        );
      case "multiselectother":
        return (
          <FormMultiSelectOther
            {...(componentProps as FormMultiSelectOtherProps)}
          />
        );
      case "searchselect":
        return (
          <FormSearchSelect {...(componentProps as FormSearchSelectProps)} />
        );
      case "searchselectother":
        return (
          <FormSearchSelectOther
            {...(componentProps as FormSearchSelectOtherProps)}
          />
        );
      case "select":
        return <FormSelect {...(componentProps as FormSelectProps)} />;
      case "selectother":
        return (
          <FormSelectOther {...(componentProps as FormSelectOtherProps)} />
        );
      case "textarea":
        return <FormTextarea {...(componentProps as FormTextareaProps)} />;
      default:
        return <FormControl {...(componentProps as FormControlProps)} />;
    }
  }, [componentProps, hasOptionsFromContext, type]);

  const wrapInGroup = useMemo(() => {
    switch (type) {
      case "attachment":
      case "button":
      case "checkbox":
      case "checklist":
      case "checklistothermultiselect":
      case "radio":
      case "switch":
        return false;
      default:
        return true;
    }
  }, [type]);

  if (wrapInGroup && label) {
    return (
      <Form.Group className={otherProps?.className}>
        <>
          <FormLabel
            label={label}
            required={required}
            showRequired={showRequired}
            infoProps={infoProps}
            requiredMessage={requiredMessage}
          />
          <div className={invalid ? "form-invalid-input" : ""}>
            {componentByType}
          </div>
          {validationMessage && <FormValidation message={validationMessage} />}
        </>
      </Form.Group>
    );
  }

  return <div className={otherProps?.className}>{componentByType}</div>;
};

export { FormCheckList, FormControl, FormSelect, FormInput as default };
