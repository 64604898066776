import AppPath from "../../../components/App/models/AppPath";
import FeatureName from "../../../components/App/models/FeatureName";
import ManagedContent from "../../shared/src/components/ManagedContent";
import ManagedContentName from "./ManagedContentName";

class MOCPath extends AppPath {
  public static readonly MOC = new AppPath(
    "pim",
    (
      <ManagedContent
        name={ManagedContentName.ProgramsInvolvingMinorsLabelForm}
        featureName={FeatureName.MinorsOnCampusRegistrationForm}
      />
    ),
    "/pim/form/:id?"
  );

  public static readonly MOCSubmissions = new AppPath(
    "pim-submissions",
    (
      <ManagedContent
        name={ManagedContentName.ProgramsInvolvingMinorsLabelRegistration}
        featureName={FeatureName.MinorsOnCampusRegistrationForm}
      />
    ),
    "/pim/submitter-dashboard"
  );

  public static readonly MOCSubmissionsApprover = new AppPath(
    "pim-approver",
    (
      <ManagedContent
        name={ManagedContentName.ProgramsInvolvingMinorsLabelApprovers}
        featureName={FeatureName.MinorsOnCampusRegistrationForm}
      />
    ),
    "/pim/approver-dashboard"
  );
}

export default MOCPath;
