import { CacheAxiosResponse } from "axios-cache-interceptor";
import _ from "lodash";
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Form } from "react-bootstrap";
import { FormCheckType } from "react-bootstrap/esm/FormCheck";
import { v4 as uuid } from "uuid";
import { useSafeAsync } from "../../../../../../hooks";
import { BaseService } from "../../../../../../services";
import { FormContext } from "../../../../models/FormContext";
import FormLabel from "../../../FormLabel";
import FormInputProps from "../../models/FormInputProps";
import { formatOptionsUrl } from "../../utils/FormInputUtil";
import FormValidation from "../FormValidation";
import FormCheck, { FormCheckProps } from "./FormCheck";

export interface FormCheckListProps extends FormInputProps {
  options: FormCheckProps[];
  onChange?: ChangeEventHandler<HTMLInputElement>;
  inline?: boolean;
  inlineLabel?: boolean;
  disabled?: boolean;
  formCheckProps?: string;
}

const FormCheckList: FC<FormCheckListProps> = ({
  id,
  name,
  label,
  type,
  required,
  showRequired,
  onChange,
  options,
  optionsEndpoint,
  inline,
  inlineLabel,
  className,
  disabled,
  invalid,
  validationMessage,
  infoProps,
  requiredMessage,
  onAfterChange,
  formCheckProps: formCheckPropsString,
}: FormCheckListProps): ReactElement => {
  const safeAsync = useSafeAsync();
  const [value, setValue] = useState<string | string[]>();
  const { initialRequest, setRequest, optionsByFieldNameMap } =
    useContext(FormContext);
  const [stateOptions, setStateOptions] = useState<FormCheckProps[]>(
    options ?? []
  );

  // TODO: update usage from initialRequest to actual request
  const formattedOptionsEndpoint = useMemo(() => {
    if (optionsEndpoint) {
      return formatOptionsUrl(optionsEndpoint, initialRequest);
    }
    return optionsEndpoint;
  }, [optionsEndpoint, initialRequest]);

  useEffect(() => {
    if ((optionsByFieldNameMap?.[name] ?? []).length > 0) {
      setStateOptions(
        (optionsByFieldNameMap?.[name] ?? []) as FormCheckProps[]
      );
    } else if ((_.get(optionsByFieldNameMap, name) ?? []).length > 0) {
      setStateOptions(
        (_.get(optionsByFieldNameMap, name) ?? []) as FormCheckProps[]
      );
    } else if (formattedOptionsEndpoint) {
      safeAsync(
        BaseService.get<FormCheckProps[]>({ url: formattedOptionsEndpoint })
      )
        .then((response) => response as CacheAxiosResponse<FormCheckProps[]>)
        .then((response) => response.data || [])
        .then(setStateOptions);
    }
  }, [formattedOptionsEndpoint, name, optionsByFieldNameMap, safeAsync]);

  const isInline = useCallback(() => {
    return inline ?? stateOptions.every((x) => x?.inline);
  }, [inline, stateOptions]);

  const onChangeHandler = useMemo(() => {
    return (
      event: ChangeEvent<HTMLInputElement>,
      newValue?: string | string[]
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (onChange !== undefined) {
        onChange(event);
      } else if (setRequest !== undefined) {
        setRequest((request) => {
          return _.setWith(_.clone(request), name, newValue, _.clone);
        });
      }
      if (onAfterChange) {
        onAfterChange(event);
      }
    };
  }, [name, onAfterChange, onChange, setRequest]);

  const handleOnChange = useMemo(() => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value: optionValue, checked },
      } = event;
      setValue((previousValue) => {
        let newValue: string | string[] | undefined;
        if (type === "checkbox") {
          if (checked && !_.isArray(previousValue)) {
            newValue = [optionValue];
          } else if (_.isArray(previousValue)) {
            if (checked) {
              newValue = _.uniq([...previousValue, optionValue]);
            } else {
              newValue = previousValue.filter((x) => x !== optionValue);
            }
          } else {
            newValue = previousValue;
          }
        } else {
          newValue = optionValue;
        }
        onChangeHandler(event, newValue);
        return newValue;
      });
    };
  }, [onChangeHandler, type]);

  useEffect(() => {
    setValue(_.get(initialRequest, name) as string | string[]);
  }, [initialRequest, name]);

  const formCheckProps = useMemo(() => {
    if (formCheckPropsString) {
      return JSON.parse(formCheckPropsString) as Record<string, unknown>;
    }
    return {};
  }, [formCheckPropsString]);

  const formChecks = (
    <>
      {stateOptions.map((optionProps, index) => {
        return (
          <FormCheck
            {...optionProps}
            {...formCheckProps}
            inline={isInline()}
            key={uuid()}
            type={type as FormCheckType}
            name={name}
            required={index === 0 ? required : false}
            checked={
              type === "checkbox"
                ? (value ?? []).includes(optionProps.value)
                : value === optionProps.value
            }
            onChange={handleOnChange}
          />
        );
      })}
    </>
  );

  return (
    <fieldset disabled={disabled}>
      <Form.Group controlId={id} className={className}>
        {label && (
          <FormLabel
            label={label}
            required={required}
            showRequired={showRequired}
            inline={inlineLabel && isInline()}
            infoProps={infoProps}
            requiredMessage={requiredMessage}
          />
        )}
        <div className={invalid ? "form-invalid-input" : ""}>
          {inlineLabel ? formChecks : <div>{formChecks}</div>}
        </div>
        {validationMessage && <FormValidation message={validationMessage} />}
      </Form.Group>
    </fieldset>
  );
};

FormCheckList.defaultProps = {
  onChange: undefined,
  inline: false,
  inlineLabel: false,
  disabled: false,
  formCheckProps: undefined,
};

export default FormCheckList;
