import _ from "lodash";
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
} from "react";
import { FormRequest, parseValueAsString } from "../../../models/FormRequest";

export const formatOptionsUrl = (
  optionsEndpoint: string,
  request: FormRequest
): string => {
  const parametersWithBrackets = _.uniq(
    optionsEndpoint.match(/{[A-Za-z0-9.\\[\]]+}/g) || []
  );
  let url = optionsEndpoint;
  parametersWithBrackets.forEach((parameterWithBrackets) => {
    const parameter = parameterWithBrackets.substring(
      1,
      parameterWithBrackets.length - 1
    );
    url = url.replaceAll(
      parameterWithBrackets,
      parseValueAsString(parameter, request)
    );
  });
  return url;
};

export function buildOnChangeHandler(
  onChange:
    | ChangeEventHandler<
        HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
      >
    | undefined,
  setRequest: Dispatch<SetStateAction<FormRequest>> | undefined,
  name: string,
  onAfterChange?: (event?: unknown) => void
): ChangeEventHandler<
  HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
> {
  return (
    event: ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (onChange !== undefined) {
      onChange(event);
    } else if (setRequest !== undefined) {
      const { value } = event.target;
      setRequest((request) => {
        return _.setWith(_.clone(request), name, value, _.clone);
      });
    }
    if (onAfterChange) {
      onAfterChange(event);
    }
  };
}
