/* eslint-disable react/jsx-props-no-spreading */
import React, {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { Alert } from "react-bootstrap";
import ComponentProps from "../../../../models/ComponentProps";
import { ValidationError } from "../../../../models/ErrorResponse";
import Component from "../../../Component";
import { FormContext } from "../../models/FormContext";
import { FormCustomComponentContext } from "../../models/FormCustomComponentContext";
import { FormRequest } from "../../models/FormRequest";
import FormValidation from "../FormInput/components/FormValidation";

type Props = {
  id: string;
  className?: string;
  invalid?: boolean;
  validationMessage?: string | ReactElement;
};

const FormCustomComponent: FC<ComponentProps> = ({
  content,
  ...props
}: ComponentProps): ReactElement => {
  const {
    id,
    className: classNameProp,
    invalid,
    validationMessage,
  } = (props as unknown as Props) || {};

  const { validateFields, alwaysValidateFields } = useContext(FormContext);

  const className = useMemo(() => {
    return classNameProp ? ` ${classNameProp}` : "";
  }, [classNameProp]);

  const handleValidateFields = useCallback(
    async (
      formRequest: FormRequest,
      ...fieldNames: string[]
    ): Promise<Record<string, ValidationError[]>> => {
      if (
        ((invalid && validationMessage) || alwaysValidateFields) &&
        formRequest &&
        validateFields
      ) {
        return validateFields(formRequest, ...fieldNames);
      }
      return {};
    },
    [alwaysValidateFields, invalid, validateFields, validationMessage]
  );

  const formCustomComponentContext = useMemo(() => {
    return {
      validateFields: handleValidateFields,
    };
  }, [handleValidateFields]);

  return (
    <FormCustomComponentContext.Provider value={formCustomComponentContext}>
      <div id={id} className={`form-custom-component${className}`}>
        {validationMessage && (
          <Alert variant="danger">
            <FormValidation message={validationMessage} />
          </Alert>
        )}
        <div className={invalid ? "border border-danger" : ""}>
          <Component {...(content as ComponentProps)} />
        </div>
      </div>
    </FormCustomComponentContext.Provider>
  );
};

export default FormCustomComponent;
