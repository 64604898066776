import { Button } from "react-bootstrap";
import { Col, Form, Row } from "../components";
import FormCustomComponent from "../components/Form/components/FormCustomComponent";
import FormInput, {
  FormCheckList,
  FormControl,
  FormSelect,
} from "../components/Form/components/FormInput";
import FormSection from "../components/Form/components/FormSection";
import ManagedContentFromSalesforceObject from "../components/ManagedContent/components/ManagedContentFromSalesforceObject";
import ComponentManager from "./ComponentManager";

export function initializeComponents(): void {
  ComponentManager.addComponents(
    {
      Form,
      FormSection,
      FormInput,
      FormCheckList,
      FormControl,
      FormSelect,
      Row,
      Col,
      Button,
      ManagedContent: ManagedContentFromSalesforceObject,
      FormCustomComponent,
    },
    true
  );
  ComponentManager.addComponents(
    {
      Button,
    },
    false
  );
}

export default { initializeComponents };
