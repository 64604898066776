import { InfoIconProps } from "../../FormLabel/components/InfoIcon";
import { FormControlProps } from "../components/FormControl";
import { FormMultiSelectProps } from "../components/FormMultiSelect";

export type FormOtherProps = {
  otherName: string;
  otherValue: string;
  otherLabel: string;
  otherPlaceholder?: string;
  otherRequired?: boolean;
  otherRequiredMessage?: string;
  otherInfoProps?: InfoIconProps | string;
  otherProps?: string;
};

export const DEFAULT_OTHER_LABEL = "Other";

export const DEFAULT_OTHER_PLACEHOLDER = "Enter Other...";

export type OtherFormControlProps = FormControlProps & {
  targetValue: string;
};

export type OtherFormMultiSelectProps = FormMultiSelectProps & {
  targetValue: string;
};
