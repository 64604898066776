import _ from "lodash";
import React, {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  FormControlProps as BootstrapFormControlProps,
  Form,
} from "react-bootstrap";
import { FormContext } from "../../../../models/FormContext";
import FormInputProps from "../../models/FormInputProps";
import { buildOnChangeHandler } from "../../utils/FormInputUtil";
import FormValidation from "../FormValidation";

export type FormControlProps = Omit<FormInputProps, "label"> &
  Partial<Pick<FormInputProps, "label">> &
  Omit<BootstrapFormControlProps, "id" | "type"> & {
    onChange?: ChangeEventHandler<HTMLInputElement>;
    disableOnWheel?: boolean;
    characterLimit?: number;
  };

const FormControl: FC<FormControlProps> = (
  allProps: FormControlProps
): ReactElement => {
  const {
    id,
    name,
    showRequired,
    onChange,
    label,
    // extract props to prevent React warnings
    invalid: invalidProp,
    validationMessage: validationMessageProp,
    infoProps,
    requiredMessage,
    onAfterChange,
    disableOnWheel,
    characterLimit,
    ...props
  } = allProps;
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>("");
  const { initialRequest, setRequest, isPrintMode } = useContext(FormContext);
  const [validationMessage, setValidationMessage] = useState<string>("");
  const [charactersRemaining, setCharactersRemaining] = useState(
    _.toFinite(characterLimit)
  );

  const showValidationMessage = useMemo(
    () => !invalidProp && !validationMessageProp && validationMessage,
    [invalidProp, validationMessage, validationMessageProp]
  );

  const type = useMemo(() => props.type, [props]);

  useEffect(() => {
    if (characterLimit && type !== "date") {
      const characterCount = _.isString(value) ? value.length : 0;
      setCharactersRemaining(_.toFinite(characterLimit) - characterCount);
    }
  }, [characterLimit, type, value]);

  const min = useMemo(() => {
    if (props.type === "number") {
      const propsMap = props as unknown as Record<string, string>;
      if (propsMap.min) {
        return parseInt(propsMap.min, 10);
      }
    }
    return undefined;
  }, [props]);

  const max = useMemo(() => {
    if (props.type === "number") {
      const propsMap = props as unknown as Record<string, string>;
      if (propsMap.max) {
        return parseInt(propsMap.max, 10);
      }
    }
    return undefined;
  }, [props]);

  const step = useMemo(() => {
    if (props.type === "number") {
      const propsMap = props as unknown as Record<string, string>;
      if (propsMap.step) {
        return parseInt(propsMap.step, 10);
      }
    }
    return undefined;
  }, [props]);

  const handleOnWheel = useMemo(() => {
    if (props.type === "number" && disableOnWheel) {
      return () => {
        inputRef.current?.blur();

        setTimeout(() => inputRef.current?.focus());
      };
    }
    return undefined;
  }, [disableOnWheel, props.type]);

  const onChangeHandler = useMemo(() => {
    return buildOnChangeHandler(onChange, setRequest, name, onAfterChange);
  }, [name, onAfterChange, onChange, setRequest]);

  const handleOnChange = useMemo(() => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value: newValue },
      } = event;

      if (
        type === "date" ||
        !characterLimit ||
        (characterLimit && newValue.length <= _.toFinite(characterLimit))
      ) {
        if (characterLimit) {
          setCharactersRemaining(_.toFinite(characterLimit) - newValue.length);
        }
        setValue(newValue);
        onChangeHandler(event);
      }
    };
  }, [onChangeHandler, characterLimit, type]);

  useEffect(() => {
    setValue((_.get(initialRequest, name) as string) ?? "");
  }, [name, initialRequest]);

  useEffect(() => {
    if (type === "number" && value) {
      const validatedValue = parseFloat(value);
      if (step !== undefined) {
        if (validatedValue % step !== 0) {
          const greatestCommonDivisor = Math.floor(validatedValue / step);
          const lowerValidValue = greatestCommonDivisor * step;
          const upperValidValue = (greatestCommonDivisor + 1) * step;
          setValidationMessage(
            `Please enter a valid value. The two nearest valid values are ${lowerValidValue} and ${upperValidValue}.`
          );
          return;
        }
      }
      if (min !== undefined && validatedValue < min) {
        setValidationMessage(`Value must be greater than or equal to ${min}.`);
        return;
      }
      if (max !== undefined && validatedValue > max) {
        setValidationMessage(`Value must be less than or equal to ${max}.`);
        return;
      }
    }
    setValidationMessage("");
  }, [max, min, step, type, value]);

  return (
    <>
      <div className={showValidationMessage ? "form-invalid-input" : ""}>
        {isPrintMode ? (
          <div className="printable-form-control form-control">
            {value || <>&nbsp;</>}
          </div>
        ) : (
          <>
            <Form.Control
              onWheel={handleOnWheel}
              {...props}
              ref={inputRef}
              id={id}
              name={name}
              value={value}
              onChange={handleOnChange}
            />
            {characterLimit && type !== "date" && (
              <div className="text-muted">
                Characters remaining: {charactersRemaining}
              </div>
            )}
          </>
        )}
      </div>
      {showValidationMessage && <FormValidation message={validationMessage} />}
    </>
  );
};

FormControl.defaultProps = {
  onChange: undefined,
  disableOnWheel: false,
  characterLimit: undefined,
};

export default React.memo(FormControl);
