import FormCustomComponent from "../../../models/salesforce/FormCustomComponent";
import SalesforceResponse from "../../../models/salesforce/SalesforceResponse";
import FormSalesforceObjectService from "../FormSalesforceObjectService";

class FormCustomComponentService extends FormSalesforceObjectService {
  private static readonly OBJECT_NAME = "form-custom-components";

  /**
   * Save form custom component
   * @param formCustomComponent
   * @param depth depth of salesforce nested objects
   * @returns array of salesforce responses
   */
  public static async saveFormCustomComponent(
    formCustomComponent: FormCustomComponent,
    depth: number | undefined = undefined
  ): Promise<SalesforceResponse[]> {
    return super.save<FormCustomComponent>(
      this.OBJECT_NAME,
      [formCustomComponent],
      depth
    );
  }

  /**
   * Delete form custom component by id
   * @param formCustomComponentId
   * @param depth depth of salesforce nested objects
   * @returns array of salesforce responses
   */
  public static async deleteFormCustomComponentById(
    formCustomComponentId: string,
    depth: number | undefined = undefined
  ): Promise<SalesforceResponse[]> {
    return super.deleteById(this.OBJECT_NAME, [formCustomComponentId], depth);
  }
}

export default FormCustomComponentService;
