import { FC, ReactElement } from "react";
import FormControl, { FormControlProps } from "../FormControl";

export type FormTextareaProps = FormControlProps;

const FormTextarea: FC<FormTextareaProps> = (props): ReactElement => {
  return <FormControl {...props} as="textarea" />;
};

export default FormTextarea;
