import _ from "lodash";
import {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { v4 as uuid } from "uuid";
import { FormContext } from "../../../../models/FormContext";
import FormOption from "../../models/FormOption";
import {
  DEFAULT_OTHER_LABEL,
  DEFAULT_OTHER_PLACEHOLDER,
  FormOtherProps,
  OtherFormControlProps,
} from "../../models/FormOtherProps";
import FormSearchSelect, { FormSearchSelectProps } from "../FormSearchSelect";
import OtherFormControl from "../OtherFormControl";

export type FormSearchSelectOtherProps = FormSearchSelectProps & FormOtherProps;

const FormSearchSelectOther: FC<FormSearchSelectOtherProps> = ({
  otherName,
  otherValue,
  otherLabel,
  otherPlaceholder,
  otherRequired,
  otherRequiredMessage,
  otherInfoProps,
  otherProps,
  onChange,
  onAfterChange,
  ...props
}: FormSearchSelectOtherProps): ReactElement => {
  const { name, disabled, isDisabled } = props;
  const { initialRequest, setRequest } = useContext(FormContext);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const otherFormControls = useMemo<OtherFormControlProps[]>(() => {
    if (otherProps) {
      return (JSON.parse(otherProps) as OtherFormControlProps[]).map(
        (formControlProps) => {
          return {
            ...formControlProps,
            id: uuid(),
            type: "text",
            placeholder:
              formControlProps?.placeholder || DEFAULT_OTHER_PLACEHOLDER,
            label: formControlProps?.label || DEFAULT_OTHER_LABEL,
          };
        }
      );
    }
    return [
      {
        id: uuid(),
        name: otherName,
        type: "text",
        placeholder: otherPlaceholder || DEFAULT_OTHER_PLACEHOLDER,
        targetValue: otherValue,
        label: otherLabel || DEFAULT_OTHER_LABEL,
        required: otherRequired,
        requiredMessage: otherRequiredMessage,
        infoProps: otherInfoProps,
      },
    ];
  }, [
    otherInfoProps,
    otherLabel,
    otherName,
    otherPlaceholder,
    otherProps,
    otherRequired,
    otherRequiredMessage,
    otherValue,
  ]);

  const updateSelectedValues = useCallback(
    (selectedOptions: FormOption | FormOption[]) => {
      const newSelectedValues: string[] = [];
      if (Array.isArray(selectedOptions)) {
        // handles multiselect
        ((selectedOptions as FormOption[]) || []).forEach(({ value }) =>
          newSelectedValues.push(value)
        );
      } else {
        // handles single select
        const { value } = (selectedOptions as FormOption) || {};
        if (value) {
          newSelectedValues.push(value);
        }
      }
      setSelectedValues(newSelectedValues);
    },
    []
  );

  const onChangeHandler = useMemo(() => {
    return (selectedOptions: FormOption | FormOption[]) => {
      if (onChange !== undefined) {
        onChange(selectedOptions);
      } else if (setRequest !== undefined) {
        setRequest((previousRequest) => {
          return _.setWith(
            _.clone(previousRequest),
            name,
            selectedOptions,
            _.clone
          );
        });
      } else {
        throw new Error(`No onChange event defined for input field: ${name}`);
      }
      if (onAfterChange) {
        onAfterChange();
      }
    };
  }, [name, onAfterChange, onChange, setRequest]);

  const handleSelectOnChange = useMemo(() => {
    return (selectedOptions: unknown) => {
      onChangeHandler(selectedOptions as FormOption | FormOption[]);
      updateSelectedValues(selectedOptions as FormOption | FormOption[]);
    };
  }, [onChangeHandler, updateSelectedValues]);

  useEffect(() => {
    updateSelectedValues(
      _.get(initialRequest, name) as FormOption | FormOption[]
    );
  }, [initialRequest, name, updateSelectedValues]);

  return (
    <>
      <FormSearchSelect {...props} onChange={handleSelectOnChange} />
      {otherFormControls
        .filter(({ targetValue }) => selectedValues.includes(targetValue))
        .map((otherFormControlProps) => (
          <OtherFormControl
            key={otherFormControlProps.id}
            {...otherFormControlProps}
            disabled={disabled || isDisabled}
          />
        ))}
    </>
  );
};

export default FormSearchSelectOther;
