/* istanbul ignore file */
import { useCallback } from "react";
import useMountedState from "../useMountedState";

/**
 * WARNING: Can potentially cause a bug where the promise is never settled.
 * Since the promise potentially is never settled, any finally block awaiting
 * the promise might never be invoked.
 *
 * @example
 * const loading = useLoading();
 * const safeAsync = useSafeAsync();
 *
 * loading(safeAsync(...)); // can result in a potential bug where the loading spinner is never dismissed
 *
 * safeAsync(loading(...)).then(...) // correct usage
 *
 * {@link https://www.benmvp.com/blog/handling-async-react-component-effects-after-unmount/ Reference}
 */
const useSafeAsync = <T = unknown>(): ((
  promise: Promise<T>
) => Promise<T | undefined>) => {
  const isMounted = useMountedState();

  return useCallback(
    (promise: Promise<T>) =>
      new Promise((resolve, reject) => {
        if (promise) {
          promise
            .then((value) => {
              if (isMounted()) {
                resolve(value);
              }
            })
            .catch((error) => {
              if (isMounted()) {
                reject(error);
              }
            });
        } else if (isMounted()) {
          resolve(undefined);
        }
      }),
    [isMounted]
  );
};

export default useSafeAsync;
