import FormInputProperty from "../../../models/salesforce/FormInputProperty";
import SalesforceResponse from "../../../models/salesforce/SalesforceResponse";
import FormSalesforceObjectService from "../FormSalesforceObjectService";

class FormInputPropertyService extends FormSalesforceObjectService {
  private static readonly OBJECT_NAME = "form-input-properties";

  /**
   * Save form input property
   * @param formInputProperty
   * @param depth depth of salesforce nested objects
   * @returns array of salesforce responses
   */
  public static async saveFormInputProperty(
    formInputProperty: FormInputProperty,
    depth: number | undefined = undefined
  ): Promise<SalesforceResponse[]> {
    return super.save<FormInputProperty>(
      this.OBJECT_NAME,
      [formInputProperty],
      depth
    );
  }

  /**
   * Delete form input property by id
   * @param formInputPropertyId
   * @param depth depth of salesforce nested objects
   * @returns array of salesforce responses
   */
  public static async deleteFormInputPropertyById(
    formInputPropertyId: string,
    depth: number | undefined = undefined
  ): Promise<SalesforceResponse[]> {
    return super.deleteById(this.OBJECT_NAME, [formInputPropertyId], depth);
  }
}

export default FormInputPropertyService;
