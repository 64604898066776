import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import AppPath from "../../../components/App/models/AppPath";
import AppHeaderLink, {
  AppHeaderLinkType,
} from "../../shared/src/components/AppHeader/AppHeaderLink";
import AppRouteProps from "../../shared/src/models/AppRouteProps";
import {
  AAEEventsReservationRequestForm,
  AAEEventsReservationRequestFormSuccess,
} from "../pages";

export class AAEEventsPath extends AppPath {
  public static readonly AAEEventsReservationRequestForm = new AAEEventsPath(
    "aae-events",
    "AAE Events",
    "/forms/aae-events"
  );

  public static readonly AAEEventsReservationRequestFormSuccess =
    new AAEEventsPath(
      "aae-events-success",
      "AAE Event Reservation Request Submission Success",
      "/forms/aae-events/success"
    );
}

export const AAEEventsAppHeaderLinks: AppHeaderLink[] = [
  {
    ...AAEEventsPath.AAEEventsReservationRequestForm,
    type: AppHeaderLinkType.LINK,
  },
];

export const AAEEventsAppRoutes: AppRouteProps[] = [
  {
    ...AAEEventsPath.AAEEventsReservationRequestFormSuccess,
    exact: true,
    render: () => <AAEEventsReservationRequestFormSuccess />,
    hideHeader: true,
  },
  {
    ...AAEEventsPath.AAEEventsReservationRequestForm,
    exact: true,
    render: () => <AAEEventsReservationRequestForm />,
    hideHeader: true,
  },
];

export const AAEEventsRouter: FC = () => {
  return (
    <Switch>
      {AAEEventsAppRoutes.map(({ id, exact, path, render }) => (
        <Route
          key={id}
          exact={exact}
          path={path}
          render={(props) => <>{render?.(props)}</>}
        />
      ))}
    </Switch>
  );
};

export const AAEEventsRoutes: AppRouteProps[] = [
  {
    id: "aae-events",
    path: "/form/aae-events",
    exact: false,
    render: () => <AAEEventsRouter />,
  },
];
