import { Row, RowData, flexRender } from "@tanstack/react-table";
import { MouseEventHandler, ReactElement } from "react";

export type TableRowProps<TData extends RowData> = {
  row: Row<TData>;
  className?: string;
  onClick?: MouseEventHandler<HTMLTableRowElement>;
};

const TableRow = <T,>({ row, ...trProps }: TableRowProps<T>): ReactElement => {
  return (
    <tr {...trProps}>
      {row.getVisibleCells().map((cell) => {
        return (
          <td key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        );
      })}
    </tr>
  );
};

TableRow.defaultProps = {
  className: undefined,
  onClick: undefined,
};

export default TableRow;
