import React, {
  FC,
  MouseEvent,
  MouseEventHandler,
  ReactElement,
  useMemo,
  useState,
} from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import FormInput from "../../../../components/Form/components/FormInput";
import { FormContext } from "../../../../components/Form/models/FormContext";
import Modal from "../../../../components/Modal";
import useLoading from "../../../../hooks/useLoading";
import Form from "../../../../models/salesforce/Form";
import FormService from "../../../../services/forms/FormService";

export type FormAddModalProps = {
  id: string;
  show: boolean;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onSubmit: (form: Form) => void;
};

const FormAddModal: FC<FormAddModalProps> = ({
  id,
  show,
  onCancel,
  onSubmit,
}: FormAddModalProps): ReactElement => {
  const initialRequest = useMemo(() => ({}), []);
  const [form, setForm] = useState<Form>({});
  const loading = useLoading();

  const handleSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
    loading(async () => {
      try {
        event.preventDefault();
        event.stopPropagation();
        const responses = await FormService.saveForm(form);
        if (responses.every(({ success }) => success)) {
          onSubmit({
            ...form,
            id: responses[0]?.id,
          });
        } else {
          [
            {
              message: "Failed to create form",
            },
            ...responses.flatMap(({ errors }) => errors || []),
          ].forEach(({ message }) => toast.error(message));
        }
      } catch {
        toast.error("Failed to create form");
      }
    });
  };

  return (
    <Modal
      id={id}
      show={show}
      title="Add Form"
      body={
        <>
          <FormContext.Provider
            value={{
              initialRequest,
              setRequest: setForm,
              disabled: false,
            }}
          >
            <Row>
              <Col className="mb-3">
                <FormInput
                  id="add-form-name"
                  name="name"
                  label="Name"
                  type="text"
                  required
                />
                <FormInput
                  id="add-form-featureId"
                  name="featureId"
                  label="Feature Id"
                  type="text"
                  required
                />
                <FormInput
                  id="add-form-section-version"
                  name="version"
                  label="Version"
                  type="text"
                />
                <FormInput
                  id="add-form-section-description"
                  name="description"
                  label="Description"
                  type="text"
                />
              </Col>
            </Row>
          </FormContext.Provider>
        </>
      }
      onCancel={onCancel}
      onSubmit={handleSubmit}
    />
  );
};

export default FormAddModal;
