import { useEffect, useState } from "react";
import { Person } from "../../models";
import ShibbService, { LoggedInUser } from "../../services/ShibbService";
import useSafeAsync from "../useSafeAsync";

const useLoggedInUser = (): LoggedInUser => {
  const safeAsync = useSafeAsync();
  const [loggedInUser, setLoggedInUser] = useState<LoggedInUser>({
    ppid: "",
    name: "",
  });

  useEffect(() => {
    safeAsync(ShibbService.getLoggedInUser()).then((response) => {
      const person = response as Person;
      setLoggedInUser({
        ...((response as Person) || {}),
        ppid: person?.ppid ?? "",
        name:
          (person &&
            person.firstName &&
            person.lastName &&
            `${person.firstName} ${person.lastName}`) ||
          "",
      });
    });
  }, [safeAsync]);

  return loggedInUser;
};

export default useLoggedInUser;
