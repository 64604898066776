/* eslint-disable react/jsx-props-no-spreading */
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  FC,
  FocusEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { Form, FormControlProps } from "react-bootstrap";
import "./styles.scss";

type EditableFormControlProps = FormControlProps;

const EditableFormControl: FC<EditableFormControlProps> = ({
  ...formControlProps
}: EditableFormControlProps): ReactElement => {
  const [editable, setEditable] = useState<boolean>(false);
  const formControlRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  useEffect(() => {
    if (editable) {
      formControlRef?.current?.focus();
    }
  }, [editable]);

  return (
    <div className="editable-form-control">
      {editable ? (
        <Form.Control
          {...formControlProps}
          onBlur={(event: FocusEvent<HTMLInputElement>) => {
            setEditable(false);
            if (formControlProps.onBlur) {
              formControlProps.onBlur(event);
            }
          }}
          ref={formControlRef}
        />
      ) : (
        <div
          className=" p-2"
          style={{
            position: "relative",
            border: "1px solid",
            borderRadius: "10px",
          }}
        >
          <FontAwesomeIcon
            icon={faPencilAlt}
            style={{
              position: "absolute",
              right: "-7px",
              top: "-7px",
              cursor: "pointer",
            }}
            onClick={() => setEditable(true)}
          />
          {formControlProps.value || (
            <span className="text-placeholder">
              {formControlProps.placeholder}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default EditableFormControl;
