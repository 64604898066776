/* eslint-disable react/jsx-props-no-spreading */
import { FC, ReactElement, useEffect, useState } from "react";
import { useSafeAsync } from "../../../../hooks";
import useLoading from "../../../../hooks/useLoading";
import SalesforceManagedContent from "../../../../models/salesforce/ManagedContent";
import ManagedContentService from "../../../../services/forms/ManagedContentService";
import { EditableManagedContentProps } from "../../models/EditableManagedContentProps";
import ManagedContentFromSalesforceObject from "../ManagedContentFromSalesforceObject";

export type ManagedContentFromNameAndFeatureNameProps =
  EditableManagedContentProps & {
    name: string;
    featureName: string;
  };

const ManagedContentFromNameAndFeatureName: FC<
  ManagedContentFromNameAndFeatureNameProps
> = ({
  name,
  featureName,
  ...props
}: ManagedContentFromNameAndFeatureNameProps): ReactElement => {
  const loading = useLoading();
  const safeAsync = useSafeAsync();
  const [managedContent, setManagedContent] =
    useState<SalesforceManagedContent>({});

  useEffect(() => {
    if (name && featureName) {
      safeAsync(
        loading(
          ManagedContentService.getManagedContentByNameAndFeatureName(
            name,
            featureName
          )
        )
      ).then((response) =>
        setManagedContent(response as SalesforceManagedContent)
      );
    }
  }, [featureName, loading, name, safeAsync]);

  return (
    <ManagedContentFromSalesforceObject
      {...props}
      {...managedContent}
      onUpdate={(newManagedContent) => {
        setManagedContent(newManagedContent);
      }}
    />
  );
};

export default ManagedContentFromNameAndFeatureName;
