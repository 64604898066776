import _ from "lodash";
import {
  ChangeEvent,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormContext } from "../../../../models/FormContext";
import {
  DEFAULT_OTHER_PLACEHOLDER,
  FormOtherProps,
} from "../../models/FormOtherProps";
import { buildOnChangeHandler } from "../../utils/FormInputUtil";
import FormControl from "../FormControl";
import FormSelect, { FormSelectProps } from "../FormSelect";

export type FormSelectOtherProps = FormSelectProps & FormOtherProps;

const FormSelectOther: FC<FormSelectOtherProps> = ({
  otherName,
  otherValue,
  otherPlaceholder,
  onChange,
  onAfterChange,
  ...props
}: FormSelectOtherProps): ReactElement => {
  const { id, name, disabled } = props;
  const { initialRequest, setRequest } = useContext(FormContext);
  const [showOther, setShowOther] = useState<boolean>(false);

  const onChangeHandler = useMemo(() => {
    return buildOnChangeHandler(onChange, setRequest, name, onAfterChange);
  }, [name, onAfterChange, onChange, setRequest]);

  const handleSelectOnChange = useMemo(() => {
    return (event: ChangeEvent<HTMLSelectElement>) => {
      const {
        target: { value: newValue },
      } = event;
      onChangeHandler(event);
      setShowOther(newValue === otherValue);
    };
  }, [onChangeHandler, otherValue]);

  useEffect(() => {
    setShowOther(_.get(initialRequest, name) === otherValue);
  }, [initialRequest, name, otherValue]);

  return (
    <>
      <FormSelect {...props} onChange={handleSelectOnChange} />
      {showOther && (
        <FormControl
          id={`${id}-other`}
          name={otherName}
          type="text"
          placeholder={otherPlaceholder || DEFAULT_OTHER_PLACEHOLDER}
          className="mt-2"
          disabled={disabled}
        />
      )}
    </>
  );
};

export default FormSelectOther;
