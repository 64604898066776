import React, { FC, ReactElement } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { UseGlobalFiltersInstanceProps, useAsyncDebounce } from "react-table";

export type GlobalFilterProps = Partial<
  UseGlobalFiltersInstanceProps<Record<string, unknown>> & {
    className: string;
    globalFilter: string;
  }
>;

const GlobalFilter: FC<GlobalFilterProps> = ({
  className,
  globalFilter,
  preGlobalFilteredRows = [],
  setGlobalFilter = () => {},
}: GlobalFilterProps): ReactElement => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((newValue: string) => {
    setGlobalFilter(newValue || undefined);
  }, 200);

  return (
    <InputGroup className={className} data-testid="globalFilter">
      <InputGroup.Text>Filter</InputGroup.Text>
      <Form.Control
        value={value ?? ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </InputGroup>
  );
};

export default GlobalFilter;
