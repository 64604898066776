import { FC, ReactElement, useContext, useMemo } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import AppPath from "../../../../components/App/models/AppPath";
import FeatureName from "../../../../components/App/models/FeatureName";
import ManagedContent from "../../../shared/src/components/ManagedContent";
import MOCPath from "../../models/MOCPath";
import ManagedContentName from "../../models/ManagedContentName";
import SubmissionsTable from "../MOCSubmissions/components/SubmissionsTable";
import { MyEmoryContext } from "../../../shared/src/models/MyEmoryContext";
import { MOC_APPROVER_ROLES } from "../../models/MOCRoles";
import { MOCStatus } from "../../models/salesforce/Case";

const MOCSubmissionsApprover: FC = (): ReactElement => {
  const { roleNames } = useContext(MyEmoryContext);
  const isApprover = useMemo(
    () =>
      roleNames?.some((role) =>
        MOC_APPROVER_ROLES.includes(role as string)
      ) as boolean,
    [roleNames]
  );
  const statuses = useMemo<MOCStatus[]>(() => [MOCStatus.NEW], []);
  const otherStatuses = useMemo<MOCStatus[]>(
    () => [MOCStatus.REJECTED, MOCStatus.APPROVED, MOCStatus.CLOSED],
    []
  );
  return (
    <Container>
      <Row>
        <Col>
          <Breadcrumb>
            <>
              {[AppPath.Home, MOCPath.MOCSubmissionsApprover].map(
                (path, index, { length }) =>
                  path.toBreadcrumbItem(index === length - 1)
              )}
            </>
          </Breadcrumb>
        </Col>
      </Row>
      <ManagedContent
        name={ManagedContentName.ProgramsInvolvingMinorsDashboardApprover}
        featureName={FeatureName.MinorsOnCampusRegistrationForm}
      />
      {isApprover && (
        <div>
          <Row>
            <Col>
              <SubmissionsTable isApprover={isApprover} statuses={statuses} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ManagedContent
                name={ManagedContentName.ProgramsInvolvingMinorsClosedNoAction}
                featureName={FeatureName.MinorsOnCampusRegistrationForm}
              />
              <SubmissionsTable
                isApprover={isApprover}
                statuses={otherStatuses}
              />
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
};

export default MOCSubmissionsApprover;
