import SalesforceResponse from "../../../models/salesforce/SalesforceResponse";
import SectionRow from "../../../models/salesforce/SectionRow";
import FormSalesforceObjectService from "../FormSalesforceObjectService";

class SectionRowService extends FormSalesforceObjectService {
  private static readonly OBJECT_NAME = "section-rows";

  /**
   * Save form section
   * @param sectionRow
   * @param depth depth of salesforce nested objects
   * @returns array of salesforce responses
   */
  public static async saveSectionRow(
    sectionRow: SectionRow,
    depth: number | undefined = undefined
  ): Promise<SalesforceResponse[]> {
    return super.save<SectionRow>(this.OBJECT_NAME, [sectionRow], depth);
  }

  /**
   * Delete section row by id
   * @param sectionRowId
   * @param depth depth of salesforce nested objects
   * @returns array of salesforce responses
   */
  public static async deleteSectionRowById(
    sectionRowId: string,
    depth: number | undefined = undefined
  ): Promise<SalesforceResponse[]> {
    return super.deleteById(this.OBJECT_NAME, [sectionRowId], depth);
  }
}

export default SectionRowService;
