import Form from "../../../models/salesforce/Form";
import SalesforceResponse from "../../../models/salesforce/SalesforceResponse";
import FormSalesforceObjectService from "../FormSalesforceObjectService";

class FormService extends FormSalesforceObjectService {
  private static readonly OBJECT_NAME = "forms";

  /**
   * Get all forms
   * @param depth depth of salesforce nested objects
   * @returns array of forms
   */
  public static async getAllForms(depth = 1): Promise<Form[]> {
    return super.findAll<Form>(this.OBJECT_NAME, depth);
  }

  /**
   * Save form
   * @param form
   * @param depth depth of salesforce nested objects
   * @returns array of salesforce responses
   */
  public static async saveForm(
    form: Form,
    depth = 0
  ): Promise<SalesforceResponse[]> {
    return super.save<Form>(this.OBJECT_NAME, [form], depth);
  }

  /**
   * Delete form by id
   * @param formId
   * @param depth depth of salesforce nested objects
   * @returns array of salesforce responses
   */
  public static async deleteFormById(
    formId: string,
    depth: number | undefined = undefined
  ): Promise<SalesforceResponse[]> {
    return super.deleteById(this.OBJECT_NAME, [formId], depth);
  }
}

export default FormService;
