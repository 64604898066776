import { RowData, Table } from "@tanstack/react-table";
import { ReactElement } from "react";
import TableRow from "../TableRow";
import TableRowNoRecordsFound from "../TableRowNoRecordsFound";

export type TableBodyProps<TData extends RowData> = {
  table: Table<TData>;
};

const TableBody = <T,>({ table }: TableBodyProps<T>): ReactElement => {
  return (
    <tbody className="table-body">
      <TableRowNoRecordsFound table={table} />
      {table.getRowModel().rows.map((row) => {
        return <TableRow row={row} key={row.id} />;
      })}
    </tbody>
  );
};

export default TableBody;
