import {
  faLongArrowAltDown,
  faLongArrowAltUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, ReactElement } from "react";
import { HeaderGroup } from "react-table";

export type TableHeaderSortArrowProps = Pick<
  HeaderGroup,
  "canSort" | "isSorted" | "isSortedDesc"
>;

const TableHeaderSortArrow: FC<TableHeaderSortArrowProps> = ({
  canSort,
  isSorted,
  isSortedDesc,
}: TableHeaderSortArrowProps): ReactElement => {
  if (isSorted) {
    if (isSortedDesc) {
      return <FontAwesomeIcon icon={faLongArrowAltDown} className="ms-1" />;
    }
    return <FontAwesomeIcon icon={faLongArrowAltUp} className="ms-1" />;
  }
  if (canSort) {
    return (
      <span className="ms-1 text-nowrap">
        <FontAwesomeIcon icon={faLongArrowAltUp} />
        <FontAwesomeIcon icon={faLongArrowAltDown} />
      </span>
    );
  }
  return <></>;
};

export default TableHeaderSortArrow;
