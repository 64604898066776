import { CacheAxiosResponse } from "axios-cache-interceptor";
import _ from "lodash";
import {
  ChangeEvent,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Form } from "react-bootstrap";
import { FormCheckType } from "react-bootstrap/esm/FormCheck";
import { v4 as uuid } from "uuid";
import { useSafeAsync } from "../../../../../../hooks";
import { BaseService } from "../../../../../../services";
import { FormContext } from "../../../../models/FormContext";
import FormLabel from "../../../FormLabel";
import FormOption from "../../models/FormOption";
import { OtherFormMultiSelectProps } from "../../models/FormOtherProps";
import { formatOptionsUrl } from "../../utils/FormInputUtil";
import { FormCheckListProps } from "../FormCheckList";
import FormCheck, { FormCheckProps } from "../FormCheckList/FormCheck";
import FormValidation from "../FormValidation";
import OtherFormMultiSelect from "../OtherFormMultiSelect";

export type FormCheckListOtherMultiSelectProps = FormCheckListProps & {
  otherProps: string;
};

const FormCheckListOtherMultiSelect: FC<FormCheckListOtherMultiSelectProps> = ({
  id,
  name,
  label,
  type: typeProp,
  required,
  showRequired,
  onChange,
  options,
  optionsEndpoint,
  inline,
  inlineLabel,
  className,
  disabled,
  invalid,
  validationMessage,
  infoProps,
  requiredMessage,
  onAfterChange,
  formCheckProps: formCheckPropsString,
  otherProps,
}: FormCheckListOtherMultiSelectProps): ReactElement => {
  const safeAsync = useSafeAsync();
  const [value, setValue] = useState<string | string[]>();
  const { initialRequest, setRequest } = useContext(FormContext);
  const [stateOptions, setStateOptions] = useState<FormCheckProps[]>(
    options || []
  );
  const [otherFormMultiSelectValues, setOtherFormMultiSelectValues] = useState<
    _.Dictionary<FormOption | FormOption[]>
  >({});

  const type = useMemo(() => {
    if (typeProp === "checklist" || typeProp === "checklistothermultiselect") {
      return "checkbox";
    }
    return typeProp;
  }, [typeProp]);

  const otherFormMultiSelects = useMemo<
    _.Dictionary<OtherFormMultiSelectProps>
  >(() => {
    if (otherProps) {
      return _.fromPairs(
        (JSON.parse(otherProps) as OtherFormMultiSelectProps[]).map(
          (formMultiSelectProps) => {
            return [
              formMultiSelectProps.targetValue,
              {
                ...formMultiSelectProps,
                id: formMultiSelectProps.id || uuid(),
              },
            ];
          }
        )
      );
    }
    return {};
  }, [otherProps]);

  // TODO: update usage from initialRequest to actual request
  const formattedOptionsEndpoint = useMemo(() => {
    if (optionsEndpoint) {
      return formatOptionsUrl(optionsEndpoint, initialRequest);
    }
    return optionsEndpoint;
  }, [optionsEndpoint, initialRequest]);

  useEffect(() => {
    if (formattedOptionsEndpoint) {
      safeAsync(
        BaseService.get<FormCheckProps[]>({ url: formattedOptionsEndpoint })
      )
        .then((response) => response as CacheAxiosResponse<FormCheckProps[]>)
        .then((response) => response.data || [])
        .then(setStateOptions);
    }
  }, [formattedOptionsEndpoint, safeAsync]);

  const isInline = useCallback(() => {
    return inline || stateOptions.every((x) => x?.inline);
  }, [inline, stateOptions]);

  const onChangeHandler = useMemo(() => {
    return (
      event: ChangeEvent<HTMLInputElement>,
      newValue?: string | string[]
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (onChange !== undefined) {
        onChange(event);
      } else if (setRequest !== undefined) {
        setRequest((request) => {
          return _.setWith(_.clone(request), name, newValue, _.clone);
        });
      }
      if (onAfterChange) {
        onAfterChange(event);
      }
    };
  }, [name, onAfterChange, onChange, setRequest]);

  const handleOnChange = useMemo(() => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value: optionValue, checked },
      } = event;
      setValue((previousValue) => {
        let newValue: string | string[] | undefined;
        if (type === "checkbox") {
          if (checked && !_.isArray(previousValue)) {
            newValue = [optionValue];
          } else if (_.isArray(previousValue)) {
            if (checked) {
              newValue = _.uniq([...previousValue, optionValue]);
            } else {
              newValue = previousValue.filter((x) => x !== optionValue);
            }
          } else {
            newValue = previousValue;
          }
        } else {
          newValue = optionValue;
        }
        onChangeHandler(event, newValue);
        return newValue;
      });
    };
  }, [onChangeHandler, type]);

  const handleOnChangeOther = useCallback(
    (otherName: string, selectedOptions: FormOption | FormOption[]) => {
      setRequest((previousRequest) => {
        return {
          ...previousRequest,
          [otherName]: selectedOptions,
        };
      });
      setOtherFormMultiSelectValues((previousOtherFormMultiSelectValues) => {
        return {
          ...previousOtherFormMultiSelectValues,
          [otherName]: selectedOptions,
        };
      });
    },
    [setRequest]
  );

  useEffect(() => {
    setValue(_.get(initialRequest, name) as string | string[]);
  }, [initialRequest, name]);

  const formCheckProps = useMemo(() => {
    if (formCheckPropsString) {
      return JSON.parse(formCheckPropsString) as Record<string, unknown>;
    }
    return {};
  }, [formCheckPropsString]);

  const formChecks = useMemo(
    () => (
      <>
        {stateOptions.map((optionProps, index) => {
          const checked =
            type === "checkbox"
              ? (value || []).includes(optionProps.value)
              : value === optionProps.value;
          const otherFormMultiSelectProps =
            otherFormMultiSelects[optionProps.value];
          return (
            <div key={uuid()}>
              <FormCheck
                {...optionProps}
                {...formCheckProps}
                inline={isInline()}
                type={type as FormCheckType}
                name={name}
                required={index === 0 ? required : false}
                checked={checked}
                onChange={handleOnChange}
              />
              {checked && otherFormMultiSelectProps && (
                <div className="ms-4 mb-3">
                  <OtherFormMultiSelect
                    {...otherFormMultiSelectProps}
                    value={
                      otherFormMultiSelectValues[otherFormMultiSelectProps.name]
                    }
                    onChange={(selectedOptions) =>
                      handleOnChangeOther(
                        otherFormMultiSelectProps.name,
                        selectedOptions
                      )
                    }
                  />
                </div>
              )}
            </div>
          );
        })}
      </>
    ),
    [
      formCheckProps,
      handleOnChange,
      handleOnChangeOther,
      isInline,
      name,
      otherFormMultiSelectValues,
      otherFormMultiSelects,
      required,
      stateOptions,
      type,
      value,
    ]
  );

  return (
    <fieldset disabled={disabled}>
      <Form.Group controlId={id} className={className}>
        {label && (
          <FormLabel
            label={label}
            required={required}
            showRequired={showRequired}
            inline={inlineLabel && isInline()}
            infoProps={infoProps}
            requiredMessage={requiredMessage}
          />
        )}
        <div className={invalid ? "form-invalid-input" : ""}>
          {inlineLabel ? formChecks : <div>{formChecks}</div>}
        </div>
        {validationMessage && <FormValidation message={validationMessage} />}
      </Form.Group>
    </fieldset>
  );
};

export default FormCheckListOtherMultiSelect;
