enum ManagedContentName {
  ProgramsInvolvingMinorsSubmissions = "ProgramsInvolvingMinorsSubmissions",
  ProgramsInvolvingMinorsDashboard = "ProgramsInvolvingMinorsDashboard",
  ProgramsInvolvingMinorsDashboardApprover = "ProgramsInvolvingMinorsDashboardApprover",
  ProgramsInvolvingMinorsAuthorizedAdults = "ProgramsInvolvingMinorsAuthorizedAdults",
  ProgramsInvolvingMinorsAuthorizedAdultsExternal = "ProgramsInvolvingMinorsAuthorizedAdultsExternal",
  ProgramsInvolvingMinorsSubmitButton = "ProgramsInvolvingMinorsSubmitButton",
  ProgramsInvolvingMinorsSaveButton = "ProgramsInvolvingMinorsSaveButton",
  AddAuthorizedAdultButton = "AddAuthorizedAdultButton",
  EmoryAuthorizedAdultDescription = "EmoryAuthorizedAdultDescription",
  ExternalAuthorizedAdultDescription = "ExternalAuthorizedAdultDescription",
  ProgramsInvolvingMinorsLabelRegistration = "ProgramsInvolvingMinorsLabelRegistration",
  ProgramsInvolvingMinorsLabelForm = "ProgramsInvolvingMinorsLabelForm",
  ProgramsInvolvingMinorsLabelApprovers = "ProgramsInvolvingMinorsLabelApprovers",
  ProgramsInvolvingMinorsClosedNoAction = "ProgramsInvolvingMinorsClosedNoAction",
  ProgramsInvolvingMinorsBackButton = "ProgramsInvolvingMinorsBackButton",
  ProgramsInvolvingMinorsSaveAuthAdultsButton = "ProgramsInvolvingMinorsSaveAuthAdultsButton",
}

export default ManagedContentName;
