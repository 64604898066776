import AppHeaderLink, {
  AppHeaderLinkType,
} from "../../shared/src/components/AppHeader/AppHeaderLink";
import { AppRouteProps } from "../../shared/src/models";
import EUSHIP from "../pages/EUSHIP";
import EUSHIPPath from "./EUSHIPPath";

export const EUSHIPAppHeaderLinks: AppHeaderLink[] = [
  {
    ...EUSHIPPath.EUSHIP,
    type: AppHeaderLinkType.LINK,
  },
];

export const EUSHIPAppRoutes: AppRouteProps[] = [
  {
    ...EUSHIPPath.EUSHIP,
    exact: false,
    render: () => <EUSHIP />,
  },
];
