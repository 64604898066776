import { SpeedtypeResponse } from "../models/api/SpeedtypeResponse";
import { BaseService } from "../../shared/src/services";

class SpeedtypeService extends BaseService {
  private static readonly BASE_PATH = "/api/aae/v1/speedtypes";

  public static async getSpeedtypeById(id: string): Promise<SpeedtypeResponse> {
    const response = await this.get<SpeedtypeResponse>({
      url: `${this.BASE_PATH}/${id}`,
    });
    return response.data as SpeedtypeResponse;
  }
}

export default SpeedtypeService;
