import moment from "moment";

const NOW = "now";

const isValidOperator = (operator: string) => ["+", "-"].includes(operator);

const isValidUnit = (unit: string) => ["y", "M", "d"].includes(unit);

type ValidUnit = "y" | "M" | "d";

export const parseDate = (initialDateString: string): Date | undefined => {
  try {
    if (initialDateString.length === 0) {
      return undefined;
    }
    let dateString = initialDateString.trim();
    if (!dateString.startsWith(NOW)) {
      return moment(dateString).toDate();
    }
    dateString = initialDateString.substring(NOW.length).trim();
    if (dateString.length === 0) {
      return moment().toDate();
    }
    const operator = dateString.substring(0, 1);
    const deltaUnit = dateString.substring(dateString.length - 1);
    const deltaAmount = dateString.substring(1, dateString.length - 1);
    if (isValidOperator(operator) && isValidUnit(deltaUnit)) {
      return moment()
        .add(deltaAmount, deltaUnit as ValidUnit)
        .toDate();
    }
  } catch {
    // do nothing
  }
  return undefined;
};

export default { parseDate };
