import { FC, ReactElement, useMemo } from "react";
import {
  FormCheckProps as BootstrapFormCheckProps,
  Form,
} from "react-bootstrap";
import { ComponentProps } from "../../../../../../models";
import { renderContent } from "../../../../../Component/ComponentUtil";

export interface FormCheckProps extends BootstrapFormCheckProps {
  id: string;
  name: string;
  value: string;
  required?: boolean;
  optionsEndpoint?: string;
}

const FormCheck: FC<FormCheckProps> = ({
  id,
  name,
  required,
  onChange,
  label,
  optionsEndpoint,
  ...optionProps
}: FormCheckProps): ReactElement => {
  const labelContent = useMemo(() => {
    if (
      typeof label === "object" &&
      !((label as unknown as ComponentProps)?.props as Record<string, unknown>)
        ?.id
    ) {
      return <>{(label as unknown as ComponentProps)?.content as string}</>;
    }

    return renderContent(
      label as unknown as string | ComponentProps | ComponentProps[]
    );
  }, [label]);

  return (
    <Form.Check
      {...optionProps}
      id={id}
      name={name}
      required={required}
      label={labelContent}
      onChange={onChange}
    />
  );
};

FormCheck.defaultProps = {
  required: false,
  optionsEndpoint: undefined,
};

export default FormCheck;
