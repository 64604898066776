import React, { FC, ReactElement } from "react";
import "./styles.scss";

const Footer: FC = (): ReactElement => {
  return (
    <footer id="aae-footer">
      <div>
        <ul id="buttons">
          <li>
            <a href="https://together.emory.edu/give">Give Now</a>
          </li>
          <li>
            <a href="https://www.engage.emory.edu/s/1705/21/interior.aspx?sid=1705&gid=3&pgid=15630">
              Volunteer
            </a>
          </li>
        </ul>
        <ul id="links">
          <li>
            <a href="https://www.emory.edu/home/help/compliance-boilerplate.html">
              Copyright & Compliance
            </a>
          </li>
          <li>
            <a href="https://equityandcompliance.emory.edu/equity-inclusion/affirmative-action/eoaa-statements.html">
              EO/AA Statement
            </a>
          </li>
          <li>
            <a href="https://together.emory.edu/about-us/privacy-and-compliance">
              Donor Privacy Policy
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
