import { createContext } from "react";
import { RequestStateProps } from "../../../components/Form/models/FormRequest";
import { ComponentProps } from "../../../models";

export type FormEditorContextProps = RequestStateProps & {
  formId: string;
  formSectionId?: string;
  form?: ComponentProps;
  refreshForm: () => void;
  formSection?: ComponentProps;
};

export const FormEditorContext = createContext<FormEditorContextProps>({
  formId: "",
  refreshForm: () => {},
  initialRequest: {},
  setRequest: () => {},
});
