import SalesforceObject from "../../../shared/src/models/salesforce/SalesforceObject";
import type Case from "./Case";
import type { AuthorizedAdultFormRequest } from "../AuthorizedAdultFormRequest";
import MyEmoryContact, {
  toFormOption,
} from "../../../../models/salesforce/MyEmoryContact";

class AuthorizedAdult implements SalesforceObject {
  public readonly id?: string;

  public readonly name?: string;

  public readonly contactId?: string;

  public readonly contact?: MyEmoryContact;

  public readonly externalContactEmail?: string;

  public readonly externalContactName?: string;

  public readonly externalContactPhone?: string;

  public readonly isExternalContact?: boolean;

  public readonly mocRequestId?: string;

  public readonly mocRequest?: Case;

  constructor({
    id,
    name,
    contact,
    externalContactEmail,
    externalContactName,
    externalContactPhone,
    isExternalContact,
    mocRequest,
  }: AuthorizedAdultFormRequest) {
    this.id = id;
    this.name = name;
    this.contactId = contact?.value?.id ?? contact?.id;
    this.contact = contact?.value ?? contact;
    this.externalContactEmail = externalContactEmail;
    this.externalContactName = externalContactName;
    this.externalContactPhone = externalContactPhone;
    this.isExternalContact = isExternalContact || false;
    this.mocRequestId = mocRequest?.id;
    this.mocRequest = mocRequest;
  }

  public static toAuthorizedAdultFormRequest({
    id,
    name,
    contact,
    externalContactEmail,
    externalContactName,
    externalContactPhone,
    isExternalContact,
    mocRequest,
  }: AuthorizedAdult): AuthorizedAdultFormRequest {
    return {
      id,
      name,
      contact: toFormOption(contact),
      contactId: contact?.id,
      externalContactEmail,
      externalContactName,
      externalContactPhone,
      isExternalContact,
      mocRequest,
      mocRequestId: mocRequest?.id,
      uid: id,
    } as AuthorizedAdultFormRequest;
  }
}

export enum AuthorizedAdultField {
  CONTACT_ID = "MOC_Contact__c",
  CONTACT = "MOC_Contact__r",
  EXTERNAL_CONTACT_EMAIL = "MOC_Authorized_Adult_ExternlContactEmail__c",
  EXTERNAL_CONTACT_NAME = "MOC_Authorized_Adult_ExternalContactName__c",
  EXTERNAL_CONTACT_PHONE = "MOC_Authorized_Adult_ExternalContactPhone__c",
  IS_EXTERNAL_CONTACT = "MOC_Authorized_Adult_is_External_Contact__c",
  MOC_REQUEST_ID = "MOC_Request__c",
  MOC_REQUEST = "MOC_Request__r",
}

export default AuthorizedAdult;
