class FormElementType {
  private static ALL_VALUES: FormElementType[] = [];

  static readonly CUSTOM_COMPONENT = new FormElementType(
    "FormCustomComponent",
    "Custom Component"
  );

  static readonly FORM_INPUT = new FormElementType("FormInput", "Form Input");

  static readonly MANAGED_CONTENT = new FormElementType(
    "ManagedContent",
    "Managed Content"
  );

  constructor(public readonly value: string, public readonly label: string) {
    FormElementType.ALL_VALUES.push(this);
  }

  static values(): FormElementType[] {
    return FormElementType.ALL_VALUES;
  }
}

export default FormElementType;
