import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { ComponentProps } from "../../../shared/src/models";
import { Form } from "../../../shared/src/components";
import useFormByFeatureName from "../../../shared/src/hooks/useFormByFeatureName";
import { MOCFormRequest } from "../../models/MOCFormRequest";
import useLoading from "../../../shared/src/hooks/useLoading";
import MOCRequestService from "../../services/MOCRequestService";
import Case, { MOCStatus } from "../../models/salesforce/Case";
import {
  getRequestByKey,
  setRequestByKey,
} from "../../../shared/src/components/Form";
import AuthorizedAdultTable from "./components/AuthorizedAdultTable";
import type { AuthorizedAdultFormRequest } from "../../models/AuthorizedAdultFormRequest";
import AuthorizedAdult from "../../models/salesforce/AuthorizedAdult";
import { ErrorResponse } from "../../../shared/src/models/ErrorResponse";
import MOCPath from "../../models/MOCPath";
import "./styles.scss";
import ManagedContent from "../../../shared/src/components/ManagedContent";
import ManagedContentName from "../../models/ManagedContentName";
import FeatureName from "../../../../components/App/models/FeatureName";
import { MOCFormContext } from "./models/MOCFormContext";
import AppPath from "../../../../components/App/models/AppPath";
import useCurrentUser from "../../../../hooks/useCurrentUser";

const MOC: FC = (): ReactElement => {
  const { form } = useFormByFeatureName(
    "Minors on Campus Program Registration Form"
  );
  const [formContent, setFormContent] = useState<ComponentProps>();
  const [initialRequest, setInitialRequest] = useState<MOCFormRequest>({});
  const [authorizedAdults, setAuthorizedAdults] = useState<
    AuthorizedAdultFormRequest[]
  >([]);
  const [externalAuthorizedAdults, setExternalAuthorizedAdults] = useState<
    AuthorizedAdultFormRequest[]
  >([]);
  const pathParams = useParams<{ id: string }>();
  const mocRequestId = useMemo(() => pathParams?.id ?? "", [pathParams]);
  const requestKey = useMemo(() => {
    return mocRequestId !== "" ? `mocRequest-${mocRequestId}` : "mocRequest";
  }, [mocRequestId]);
  const loading = useLoading();
  const history = useHistory();
  const currentUser = useCurrentUser();
  const readonly = useMemo(() => {
    if (initialRequest.status) {
      if (initialRequest.status === MOCStatus.CLOSED) {
        return true;
      }
      if (initialRequest.programSponsorId === currentUser.id) {
        return false;
      }
      return true;
    }
    return false;
  }, [initialRequest, currentUser]);
  const authAdultReadonly = useMemo(() => {
    if (!initialRequest.status) {
      return false;
    }
    return initialRequest.programSponsorId !== currentUser.id;
  }, [initialRequest, currentUser]);

  const refreshRequest = useCallback(async () => {
    return loading(MOCRequestService.getMocCaseById(mocRequestId, false)).then(
      async (response) => {
        const formRequest = await MOCRequestService.toMOCFormRequest(
          response as Case
        );
        setInitialRequest((previousInitialRequest) => {
          return { ...previousInitialRequest, ...formRequest };
        });
        const emoryAuthorizedAdults = formRequest.authorizedAdults?.filter(
          (authorizedAdult) => !authorizedAdult.isExternalContact
        ) as AuthorizedAdultFormRequest[];
        const extAuthorizedAdults = formRequest.authorizedAdults?.filter(
          (authorizedAdult) => authorizedAdult.isExternalContact
        ) as AuthorizedAdultFormRequest[];
        setAuthorizedAdults(emoryAuthorizedAdults ?? []);
        setExternalAuthorizedAdults(extAuthorizedAdults ?? []);
        setRequestByKey(requestKey, formRequest);
      }
    );
  }, [loading, mocRequestId, requestKey]);

  useEffect(() => {
    if (mocRequestId) {
      refreshRequest();
    }
  }, [mocRequestId, refreshRequest]);

  const handleSave = useCallback(async () => {
    return loading(async () => {
      try {
        const formRequest = getRequestByKey(requestKey);
        setInitialRequest(formRequest);
        const request = new Case(formRequest);
        request.authorizedAdults = authorizedAdults
          .map((authorizedAdult) => new AuthorizedAdult(authorizedAdult))
          .concat(
            externalAuthorizedAdults.map(
              (authorizedAdult) => new AuthorizedAdult(authorizedAdult)
            )
          );
        const response = await MOCRequestService.validateAndSave(
          request,
          "Update Request"
        );
        if (response.every((res) => res.success)) {
          toast.success("Form saved successfully");
          if (mocRequestId !== "") {
            history.push(MOCPath.MOCSubmissions.path);
          } else {
            history.push(MOCPath.MOC.generatePath({ id: response[0].id }));
          }
        }
        response.forEach((res) => {
          res.errors?.forEach((error) => {
            toast.error(`Failed to save form: ${error.message}`);
          });
        });
      } catch (error) {
        const axiosError = error as AxiosError;
        const errorResponse = axiosError.response?.data as ErrorResponse;
        const errors = errorResponse?.errors;
        if (errors && Array.isArray(errors)) {
          errors.forEach((err) => {
            toast.error(
              `Failed to save form: ${err.field ? `${err.field} - ` : ""}${
                err.defaultMessage
              }`
            );
          });
        }
      }
    });
  }, [
    loading,
    requestKey,
    authorizedAdults,
    externalAuthorizedAdults,
    history,
    mocRequestId,
  ]);

  useEffect(() => {
    setFormContent(form);
  }, [form]);

  useEffect(() => {
    loading(async () => {
      localStorage.removeItem(requestKey);
    }).catch(() =>
      toast.error(`An error occurred while clearing cached ${requestKey}"`)
    );
  }, [loading, requestKey]);

  const mocFormContext = useMemo(
    () => ({
      initialRequest,
      setInitialRequest,
      requestKey,
      authorizedAdults,
      externalAuthorizedAdults,
      mocRequestId,
    }),
    [
      initialRequest,
      setInitialRequest,
      requestKey,
      authorizedAdults,
      externalAuthorizedAdults,
      mocRequestId,
    ]
  );

  return (
    <MOCFormContext.Provider value={mocFormContext}>
      <Container className="mb-5">
        <Row>
          <Col>
            <Breadcrumb>
              <>
                {[AppPath.Home, MOCPath.MOCSubmissions, MOCPath.MOC].map(
                  (path, index, { length }) =>
                    path.toBreadcrumbItem(index === length - 1)
                )}
              </>
            </Breadcrumb>
          </Col>
        </Row>
        <ManagedContent
          name={ManagedContentName.ProgramsInvolvingMinorsDashboard}
          featureName={FeatureName.MinorsOnCampusRegistrationForm}
        />
        <Row>
          <Col>
            <Form
              content={formContent}
              initialRequest={initialRequest}
              requestKey={requestKey}
              disabled={readonly}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <AuthorizedAdultTable
              data={authorizedAdults}
              setData={setAuthorizedAdults}
              readonly={authAdultReadonly}
              isExternal={false}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <AuthorizedAdultTable
              data={externalAuthorizedAdults}
              setData={setExternalAuthorizedAdults}
              readonly={authAdultReadonly}
              isExternal
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-center align-items-center">
          {!readonly && (
            <Button variant="primary" onClick={handleSave}>
              <ManagedContent
                name={
                  initialRequest.status
                    ? ManagedContentName.ProgramsInvolvingMinorsSaveButton
                    : ManagedContentName.ProgramsInvolvingMinorsSubmitButton
                }
                featureName={FeatureName.MinorsOnCampusRegistrationForm}
              />
            </Button>
          )}
          {readonly && (
            <Button
              variant="light"
              onClick={() => history.push(MOCPath.MOCSubmissions.path)}
            >
              <ManagedContent
                name={ManagedContentName.ProgramsInvolvingMinorsBackButton}
                featureName={FeatureName.MinorsOnCampusRegistrationForm}
              />
            </Button>
          )}
          {readonly && !authAdultReadonly && (
            <Button variant="primary" onClick={handleSave} className="ms-2">
              <ManagedContent
                name={
                  ManagedContentName.ProgramsInvolvingMinorsSaveAuthAdultsButton
                }
                featureName={FeatureName.MinorsOnCampusRegistrationForm}
              />
            </Button>
          )}
        </div>
      </Container>
    </MOCFormContext.Provider>
  );
};

export default MOC;
