import { FC, ReactElement, useCallback, useContext } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import ManagedContent from "../../../../../shared/src/components/ManagedContent";
import ManagedContentName from "../../../../models/ManagedContentName";
import FeatureName from "../../../../../../components/App/models/FeatureName";
import { MOCFormContext } from "../../models/MOCFormContext";
import useLoading from "../../../../../shared/src/hooks/useLoading";
import { getRequestByKey } from "../../../../../shared/src/components/Form";
import Case from "../../../../models/salesforce/Case";
import AuthorizedAdult from "../../../../models/salesforce/AuthorizedAdult";
import MOCRequestService from "../../../../services/MOCRequestService";
import MOCPath from "../../../../models/MOCPath";
import { ErrorResponse } from "../../../../../shared/src/models/ErrorResponse";

const SubmissionsButton: FC = (): ReactElement => {
  const {
    initialRequest,
    setInitialRequest,
    requestKey,
    authorizedAdults,
    externalAuthorizedAdults,
    mocRequestId,
  } = useContext(MOCFormContext);
  const history = useHistory();
  const loading = useLoading();
  const handleSave = useCallback(async () => {
    return loading(async () => {
      try {
        const formRequest = getRequestByKey(requestKey);
        setInitialRequest(formRequest);
        const request = new Case(formRequest);
        request.authorizedAdults = authorizedAdults
          .map((authorizedAdult) => new AuthorizedAdult(authorizedAdult))
          .concat(
            externalAuthorizedAdults.map(
              (authorizedAdult) => new AuthorizedAdult(authorizedAdult)
            )
          );
        const response = await MOCRequestService.validateAndSave(
          request,
          "Update Request"
        );
        if (response.every((res) => res.success)) {
          toast.success("Form saved successfully");
          if (mocRequestId !== "") {
            history.push(MOCPath.MOCSubmissions.path);
          } else {
            history.push(MOCPath.MOC.generatePath({ id: response[0].id }));
          }
        }
        response.forEach((res) => {
          res.errors?.forEach((error) => {
            toast.error(`Failed to save form: ${error.message}`);
          });
        });
      } catch (error) {
        const axiosError = error as AxiosError;
        const errorResponse = axiosError.response?.data as ErrorResponse;
        const errors = errorResponse?.errors;
        if (errors && Array.isArray(errors)) {
          errors.forEach((err) => {
            toast.error(
              `Failed to save form: ${err.field ? `${err.field} - ` : ""}${
                err.defaultMessage
              }`
            );
          });
        }
      }
    });
  }, [
    setInitialRequest,
    loading,
    requestKey,
    authorizedAdults,
    externalAuthorizedAdults,
    history,
    mocRequestId,
  ]);
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center">
        <Button variant="primary" onClick={handleSave} disabled={false}>
          <ManagedContent
            name={
              initialRequest.status
                ? ManagedContentName.ProgramsInvolvingMinorsSaveButton
                : ManagedContentName.ProgramsInvolvingMinorsSubmitButton
            }
            featureName={FeatureName.MinorsOnCampusRegistrationForm}
          />
        </Button>
      </div>
      <hr />
    </div>
  );
};

export default SubmissionsButton;
