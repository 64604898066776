import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Col, Container, Modal, Navbar, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { v4 as uuid } from "uuid";
import useFormByFeatureName from "../../../shared/src/hooks/useFormByFeatureName";
import ComponentProps from "../../../shared/src/models/ComponentProps";
import { Form } from "../../../shared/src/components";
import { EUSHIPFormRequest } from "../../models/EUSHIPFormRequest";
import useLoading from "../../../shared/src/hooks/useLoading";
import {
  getRequestByKey,
  setRequestByKey,
} from "../../../shared/src/components/Form";
import EUSHIPService from "../../services/EUSHIPService";
import { ErrorResponse } from "../../../shared/src/models/ErrorResponse";
import { mapEUSHIPFormRequestToEUSHIPCase } from "../../../moc/models/salesforce/Case";
import "./styles.scss";
import AppPath from "../../../../components/App/models/AppPath";
import { getModifiedFieldNames } from "../../../shared/src/utils/ObjectsUtil";
import useCurrentUser from "../../../../hooks/useCurrentUser";

const EUSHIP: FC = (): ReactElement => {
  const { form } = useFormByFeatureName("EUSHIP");
  const [formContent, setFormContent] = useState<ComponentProps>();
  const [initialRequest, setInitialRequest] = useState<EUSHIPFormRequest>({});
  const [showModal, setShowModal] = useState(false);
  const loading = useLoading();
  const history = useHistory();
  const currentUser = useCurrentUser();
  const requestKey = useMemo(() => {
    return `eushipRequest-${uuid()}`;
  }, []);

  useEffect(() => {
    setFormContent(form);
  }, [form]);

  const handleSave = useCallback(async () => {
    return loading(async () => {
      try {
        const formRequest = getRequestByKey(requestKey);
        setInitialRequest(formRequest);
        const request = mapEUSHIPFormRequestToEUSHIPCase(formRequest);
        const response = await EUSHIPService.validateAndSave(
          request,
          "Update Request"
        );
        if (response.every((res) => res.success)) {
          toast.success("Form saved successfully");
          history.push(AppPath.Home.generatePath());
        }
        response.forEach((res) => {
          res.errors?.forEach((error) => {
            toast.error(`Failed to save form: ${error.message}`);
          });
        });
      } catch (error) {
        const axiosError = error as AxiosError;
        const errorResponse = axiosError.response?.data as ErrorResponse;
        const errors = errorResponse?.errors;
        if (errors && Array.isArray(errors)) {
          errors.forEach((err) => {
            toast.error(
              `Failed to save form: ${err.field ? `${err.field} - ` : ""}${
                err.defaultMessage
              }`
            );
          });
        }
      }
    });
  }, [loading, requestKey, history]);

  const autofillRequest = useCallback(
    (updatedRequest) => {
      const returnRequest = {
        ...updatedRequest,
        ...(updatedRequest.clientType === "Student" ||
        currentUser.contactType === "Student"
          ? {
              studentFirstName: currentUser.firstName,
              studentLastName: currentUser.lastName,
              studentEmail: currentUser.email,
              netid: currentUser.netId,
              studentId: currentUser.studentId,
            }
          : {
              requestorFirstName: currentUser.firstName,
              requestorLastName: currentUser.lastName,
              requestorEmail: currentUser.email,
              netid: currentUser.netId,
            }),
      };
      return returnRequest;
    },
    [
      currentUser.email,
      currentUser.firstName,
      currentUser.lastName,
      currentUser.netId,
      currentUser.studentId,
      currentUser.contactType,
    ]
  );

  const handleSetRequestByKey = useCallback(
    (previousRequest, updatedRequest) => {
      const modifiedFieldNames = getModifiedFieldNames(
        previousRequest,
        updatedRequest
      );
      let newRequest = updatedRequest;

      if (modifiedFieldNames.includes("clientType")) {
        const isClientEmoryPerson = ["Student", "Staff"].includes(
          updatedRequest.clientType
        );
        if (!currentUser.id && isClientEmoryPerson) {
          setShowModal(true);
        }

        if (isClientEmoryPerson) {
          newRequest = {
            ...autofillRequest(updatedRequest),
          };
        }
      }
      if (newRequest !== updatedRequest) {
        setInitialRequest(newRequest);
      }
      return newRequest;
    },
    [currentUser, autofillRequest]
  );

  useEffect(() => {
    if (
      currentUser.contactType &&
      ["Student", "Staff", "Faculty"].includes(currentUser.contactType)
    ) {
      let clientType = currentUser.contactType;
      if (["Staff", "Faculty"].includes(currentUser.contactType)) {
        clientType = "Staff";
      }
      setInitialRequest((prev) => {
        const updatedRequest = autofillRequest({ ...prev, clientType });
        setRequestByKey(requestKey, updatedRequest);
        return updatedRequest;
      });
    }
  }, [currentUser.contactType, autofillRequest, requestKey]);

  const handleLogIn = useCallback(() => {
    setShowModal(false);
    window.location.href = AppPath.ShibbolethLoginEuship.path;
  }, []);

  return (
    <div className="euship-inquiry-form">
      <Container className="mb-5">
        <Row>
          <Col>
            <Form
              content={formContent}
              initialRequest={initialRequest}
              requestKey={requestKey}
              disabled={false}
              onSetRequestByKey={handleSetRequestByKey}
            />
          </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header>
            <Modal.Title>Log in to auto-fill</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            If you are a Current Student or Staff at Emory University please
            login to authenticate and autofill your information.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleLogIn}>
              Log In
            </Button>
            <Button variant="danger" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
      <Navbar
        fixed="bottom"
        className="d-flex justify-content-center border-top bg-white"
      >
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
        <Button
          className="ms-2"
          variant="danger"
          onClick={() => history.push(AppPath.Home.generatePath())}
        >
          Cancel
        </Button>
      </Navbar>
    </div>
  );
};
export default EUSHIP;
