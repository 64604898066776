import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useMemo,
  FocusEvent,
} from "react";
import {
  TableOptions,
  getCoreRowModel,
  useReactTable,
  CellContext,
  createColumnHelper,
} from "@tanstack/react-table";
import { v4 as uuid } from "uuid";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Table from "../../../../../shared/src/modules/@tanslack/react-table/components/Table";
import type { AuthorizedAdultFormRequest } from "../../../../models/AuthorizedAdultFormRequest";
import FormSearchSelect from "../../../../../shared/src/components/Form/components/FormInput/components/FormSearchSelect";
import FormOption from "../../../../../shared/src/components/Form/components/FormInput/models/FormOption";
import MyEmoryContact from "../../../../../../models/salesforce/MyEmoryContact";
import ManagedContent from "../../../../../shared/src/components/ManagedContent";
import ManagedContentName from "../../../../models/ManagedContentName";
import FeatureName from "../../../../../../components/App/models/FeatureName";
import "./styles.scss";

export type AuthorizedAdultTableProps = {
  data: AuthorizedAdultFormRequest[];
  setData: Dispatch<SetStateAction<AuthorizedAdultFormRequest[]>>;
  readonly: boolean;
  isExternal: boolean;
};

const AuthorizedAdultTable = ({
  data,
  setData,
  readonly,
  isExternal,
}: AuthorizedAdultTableProps): ReactElement => {
  const addRow = useCallback(() => {
    setData((prevData) => [
      ...prevData,
      { uid: uuid(), isExternalContact: isExternal },
    ]);
  }, [setData, isExternal]);

  const removeRow = useCallback(
    (targetUid: string) => {
      setData((prevData) => prevData.filter(({ uid }) => uid !== targetUid));
    },
    [setData]
  );

  const EditableTextCell = useCallback(
    ({
      column: { columnDef },
      row: {
        original: { uid: rowUid },
      },
      getValue,
    }: CellContext<AuthorizedAdultFormRequest, unknown>) => {
      const { accessorKey } = columnDef as {
        accessorKey: keyof AuthorizedAdultFormRequest;
      };
      const value = (getValue() ?? "") as string;
      return (
        <Form.Control
          type="text"
          name={accessorKey as string}
          defaultValue={value}
          disabled={readonly}
          onBlur={({
            target: { value: newValue },
          }: FocusEvent<HTMLInputElement>) => {
            setData((prevData) =>
              prevData.map((row) =>
                row.uid === rowUid ? { ...row, [accessorKey]: newValue } : row
              )
            );
          }}
        />
      );
    },
    [setData, readonly]
  );

  const DisplayTextCell = useCallback(
    ({
      column: { columnDef },
      getValue,
    }: CellContext<AuthorizedAdultFormRequest, unknown>) => {
      const { accessorKey } = columnDef as {
        accessorKey: keyof AuthorizedAdultFormRequest;
      };
      const value = (getValue() ?? "") as string;
      return (
        <Form.Control
          type="text"
          name={accessorKey as string}
          value={value}
          disabled
        />
      );
    },
    []
  );

  const EditableSearchCell = useCallback(
    ({
      column: { columnDef },
      row: {
        original: { uid: rowUid },
      },
      getValue,
    }: CellContext<AuthorizedAdultFormRequest, unknown>) => {
      const { accessorKey } = columnDef as {
        accessorKey: keyof AuthorizedAdultFormRequest;
      };
      const value = (getValue() ?? {}) as FormOption<MyEmoryContact>;
      return (
        <FormSearchSelect
          label=""
          type="searchselect"
          id="search-select"
          name={accessorKey as string}
          options={[]}
          optionsEndpoint="/api/myemory/v0/contacts/options"
          asyncMinSearchLength={3}
          defaultValue={value as FormOption<string>}
          isDisabled={readonly}
          onChange={(selectedOption) => {
            const newValue = Array.isArray(selectedOption)
              ? selectedOption[0].value
              : selectedOption.value;
            setData((prevData) =>
              prevData.map((row) =>
                row.uid === rowUid ? { ...row, [accessorKey]: newValue } : row
              )
            );
          }}
        />
      );
    },
    [setData, readonly]
  );

  const ActionCell = useCallback(
    ({
      row: {
        original: { uid },
      },
    }: CellContext<AuthorizedAdultFormRequest, unknown>) => {
      return (
        !readonly && (
          <Button variant="danger" onClick={() => removeRow(uid as string)}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        )
      );
    },
    [removeRow, readonly]
  );

  const columnHelper = createColumnHelper<AuthorizedAdultFormRequest>();
  const columns = useMemo<
    TableOptions<AuthorizedAdultFormRequest>["columns"]
  >(() => {
    const newColumns = [];
    if (isExternal) {
      newColumns.push(
        columnHelper.accessor("externalContactName", {
          header: "External Contact Name",
          cell: EditableTextCell,
        }),
        columnHelper.accessor("externalContactEmail", {
          header: "External Contact Email",
          cell: EditableTextCell,
        }),
        columnHelper.accessor("externalContactPhone", {
          header: "External Contact Phone",
          cell: EditableTextCell,
        })
      );
    } else {
      newColumns.push(
        columnHelper.accessor("contact", {
          header: "Contact",
          cell: EditableSearchCell,
        }),
        columnHelper.accessor(
          (row) => row.contact?.value?.email ?? row.contact?.email,
          {
            header: "Email",
            cell: DisplayTextCell,
          }
        ),
        columnHelper.accessor(
          (row) => row.contact?.value?.officePhone ?? row.contact?.officePhone,
          {
            header: "Phone",
            cell: DisplayTextCell,
          }
        )
      );
    }
    newColumns.push(
      columnHelper.display({
        id: "action",
        header: "Action",
        cell: ActionCell,
      })
    );
    return newColumns;
  }, [
    ActionCell,
    EditableTextCell,
    EditableSearchCell,
    DisplayTextCell,
    columnHelper,
    isExternal,
  ]);

  const table = useReactTable<AuthorizedAdultFormRequest>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    enableColumnFilters: false,
    enableSorting: false,
    getRowId: ({ uid }) => uid as string,
  });

  const tableHeaderProps = useMemo(() => ({ wrap: true }), []);
  return (
    <div className="moc-form__authorized-adults-table">
      <ManagedContent
        name={
          isExternal
            ? ManagedContentName.ProgramsInvolvingMinorsAuthorizedAdultsExternal
            : ManagedContentName.ProgramsInvolvingMinorsAuthorizedAdults
        }
        featureName={FeatureName.MinorsOnCampusRegistrationForm}
      />
      <ManagedContent
        name={
          isExternal
            ? ManagedContentName.ExternalAuthorizedAdultDescription
            : ManagedContentName.EmoryAuthorizedAdultDescription
        }
        featureName={FeatureName.MinorsOnCampusRegistrationForm}
      />
      <Table table={table} tableHeaderProps={tableHeaderProps} />
      {!readonly && (
        <div className="d-flex justify-content-center align-items-center">
          <Button onClick={addRow} variant="info" className="text-white">
            <ManagedContent
              name={ManagedContentName.AddAuthorizedAdultButton}
              featureName={FeatureName.MinorsOnCampusRegistrationForm}
            />
          </Button>
        </div>
      )}
    </div>
  );
};

export default AuthorizedAdultTable;
