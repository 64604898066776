import { CacheProperties } from "axios-cache-interceptor";
import { BaseService } from "../../../shared/src/services";
import { getDefaultAxiosInstance } from "../../../shared/src/services/BaseService/AxiosService";
import {
  QTMContact,
  QTMContactFields,
} from "../../models/salesforce/QTMContact";
import { QTMFormRequest } from "../../models/QTMFormRequest";
import FormOption, {
  salesforcePicklistStringToFormOptions,
} from "../../../shared/src/components/Form/components/FormInput/models/FormOption";
import SalesforceResponse from "../../../shared/src/models/salesforce/SalesforceResponse";

class QTMContactService extends BaseService {
  protected static readonly BASE_PATH = "/api/myemory/v0/qtm/contacts";

  constructor(public readonly basePath: string) {
    super(getDefaultAxiosInstance());
  }

  public static getPath(path?: string): string {
    let fullPath = this.BASE_PATH;
    if (path) {
      if (!path.startsWith("/")) {
        fullPath += "/";
      }
      fullPath += path;
    }
    return fullPath;
  }

  public static async getQTMContactByPpid(
    ppid: string,
    cache?: false | Partial<CacheProperties<QTMContact, unknown>>
  ): Promise<QTMContact> {
    const response = await super.get<QTMContact>({
      url: this.getPath(ppid),
      config: { cache },
    });
    return response?.data || {};
  }

  public static async getPicklistByFieldName(
    fieldName: string
  ): Promise<FormOption[]> {
    const response = await super.get<FormOption[]>({
      url: `${this.BASE_PATH}/picklist-options/${fieldName}`,
    });
    return response?.data || [];
  }

  public static async validateAndSave(
    request: QTMContact,
    action: string
  ): Promise<SalesforceResponse[]> {
    const response = await super.post<SalesforceResponse[], QTMContact>({
      url: this.BASE_PATH,
      data: request,
      config: {
        cache: false,
        params: { action },
      },
    });
    return response?.data || [];
  }

  public static async mapQTMContactToQTMFormRequest(
    request: QTMContact
  ): Promise<QTMFormRequest> {
    const [
      areaOfIndustryInterestOptions,
      communityAffiliationsOptions,
      identityAffiliationsOptions,
    ] = await Promise.all(
      [
        QTMContactFields.AREA_OF_INDUSTRY_INTEREST,
        QTMContactFields.COMMUNITY_AFFILIATIONS,
        QTMContactFields.IDENTITY_AFFILIATIONS,
      ].map((fieldName) => this.getPicklistByFieldName(fieldName))
    );
    return {
      ...request,
      academicLevel: {
        label: request.academicLevel,
        value: request.academicLevel,
      },
      areaOfIndustryInterest: salesforcePicklistStringToFormOptions(
        request.areaOfIndustryInterest,
        areaOfIndustryInterestOptions
      ),
      communityAffiliations: salesforcePicklistStringToFormOptions(
        request.communityAffiliations,
        communityAffiliationsOptions
      ),
      identityAffiliations: salesforcePicklistStringToFormOptions(
        request.identityAffiliations,
        identityAffiliationsOptions
      ),
      planTrack: {
        label: request.planTrack?.name,
        value: request.planTrack,
      },
      pronouns: {
        label: request.pronouns,
        value: request.pronouns,
      },
      primaryPlan: {
        label: request.primaryPlan?.name,
        value: request.primaryPlan,
      },
      secondaryPlan: {
        label: request.secondaryPlan?.name,
        value: request.secondaryPlan,
      },
      studentStatus: {
        label: request.studentStatus,
        value: request.studentStatus,
      },
      termToGraduate: {
        label: request.termToGraduate,
        value: request.termToGraduate,
      },
      yearToGraduate: {
        label: request.yearToGraduate,
        value: request.yearToGraduate,
      },
    } as QTMFormRequest;
  }
}

export default QTMContactService;
