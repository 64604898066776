import { createContext } from "react";
import { FormContextProps } from "./FormContext";

export type FormCustomComponentContextProps = Pick<
  FormContextProps,
  "validateFields"
>;

export const FormCustomComponentContext =
  createContext<FormCustomComponentContextProps>({});
