import { CacheProperties } from "axios-cache-interceptor";
import { toFormOption } from "../../../../models/salesforce/MyEmoryContact";
import FormOption, {
  valueToFormOption,
} from "../../../shared/src/components/Form/components/FormInput/models/FormOption";
import SalesforceResponse from "../../../shared/src/models/salesforce/SalesforceResponse";
import { BaseService } from "../../../shared/src/services";
import { getDefaultAxiosInstance } from "../../../shared/src/services/BaseService/AxiosService";
import { MOCFormRequest } from "../../models/MOCFormRequest";
import AuthorizedAdult from "../../models/salesforce/AuthorizedAdult";
import Case, { CaseField, MOCStatus } from "../../models/salesforce/Case";

export type MOCRequestSaveAction =
  | "Create New Request"
  | "Update Request"
  | "Update Status";

class MOCRequestService extends BaseService {
  protected static readonly BASE_PATH = "/api/myemory/v0/moc/cases";

  constructor(public readonly basePath: string) {
    super(getDefaultAxiosInstance());
  }

  public static getPath(path?: string): string {
    let fullPath = this.BASE_PATH;
    if (path) {
      if (!path.startsWith("/")) {
        fullPath += "/";
      }
      fullPath += path;
    }
    return fullPath;
  }

  public static async validateAndSave<Case>(
    object: Case,
    action: MOCRequestSaveAction
  ): Promise<SalesforceResponse[]> {
    return this.save(object, { action });
  }

  protected static async save<Case>(
    object: Case,
    params: Record<string, string | boolean | undefined> = {}
  ): Promise<SalesforceResponse[]> {
    const response = await super.post<SalesforceResponse[], Case>({
      url: this.BASE_PATH,
      data: object,
      config: { params },
    });
    return response?.data || [];
  }

  public static async getMocCasesByIsApproverAndStatuses(
    isApprover: boolean,
    statuses: MOCStatus[]
  ): Promise<Case[]> {
    return this.getMocCasesForCurrentUser({ isApprover, statuses });
  }

  protected static async getMocCasesForCurrentUser(
    params: Record<string, string | boolean | MOCStatus[] | undefined> = {}
  ): Promise<Case[]> {
    const statuses = (params.statuses as MOCStatus[]) || [];
    const response = await super.get<Case[]>({
      url: this.BASE_PATH,
      config: {
        cache: false,
        params: { ...params, statuses: statuses.join(",") },
      },
    });
    return response?.data || [];
  }

  public static async getMocCaseById(
    id: string,
    cache?: false | Partial<CacheProperties<Case, unknown>>
  ): Promise<Case> {
    const response = await super.get<Case>({
      url: this.getPath(id),
      config: { cache },
    });
    return response?.data || {};
  }

  public static async getPicklistByFieldName(
    fieldName: string
  ): Promise<FormOption[]> {
    const response = await super.get<FormOption[]>({
      url: `${this.BASE_PATH}/picklist-options/${fieldName}`,
    });
    return response?.data || [];
  }

  public static async toMOCFormRequest({
    id,
    name,
    caseNumber,
    recordTypeId,
    additionalComments,
    completedAnnualTraining,
    contact,
    criminalBackgroundCheckLast4Yrs,
    emorySponsorOrAffiliated,
    locationOfProgramActivities,
    maximumAgeOfMinors,
    minimumAgeOfMinors,
    numberOfAuthorizedAdults,
    numberOfMinorParticipants,
    obtainedAssumptionOfRiskForm,
    obtainedInsuranceCoverage,
    programDescription,
    programEndDate,
    programMeetsMinimumStandard,
    programName,
    programOvernightStays,
    programStartDate,
    programSponsor,
    rejectionReason,
    schoolOrDepartment,
    schoolOrDepartmentOther,
    speedtype,
    status,
    authorizedAdults,
  }: Case): Promise<MOCFormRequest> {
    const [schoolOrDepartmentOptions] = await Promise.all(
      [CaseField.SCHOOL_OR_DEPARTMENT].map((fieldName) =>
        this.getPicklistByFieldName(fieldName)
      )
    );
    return {
      id,
      name,
      caseNumber,
      recordTypeId,
      completedAnnualTraining,
      additionalComments,
      contact: toFormOption(contact),
      contactId: contact?.id,
      criminalBackgroundCheckLast4Yrs,
      emorySponsorOrAffiliated,
      locationOfProgramActivities,
      maximumAgeOfMinors,
      minimumAgeOfMinors,
      numberOfAuthorizedAdults,
      numberOfMinorParticipants,
      obtainedAssumptionOfRiskForm,
      obtainedInsuranceCoverage,
      programDescription,
      programEndDate,
      programMeetsMinimumStandard,
      programName,
      programOvernightStays,
      programStartDate,
      programSponsor: toFormOption(programSponsor),
      programSponsorId: programSponsor?.id,
      rejectionReason,
      schoolOrDepartment: valueToFormOption(
        schoolOrDepartment,
        schoolOrDepartmentOptions
      ),
      schoolOrDepartmentOther,
      speedtype,
      status,
      authorizedAdults: authorizedAdults?.map((authorizedAdult) =>
        AuthorizedAdult.toAuthorizedAdultFormRequest(authorizedAdult)
      ),
    } as MOCFormRequest;
  }
}

export default MOCRequestService;
