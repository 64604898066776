import { FC, ReactElement } from "react";
import { FormOtherProps } from "../../models/FormOtherProps";
import FormSearchSelectOther, {
  FormSearchSelectOtherProps,
} from "../FormSearchSelectOther";

export type FormMultiSelectOtherProps = FormSearchSelectOtherProps &
  FormOtherProps;

const FormMultiSelectOther: FC<FormMultiSelectOtherProps> = (
  props: FormMultiSelectOtherProps
): ReactElement => {
  return <FormSearchSelectOther {...props} isMulti />;
};

export default FormMultiSelectOther;
