import { ReactElement, useEffect, useMemo, useState } from "react";
import { Form, FormControlProps } from "react-bootstrap";
import { DebouncedFormControlProps } from "../../../../../debounced-inputs/models/DebouncedInputProps";
import { FilterInputProps } from "../../models/FilterInputProps";
import {
  DEFAULT_CLIENT_FILTERING_DEBOUNCE_WAIT_MILLIS,
  DEFAULT_SERVER_FILTERING_DEBOUNCE_WAIT_MILLIS,
  FilterMeta,
} from "../../models/FilterMeta";

export type FormControlFilterProps<T> = FilterInputProps<T> & FormControlProps;

const FormControlFilter = <T,>({
  table,
  columnId,
  ...props
}: FilterInputProps<T>): ReactElement => {
  const tableManualFiltering = table.options.manualFiltering;
  const tableFilterInputProps = ((table.options.meta ?? {}) as FilterMeta)
    .filterProps as DebouncedFormControlProps;
  const columnFilterInputProps = (
    (table.getColumn(columnId)?.columnDef.meta ?? {}) as FilterMeta
  ).filterProps as DebouncedFormControlProps;

  const tableDebounceWaitMillis =
    tableFilterInputProps?.debounceProps?.waitMillis;
  const columnDebounceWaitMillis =
    columnFilterInputProps?.debounceProps?.waitMillis;
  const debounceWaitMillis = useMemo(() => {
    if (columnDebounceWaitMillis) {
      return columnDebounceWaitMillis;
    }

    if (tableDebounceWaitMillis) {
      return tableDebounceWaitMillis;
    }

    if (tableManualFiltering) {
      return DEFAULT_SERVER_FILTERING_DEBOUNCE_WAIT_MILLIS;
    }

    return DEFAULT_CLIENT_FILTERING_DEBOUNCE_WAIT_MILLIS;
  }, [columnDebounceWaitMillis, tableDebounceWaitMillis, tableManualFiltering]);

  const initialValue = table.getColumn(columnId)?.getFilterValue() as
    | string
    | undefined
    | null;
  const [value, setValue] = useState<string>(initialValue ?? "");

  useEffect(() => {
    setValue(initialValue ?? "");
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      table.getColumn(columnId)?.setFilterValue(value);
    }, debounceWaitMillis);

    return () => clearTimeout(timeout);
  }, [columnId, table, value, debounceWaitMillis]);

  return (
    <Form.Control
      className="form-control-filter"
      placeholder="Search"
      {...props}
      {...(tableFilterInputProps ?? {})}
      {...(columnFilterInputProps ?? {})}
      value={value}
      onChange={({ target: { value: newValue } }) => {
        setValue(newValue);
      }}
    />
  );
};

export default FormControlFilter;
