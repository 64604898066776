import moment from "moment";
import YesNoType from "../../../shared/src/pages/FormManager/models/YesNoType";
import { EventFormRequest } from "../EventFormRequest";
import { AlcoholType } from "./AlcoholType";
import { ClientType } from "./ClientType";
import { ContactMethodType } from "./ContactMethodType";
import { RoomSetupType } from "./RoomSetupType";
import { ServiceReferralType } from "./ServiceReferralType";
import { ServiceType } from "./ServiceType";

class EventReservationRequest {
  public readonly clientType?: ClientType;

  public readonly organization?: string;

  public readonly firstName?: string;

  public readonly lastName?: string;

  public readonly email?: string;

  public readonly phone?: string;

  public readonly preferredContactMethod?: ContactMethodType;

  public readonly onsiteContactName?: string;

  public readonly onsiteContactEmail?: string;

  public readonly onsiteContactPhone?: string;

  public readonly eventName?: string;

  public readonly estimatedNumberOfGuests?: number;

  public readonly eventDate?: string;

  public readonly eventStartTime?: string;

  public readonly eventEndTime?: string;

  public readonly clientArrivalTime?: string;

  public readonly clientDepartureTime?: string;

  public readonly isFoodOrDrinkServed?: boolean;

  public readonly caterer?: string;

  public readonly isAlcoholServed?: boolean;

  public readonly alcohol?: AlcoholType;

  public readonly roomSetup?: RoomSetupType;

  public readonly roomSetupDescription?: string;

  public readonly isAVRequired?: boolean;

  public readonly avDescription?: string;

  public readonly isMusicOrLivePerformance?: boolean;

  public readonly isDeliveries?: boolean;

  public readonly isYouthParticipants?: boolean;

  public readonly numberOfOfficersRequired?: number;

  public readonly numberOfChairsRequired?: number;

  public readonly numberOfPosterBoardsRequired?: number;

  public readonly additionalServices?: ServiceType[];

  public readonly additionalServicesReferrals?: ServiceReferralType[];

  public readonly additionalInformation?: string;

  public readonly speedtypeEvent?: string;

  public readonly activityCodeForEvent?: string;

  public readonly speedtypeAlcohol?: string;

  public readonly activityCodeForAlcohol?: string;

  public readonly treasurerAdvisorName?: string;

  public readonly treasurerAdvisorEmail?: string;

  public readonly treasurerAdvisorPhone?: string;

  constructor({
    clientType,
    organization,
    firstName,
    lastName,
    email,
    phone,
    preferredContactMethod,
    onsiteContactName,
    onsiteContactEmail,
    onsiteContactPhone,
    eventName,
    estimatedNumberOfGuests,
    eventDate,
    eventStartTime,
    eventEndTime,
    clientArrivalTime,
    clientDepartureTime,
    isFoodOrDrinkServed,
    caterer,
    isAlcoholServed,
    alcohol,
    roomSetup,
    roomSetupDescription,
    isAVRequired,
    avDescription,
    isMusicOrLivePerformance,
    isDeliveries,
    isYouthParticipants,
    numberOfOfficersRequired,
    numberOfChairsRequired,
    numberOfPosterBoardsRequired,
    additionalServices,
    additionalServicesReferrals,
    additionalInformation,
    speedtypeEvent,
    activityCodeForEvent,
    speedtypeAlcohol,
    activityCodeForAlcohol,
    treasurerAdvisorName,
    treasurerAdvisorEmail,
    treasurerAdvisorPhone,
  }: EventFormRequest) {
    this.clientType = clientType;
    this.organization = organization;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.preferredContactMethod = preferredContactMethod;
    this.onsiteContactName = onsiteContactName;
    this.onsiteContactEmail = onsiteContactEmail;
    this.onsiteContactPhone = onsiteContactPhone;
    this.eventName = eventName;
    this.estimatedNumberOfGuests = estimatedNumberOfGuests;
    this.eventDate = moment(eventDate).format("YYYY-MM-DD");
    this.eventStartTime = eventStartTime;
    this.eventEndTime = eventEndTime;
    this.clientArrivalTime = clientArrivalTime;
    this.clientDepartureTime = clientDepartureTime;
    this.isFoodOrDrinkServed = YesNoType.parseBoolean(isFoodOrDrinkServed);
    this.caterer = caterer;
    this.isAlcoholServed = YesNoType.parseBoolean(isAlcoholServed);
    this.alcohol = alcohol;
    this.roomSetup = roomSetup;
    this.roomSetupDescription = roomSetupDescription;
    this.isAVRequired = isAVRequired
      ? YesNoType.parseBoolean(isAVRequired)
      : undefined;
    this.avDescription = avDescription;
    this.isMusicOrLivePerformance = isMusicOrLivePerformance
      ? YesNoType.parseBoolean(isMusicOrLivePerformance)
      : undefined;
    this.isDeliveries = YesNoType.parseBoolean(isDeliveries);
    this.isYouthParticipants = isYouthParticipants
      ? YesNoType.parseBoolean(isYouthParticipants)
      : undefined;
    this.numberOfOfficersRequired = numberOfOfficersRequired;
    this.numberOfChairsRequired = numberOfChairsRequired;
    this.numberOfPosterBoardsRequired = numberOfPosterBoardsRequired;
    this.additionalServices = additionalServices;
    this.additionalServicesReferrals = additionalServicesReferrals;
    this.additionalInformation = additionalInformation;
    this.speedtypeEvent = speedtypeEvent;
    this.activityCodeForEvent = activityCodeForEvent;
    this.speedtypeAlcohol = speedtypeAlcohol;
    this.activityCodeForAlcohol = activityCodeForAlcohol;
    this.treasurerAdvisorName = treasurerAdvisorName;
    this.treasurerAdvisorEmail = treasurerAdvisorEmail;
    this.treasurerAdvisorPhone = treasurerAdvisorPhone;
  }
}

export default EventReservationRequest;
