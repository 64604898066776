import { FC, ReactElement } from "react";
import FormSearchSelect, { FormSearchSelectProps } from "../FormSearchSelect";

export type FormMultiSelectProps = FormSearchSelectProps;

const FormMultiSelect: FC<FormMultiSelectProps> = (
  props: FormMultiSelectProps
): ReactElement => {
  return <FormSearchSelect {...props} isMulti />;
};

export default FormMultiSelect;
