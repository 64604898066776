import MyEmoryContact from "../../../../models/salesforce/MyEmoryContact";
import { toYYYYMMDD } from "../../../../utils/DateUtil";
import type { EUSHIPFormRequest } from "../../../euship/models/EUSHIPFormRequest";
import SalesforceObject from "../../../shared/src/models/salesforce/SalesforceObject";
import type { MOCFormRequest } from "../MOCFormRequest";
import AuthorizedAdult from "./AuthorizedAdult";

class Case implements SalesforceObject {
  public readonly id?: string;

  public readonly name?: string;

  public readonly caseNumber?: string;

  public readonly clientType?: string;

  public readonly category?: string;

  public readonly description?: string;

  public readonly academicYear?: string;

  public readonly studentFirstName?: string;

  public readonly studentLastName?: string;

  public readonly studentId?: string;

  public readonly studentEmail?: string;

  public readonly requestorFirstName?: string;

  public readonly requestorLastName?: string;

  public readonly requestorEmail?: string;

  public readonly netid?: string;

  public readonly school?: string;

  public readonly recordTypeId?: string;

  public readonly additionalComments?: string;

  public readonly completedAnnualTraining?: boolean;

  public readonly contactId?: string;

  public readonly contact?: MyEmoryContact;

  public readonly criminalBackgroundCheckLast4Yrs?: boolean;

  public readonly emorySponsorOrAffiliated?: string;

  public readonly locationOfProgramActivities?: string;

  public readonly maximumAgeOfMinors?: number;

  public readonly minimumAgeOfMinors?: number;

  public readonly numberOfAuthorizedAdults?: number;

  public readonly numberOfMinorParticipants?: number;

  public readonly obtainedAssumptionOfRiskForm?: boolean;

  public readonly obtainedInsuranceCoverage?: boolean;

  public readonly programDescription?: string;

  public readonly programEndDate?: string;

  public readonly programMeetsMinimumStandard?: boolean;

  public readonly programName?: string;

  public readonly programOvernightStays?: string;

  public readonly programStartDate?: string;

  public readonly programSponsor?: MyEmoryContact;

  public readonly programSponsorId?: string;

  public readonly rejectionReason?: string;

  public readonly schoolOrDepartment?: string;

  public readonly schoolOrDepartmentOther?: string;

  public readonly speedtype?: string;

  public readonly status?: string;

  public authorizedAdults?: AuthorizedAdult[] = [];

  public fullyCertified?: boolean;

  constructor({
    id,
    name,
    caseNumber,
    recordTypeId,
    additionalComments,
    completedAnnualTraining,
    contact,
    criminalBackgroundCheckLast4Yrs,
    emorySponsorOrAffiliated,
    locationOfProgramActivities,
    maximumAgeOfMinors,
    minimumAgeOfMinors,
    numberOfAuthorizedAdults,
    numberOfMinorParticipants,
    obtainedAssumptionOfRiskForm,
    obtainedInsuranceCoverage,
    programDescription,
    programEndDate,
    programMeetsMinimumStandard,
    programName,
    programOvernightStays,
    programStartDate,
    programSponsor,
    rejectionReason,
    schoolOrDepartment,
    schoolOrDepartmentOther,
    speedtype,
    status,
    authorizedAdults,
  }: MOCFormRequest) {
    this.id = id;
    this.name = name;
    this.caseNumber = caseNumber;
    this.recordTypeId = recordTypeId;
    this.additionalComments = additionalComments;
    this.completedAnnualTraining = completedAnnualTraining;
    this.contactId = contact?.value.id;
    this.contact = contact?.value;
    this.criminalBackgroundCheckLast4Yrs = criminalBackgroundCheckLast4Yrs;
    this.emorySponsorOrAffiliated = emorySponsorOrAffiliated;
    this.locationOfProgramActivities = locationOfProgramActivities;
    this.maximumAgeOfMinors = maximumAgeOfMinors;
    this.minimumAgeOfMinors = minimumAgeOfMinors;
    this.numberOfAuthorizedAdults = numberOfAuthorizedAdults;
    this.numberOfMinorParticipants = numberOfMinorParticipants;
    this.obtainedAssumptionOfRiskForm = obtainedAssumptionOfRiskForm;
    this.obtainedInsuranceCoverage = obtainedInsuranceCoverage;
    this.programDescription = programDescription;
    this.programEndDate = toYYYYMMDD(programEndDate);
    this.programMeetsMinimumStandard = programMeetsMinimumStandard;
    this.programName = programName;
    this.programOvernightStays = programOvernightStays;
    this.programStartDate = toYYYYMMDD(programStartDate);
    this.programSponsor = programSponsor?.value;
    this.programSponsorId = programSponsor?.value.id;
    this.rejectionReason = rejectionReason;
    this.schoolOrDepartment = schoolOrDepartment?.value;
    this.schoolOrDepartmentOther = schoolOrDepartmentOther;
    this.speedtype = speedtype;
    this.status = status;
    this.authorizedAdults = authorizedAdults;
  }
}
export const mapEUSHIPFormRequestToEUSHIPCase = (
  request: EUSHIPFormRequest
): Case => {
  return {
    ...request,
    category: request.category?.value,
    academicYear: request.academicYear?.value,
    school: request.school?.value,
  };
};

export enum CaseField {
  CASE_NUMBER = "CaseNumber",
  RECORD_TYPE_ID = "RecordTypeId",
  ADDITIONAL_COMMENTS = "MOC_Additional_Comments",
  COMPLETED_ANNUAL_TRAINING = "MOC_Completed_Annual_Training__c",
  CONTACT_ID = "ContactId",
  CONTACT = "Contact",
  CRIMINAL_BACKGROUND_CHECK_LAST_4YRS = "MOC_Criminal_Background_Check_Last_4yrs__c",
  EMORY_SPONSOR_OR_AFFILIATED = "MOC_Emory_Sponsor_or_Affiliated__c",
  LOCATION_OF_PROGRAM_ACTIVITIES = "MOC_Location_of_Program_Activities__c",
  MAXIMUM_AGE_OF_MINORS = "MOC_Maximum_Age_of_Minors__c",
  MINIMUM_AGE_OF_MINORS = "MOC_Minimum_Age_of_Minors__c",
  NUMBER_OF_AUTHORIZED_ADULTS = "MOC_Number_of_Authorized_Adults__c",
  NUMBER_OF_MINOR_PARTICIPANTS = "MOC_Number_of_Minor_Participants__c",
  OBTAINED_ASSUMPTION_OF_RISK_FORM = "MOC_Obtained_Assumption_of_Risk_Form__c",
  OBTAINED_INSURANCE_COVERAGE = "MOC_Obtained_Insurance_Coverage__c",
  PROGRAM_DESCRIPTION = "MOC_Program_Description__c",
  PROGRAM_END_DATE = "MOC_Program_End_Date__c",
  PROGRAM_MEETS_MINIMUM_STANDARD = "MOC_Program_Meets_Minimum_Standard__c",
  PROGRAM_NAME = "MOC_Program_Name__c",
  PROGRAM_OVERNIGHT_STAYS = "MOC_Program_Overnight_Stays__c",
  PROGRAM_START_DATE = "MOC_Program_Start_Date__c",
  REJECTION_REASON = "MOC_Rejection_Reason__c",
  SCHOOL_OR_DEPARTMENT = "MOC_School_or_Department__c",
  SCHOOL_OR_DEPARTMENT_OTHER = "Department_Office_School__c",
  SPEEDTYPE = "MOC_Speedtype__c",
  STATUS = "Status",
}

export enum MOCStatus {
  APPROVED = "Approved",
  REJECTED = "Rejected",
  NEW = "New",
  CLOSED = "Closed",
}

export default Case;
