import SalesforceResponse from "../../../shared/src/models/salesforce/SalesforceResponse";
import { BaseService } from "../../../shared/src/services";
import { getDefaultAxiosInstance } from "../../../shared/src/services/BaseService/AxiosService";

class EUSHIPService extends BaseService {
  protected static readonly BASE_PATH = "/api/myemory/v0/euship/cases";

  constructor(public readonly basePath: string) {
    super(getDefaultAxiosInstance());
  }

  public static async validateAndSave<Case>(
    eushipCase: Case,
    action: string
  ): Promise<SalesforceResponse[]> {
    const response = await super.post<SalesforceResponse[], Case>({
      url: this.BASE_PATH,
      data: eushipCase,
      config: {
        cache: false,
        params: { action },
      },
    });
    return response?.data || [];
  }
}
export default EUSHIPService;
