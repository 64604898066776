import SalesforceResponse from "../../../models/salesforce/SalesforceResponse";
import SectionColumn from "../../../models/salesforce/SectionColumn";
import FormSalesforceObjectService from "../FormSalesforceObjectService";

class SectionColumnService extends FormSalesforceObjectService {
  private static readonly OBJECT_NAME = "section-columns";

  /**
   * Save section column
   * @param sectionColumn
   * @param depth depth of salesforce nested objects
   * @returns array of salesforce responses
   */
  public static async saveSectionColumn(
    sectionColumn: SectionColumn,
    depth: number | undefined = undefined
  ): Promise<SalesforceResponse[]> {
    return super.save<SectionColumn>(this.OBJECT_NAME, [sectionColumn], depth);
  }

  /**
   * Delete section column by id
   * @param sectionColumnId
   * @param depth depth of salesforce nested objects
   * @returns array of salesforce responses
   */
  public static async deleteSectionColumnById(
    sectionColumnId: string,
    depth: number | undefined = undefined
  ): Promise<SalesforceResponse[]> {
    return super.deleteById(this.OBJECT_NAME, [sectionColumnId], depth);
  }
}

export default SectionColumnService;
