import moment from "moment";
import { toast } from "react-toastify";
import { Person } from "../../../../models";

class LoginAsUserService {
  public static readonly LOGIN_AS_USER_CACHE_KEY = "loginAsUser";

  public static isLoginAsUser(): boolean {
    return !!LoginAsUserService.getLoginAsUser();
  }

  public static getLoginAsUserPpid(): string | undefined {
    const loginAsUser = LoginAsUserService.getLoginAsUser() ?? {};
    return loginAsUser.ppid;
  }

  public static getLoginAsUser(): Person | undefined {
    const loginAsUserCacheEntryAsText = localStorage.getItem(
      LoginAsUserService.LOGIN_AS_USER_CACHE_KEY
    );
    let loginAsUser;
    if (loginAsUserCacheEntryAsText) {
      const loginAsUserCacheEntry = JSON.parse(loginAsUserCacheEntryAsText);
      if (
        loginAsUserCacheEntry?.value &&
        !!Object.keys(loginAsUserCacheEntry?.value).length &&
        loginAsUserCacheEntry?.timestamp
      ) {
        const currentTimestamp = moment(new Date().getTime());
        const previousTimeStamp = moment(loginAsUserCacheEntry.timestamp);
        if (currentTimestamp.diff(previousTimeStamp, "hours") < 3) {
          loginAsUser = loginAsUserCacheEntry.value;
          // refresh cache time
          LoginAsUserService.setLoginAsUser(loginAsUser);
        } else {
          loginAsUser = undefined;
        }
      }
    }
    return loginAsUser;
  }

  public static setLoginAsUser(user: Person): void {
    localStorage.setItem(
      LoginAsUserService.LOGIN_AS_USER_CACHE_KEY,
      JSON.stringify({
        value: {
          ...user,
        },
        timestamp: new Date().getTime(),
      })
    );
  }

  public static beginLoginAsUser(user?: Person): void {
    if (user?.ppid) {
      this.setLoginAsUser(user);
      window.location.href = "/";
    } else {
      toast.error("Failed to login as user");
    }
  }

  public static logoutAsUser(): void {
    localStorage.removeItem(LoginAsUserService.LOGIN_AS_USER_CACHE_KEY);
    window.location.href = "/";
  }
}

export default LoginAsUserService;
