import FormInputOption from "../../../models/salesforce/FormInputOption";
import SalesforceResponse from "../../../models/salesforce/SalesforceResponse";
import FormSalesforceObjectService from "../FormSalesforceObjectService";

class FormInputOptionService extends FormSalesforceObjectService {
  private static readonly OBJECT_NAME = "form-input-options";

  /**
   * Save form input option
   * @param formInputOption
   * @param depth depth of salesforce nested objects
   * @returns array of salesforce responses
   */
  public static async saveFormInputOption(
    formInputOption: FormInputOption,
    depth: number | undefined = undefined
  ): Promise<SalesforceResponse[]> {
    const updatedFormInputOption = { ...formInputOption };
    if (typeof formInputOption.label === "string") {
      updatedFormInputOption.label = {
        id: formInputOption?.labelId,
        content: formInputOption.label,
      };
    }
    return super.save<FormInputOption>(
      this.OBJECT_NAME,
      [updatedFormInputOption],
      depth
    );
  }

  /**
   * Delete form input option by id
   * @param formInputOptionId
   * @param depth depth of salesforce nested objects
   * @returns array of salesforce responses
   */
  public static async deleteFormInputOptionById(
    formInputOptionId: string,
    depth: number | undefined = undefined
  ): Promise<SalesforceResponse[]> {
    return super.deleteById(this.OBJECT_NAME, [formInputOptionId], depth);
  }
}

export default FormInputOptionService;
