import React, { FC, PropsWithChildren, ReactElement } from "react";
import { Navbar, NavbarProps } from "react-bootstrap";
import "./styles.scss";

type ButtonsPanelProps = PropsWithChildren<NavbarProps> & {
  location: "top" | "bottom";
};

const ButtonsPanel: FC<ButtonsPanelProps> = ({
  location,
  children,
  ...navbarProps
}: ButtonsPanelProps): ReactElement => {
  return (
    <>
      {location === "top" ? (
        <div className="form-editor-buttons-panel">{children}</div>
      ) : (
        <Navbar
          expand
          fixed="bottom"
          className="form-editor-buttons-panel"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...navbarProps}
        >
          {children}
        </Navbar>
      )}
    </>
  );
};

export default ButtonsPanel;
