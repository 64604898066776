import { Table as ReactTable, RowData } from "@tanstack/react-table";
import { ReactElement, ReactNode, isValidElement, useMemo } from "react";
import {
  Table as BootstrapTable,
  TableProps as BootstrapTableProps,
} from "react-bootstrap";
import Pagination, {
  PaginationProps,
} from "../../../../react-table/components/Pagination";
import TableBody from "../TableBody";
import TableHeader, { TableHeaderProps } from "../TableHeader";

export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

export type PaginationPropsWithTotalSize = Required<
  Pick<PaginationProps, "totalSize">
> &
  Partial<Omit<PaginationProps, "totalSize">>;

export type TableProps<TData extends RowData> = BootstrapTableProps & {
  table: ReactTable<TData>;
  tableHeader?: ReactElement;
  tableHeaderProps?: Omit<TableHeaderProps<TData>, "table">;
  tableBody?: ReactElement;
  pagination?: ReactElement | PaginationPropsWithTotalSize;
};

const Table = <T,>({
  table,
  tableHeader,
  tableHeaderProps,
  tableBody,
  pagination: paginationProp,
  ...bootstrapTableProps
}: TableProps<T>): ReactElement => {
  const pagination = useMemo<ReactNode>(() => {
    if (paginationProp === undefined) {
      return undefined;
    }

    if (isValidElement(paginationProp)) {
      return paginationProp;
    }

    return (
      <Pagination
        className="mb-3"
        pageIndex={table.getState().pagination.pageIndex}
        pageSize={table.getState().pagination.pageSize}
        canPreviousPage={table.getCanPreviousPage()}
        canNextPage={table.getCanNextPage()}
        pageCount={table.getPageCount()}
        gotoPage={table.setPageIndex}
        nextPage={table.nextPage}
        previousPage={table.previousPage}
        setPageSize={table.setPageSize}
        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
        showGlobalFilter={false}
        {...(paginationProp as PaginationPropsWithTotalSize)}
      />
    );
  }, [paginationProp, table]);

  return (
    <div>
      {pagination}
      <BootstrapTable {...bootstrapTableProps}>
        {tableHeader ? (
          <>{tableHeader}</>
        ) : (
          <TableHeader table={table} {...tableHeaderProps} />
        )}
        {tableBody ? <>{tableBody}</> : <TableBody table={table} />}
      </BootstrapTable>
    </div>
  );
};

Table.defaultProps = {
  tableHeader: undefined,
  tableHeaderProps: {},
  tableBody: undefined,
  pagination: undefined,
};

export default Table;
