import { FC, ReactElement } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AppRouteProps } from "../../models";
import AppHeader, { AppHeaderProps } from "../AppHeader";

export interface Feature {
  id: string;
  label: string;
  path?: string;
  children?: Feature[];
}

export type AppRouterProps = AppHeaderProps & {
  routes: AppRouteProps[];
};

const AppRouter: FC<AppRouterProps> = ({
  routes,
  ...appHeaderProps
}: AppRouterProps): ReactElement => {
  return (
    <BrowserRouter
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
    >
      <Switch>
        {routes.map(({ id, exact, path, render, hideHeader }) => (
          <Route
            key={id}
            exact={exact}
            path={path}
            render={(props) => (
              <>
                {!hideHeader && <AppHeader {...appHeaderProps} />}
                {render?.(props)}
              </>
            )}
          />
        ))}
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
