import FormSection from "../../../models/salesforce/FormSection";
import SalesforceResponse from "../../../models/salesforce/SalesforceResponse";
import FormSalesforceObjectService from "../FormSalesforceObjectService";

class FormSectionService extends FormSalesforceObjectService {
  private static readonly OBJECT_NAME = "form-sections";

  /**
   * Save form section
   * @param formSection
   * @param depth depth of salesforce nested objects
   * @returns array of salesforce responses
   */
  public static async saveFormSection(
    formSection: FormSection,
    depth = 0
  ): Promise<SalesforceResponse[]> {
    return super.save<FormSection>(this.OBJECT_NAME, [formSection], depth);
  }

  /**
   * Delete form section by id
   * @param formSectionId
   * @param depth depth of salesforce nested objects
   * @returns array of salesforce responses
   */
  public static async deleteFormSectionById(
    formSectionId: string,
    depth: number | undefined = undefined
  ): Promise<SalesforceResponse[]> {
    return super.deleteById(this.OBJECT_NAME, [formSectionId], depth);
  }
}

export default FormSectionService;
