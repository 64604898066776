/* eslint-disable react/jsx-props-no-spreading */
import _ from "lodash";
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useState,
} from "react";
import { Col, Form, Row } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import FormInput from "../../../../components/Form/components/FormInput";
import FormLabel from "../../../../components/Form/components/FormLabel";
import { FormContext } from "../../../../components/Form/models/FormContext";
import { FormRequest } from "../../../../components/Form/models/FormRequest";
import ManagedContent from "../../../../components/ManagedContent";
import FormInputOption from "../../../../models/salesforce/FormInputOption";
import FormInputProperty from "../../../../models/salesforce/FormInputProperty";
import SalesforceManagedContent from "../../../../models/salesforce/ManagedContent";
import { FormComponentProps } from "../../models/FormComponentProps";
import FormInputType from "../../models/FormInputType";
import YesNoType from "../../models/YesNoType";
import FormInputOptions from "../FormInputOptions";
import FormInputProperties from "../FormInputProperties";

export type EditableFormInputProps = {
  id: string;
  formInput: FormComponentProps;
  setFormInput: (
    getFormInput: (previousFormInput: FormComponentProps) => FormComponentProps
  ) => void;
};

const EditableFormInput: FC<EditableFormInputProps> = ({
  id,
  formInput,
  setFormInput,
}: EditableFormInputProps): ReactElement => {
  const [initialRequest] = useState<FormRequest>(() => {
    return formInput?.salesforceObject as FormRequest;
  });

  const setFormInputSalesforceObject = (
    getSalesforceObject: (
      previousSalesforceObject: Record<string, unknown>
    ) => Record<string, unknown>
  ) => {
    if (getSalesforceObject) {
      setFormInput((previousFormInput) => {
        return {
          ...previousFormInput,
          salesforceObject: {
            ...(previousFormInput?.salesforceObject || {}),
            ...getSalesforceObject(previousFormInput?.salesforceObject || {}),
          },
        };
      });
    }
  };

  const setOptions = (
    getOptions: (previousOptions: FormInputOption[]) => FormInputOption[]
  ) => {
    if (getOptions) {
      setFormInput((previousFormInput) => ({
        ...previousFormInput,
        salesforceObject: {
          ...(previousFormInput?.salesforceObject || {}),
          options: getOptions(
            ((previousFormInput?.salesforceObject || {})
              .options as FormInputOption[]) || []
          ),
        },
      }));
    }
  };

  const setProperties = (
    getProperties: (
      previousProperties: FormInputProperty[]
    ) => FormInputProperty[]
  ) => {
    if (getProperties) {
      setFormInput((previousFormInput) => ({
        ...previousFormInput,
        salesforceObject: {
          ...(previousFormInput?.salesforceObject || {}),
          properties: getProperties(
            ((previousFormInput?.salesforceObject || {})
              .properties as FormInputProperty[]) || []
          ),
        },
      }));
    }
  };

  const labelAsObject = (
    <>
      <FormLabel label="Label" />
      <br />
      <ManagedContent
        editable
        managedContent={
          formInput?.salesforceObject?.label as SalesforceManagedContent
        }
        onUpdate={(updatedLabel) => {
          setFormInputSalesforceObject((previousSalesforceObject) => {
            return {
              ...previousSalesforceObject,
              label: updatedLabel,
            };
          });
        }}
      />
    </>
  );

  const labelAsString = (
    <FormInput id={`${id}-label`} name="label" label="Label" type="text" />
  );

  const renderedLabel = _.isObject(formInput?.salesforceObject?.label)
    ? labelAsObject
    : labelAsString;

  return (
    <FormContext.Provider
      value={{
        initialRequest,
        setRequest: setFormInputSalesforceObject as unknown as Dispatch<
          SetStateAction<FormRequest>
        >,
        disabled: false,
      }}
    >
      <fieldset>
        <Row className="mb-3">
          <Col>
            <FormInput
              id={`${id}-name`}
              name="name"
              label="Name"
              type="text"
              required
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>{renderedLabel}</Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <FormInput
              id={`${id}-description`}
              name="description"
              label="Description"
              type="text"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <FormInput
              id={`${id}-type`}
              name="type"
              label="Type"
              type="select"
              options={FormInputType.values().map(({ label }) => ({
                value: label,
                label,
              }))}
              required
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <FormLabel label="Is required?" required />
            <Form.Check
              key={uuid()}
              name="required"
              type="radio"
              label={YesNoType.YES.label}
              checked={formInput?.salesforceObject?.required as boolean}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                event.preventDefault();
                event.stopPropagation();
                setFormInputSalesforceObject((previousSalesforceObject) => {
                  return {
                    ...previousSalesforceObject,
                    required: !previousSalesforceObject.required,
                  };
                });
              }}
            />
            <Form.Check
              key={uuid()}
              name="required"
              type="radio"
              label={YesNoType.NO.label}
              checked={!formInput?.salesforceObject?.required}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                event.preventDefault();
                event.stopPropagation();
                setFormInputSalesforceObject((previousSalesforceObject) => {
                  return {
                    ...previousSalesforceObject,
                    required: !previousSalesforceObject.required,
                  };
                });
              }}
            />
          </Col>
        </Row>
        {FormInputType.getByLabel(formInput?.salesforceObject?.type as string)
          ?.hasOptions && (
          <>
            <Row className="mb-3">
              <Col>
                <FormInput
                  id={`${id}-optionsEndpoint`}
                  name="optionsEndpoint"
                  label="Options Endpoint"
                  type="text"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <FormInputOptions
                  options={
                    (formInput?.salesforceObject
                      ?.options as FormInputOption[]) || []
                  }
                  setOptions={setOptions}
                />
              </Col>
            </Row>
          </>
        )}
        <Row className="mb-3">
          <Col>
            <FormInputProperties
              properties={
                (formInput?.salesforceObject
                  ?.properties as FormInputProperty[]) || []
              }
              setProperties={setProperties}
            />
          </Col>
        </Row>
      </fieldset>
    </FormContext.Provider>
  );
};

export default EditableFormInput;
