import { BaseService } from "../..";
import ComponentProps from "../../../models/ComponentProps";

class ComponentService extends BaseService {
  private static readonly BASE_PATH = "/api/forms/v0/components";

  /**
   * Get form component by feature name
   * @param featureName
   * @param depth depth of salesforce nested objects
   * @returns form component
   */
  static async getFormComponentByFeatureName(
    featureName: string,
    depth: number | undefined = undefined
  ): Promise<ComponentProps> {
    const params: Record<string, string | number> = {
      featureName,
    };
    if (depth !== undefined) {
      params.depth = depth;
    }
    const response = await super.get<ComponentProps>({
      url: `${this.BASE_PATH}/forms`,
      config: { params },
    });
    return response?.data || {};
  }

  /**
   * Get form component by id
   * @param formId
   * @param depth depth of salesforce nested objects
   * @returns form component
   */
  static async getFormComponentById(
    formId: string,
    depth: number | undefined = undefined
  ): Promise<ComponentProps> {
    const params: Record<string, string | number> = {};
    if (depth !== undefined) {
      params.depth = depth;
    }
    const response = await super.get<ComponentProps>({
      url: `${this.BASE_PATH}/forms/${formId}`,
      config: {
        params,
        cache: false,
      },
    });
    return response?.data || {};
  }

  /**
   * Get form section by id
   * @param formSectionId
   * @param depth depth of salesforce nested objects
   * @returns form section component
   */
  static async getFormSectionComponentById(
    formSectionId: string,
    depth: number | undefined = undefined
  ): Promise<ComponentProps> {
    const params: Record<string, string | number> = {};
    if (depth !== undefined) {
      params.depth = depth;
    }
    const response = await super.get<ComponentProps>({
      url: `${this.BASE_PATH}/form-sections/${formSectionId}`,
      config: { params },
    });
    return response?.data || {};
  }

  /**
   * Get form input by id
   * @param formInputId
   * @param depth depth of salesforce nested objects
   * @returns form input component
   */
  static async getFormInputComponentById(
    formInputId: string,
    depth: number | undefined = undefined
  ): Promise<ComponentProps> {
    const params: Record<string, string | number> = {};
    if (depth !== undefined) {
      params.depth = depth;
    }
    const response = await super.get<ComponentProps>({
      url: `${this.BASE_PATH}/form-inputs/${formInputId}`,
      config: { params },
    });
    return response?.data || {};
  }
}

export default ComponentService;
