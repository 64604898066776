import { CacheAxiosResponse } from "axios-cache-interceptor";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AppInfo from "../../models/AppInfo";
import AppService from "../../services/AppService";
import useLoading from "../useLoading";
import useSafeAsync from "../useSafeAsync";

const useAppInfo = (): AppInfo => {
  const safeAsync = useSafeAsync();
  const [appInfo, setAppInfo] = useState<AppInfo>({
    name: "",
    version: "",
  });
  const loading = useLoading();

  useEffect(() => {
    loading(
      safeAsync(AppService.getAppInfo())
        .then((response) => response as CacheAxiosResponse<AppInfo>)
        .then((response) => {
          let { name, version } = response?.data || {
            name: "",
            version: "",
          };
          name = name ?? "";
          if (version) {
            version = version.startsWith("v") ? version : `v${version}`;
          } else {
            version = "";
          }
          setAppInfo({ name, version });
        })
        .catch(() => {
          toast.error("Failed to get app info", { autoClose: false });
        })
    );
  }, [loading, safeAsync]);

  return appInfo;
};

export default useAppInfo;
