import React, {
  Dispatch,
  FC,
  MouseEvent,
  MouseEventHandler,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import FormInput from "../../../../components/Form/components/FormInput";
import { FormContext } from "../../../../components/Form/models/FormContext";
import { FormRequest } from "../../../../components/Form/models/FormRequest";
import ManagedContentForm from "../../../../components/ManagedContent/components/ManagedContentForm";
import Modal from "../../../../components/Modal";
import useLoading from "../../../../hooks/useLoading";
import FormCustomComponent from "../../../../models/salesforce/FormCustomComponent";
import SalesforceFormInput from "../../../../models/salesforce/FormInput";
import FormInputOption from "../../../../models/salesforce/FormInputOption";
import FormInputProperty from "../../../../models/salesforce/FormInputProperty";
import SaleforceManagedContent from "../../../../models/salesforce/ManagedContent";
import SalesforceResponse from "../../../../models/salesforce/SalesforceResponse";
import FormCustomComponentService from "../../../../services/forms/FormCustomComponentService";
import FormInputService from "../../../../services/forms/FormInputService";
import ManagedContentService from "../../../../services/forms/ManagedContentService";
import { FormComponentProps } from "../../models/FormComponentProps";
import FormElementType from "../../models/FormElementType";
import EditableFormInput from "../EditableFormInput";

export type FormElementEditModalProps = {
  id: string;
  show: boolean;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onSubmit: (element: FormComponentProps) => void;
  element: FormComponentProps;
};

const FormElementEditModal: FC<FormElementEditModalProps> = ({
  id,
  show,
  onCancel,
  onSubmit,
  element,
}: FormElementEditModalProps) => {
  const [modalElement, setModalElement] = useState<FormComponentProps>({
    tag: "",
    props: {
      type: "",
    },
    layout: {
      i: "",
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    },
    salesforceObject: {},
  });
  const loading = useLoading();
  const [initialRequest] = useState<FormRequest>(
    () => element as unknown as FormRequest
  );

  useEffect(() => {
    setModalElement(element);
  }, [element]);

  const preprocessFormInputOptionsBeforeSave = (
    formInput: Record<string, unknown> | undefined
  ) => {
    const options = (formInput?.options as FormInputOption[]) || [];
    if (options.length > 0) {
      return {
        ...formInput,
        options: options.map((option) => {
          const updatedOption = { ...option };
          if (typeof updatedOption.label === "string") {
            updatedOption.label = {
              id: updatedOption?.labelId,
              content: updatedOption.label,
            };
          }
          if (updatedOption.clearIdBeforeSave) {
            updatedOption.id = undefined;
            delete updatedOption.clearIdBeforeSave;
          }
          return updatedOption;
        }),
      };
    }
    return formInput;
  };

  const preprocessFormInputPropertiesBeforeSave = (
    formInput: Record<string, unknown> | undefined
  ) => {
    const properties = (formInput?.properties as FormInputProperty[]) || [];
    if (properties.length > 0) {
      return {
        ...formInput,
        properties: properties.map((property) => {
          const updatedProperty = { ...property };
          if (updatedProperty.clearIdBeforeSave) {
            updatedProperty.id = undefined;
            delete updatedProperty.clearIdBeforeSave;
          }
          return updatedProperty;
        }),
      };
    }
    return formInput;
  };

  const handleSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
    loading(async () => {
      try {
        event.preventDefault();
        event.stopPropagation();
        let responses: SalesforceResponse[] = [];
        if (modalElement.tag === FormElementType.CUSTOM_COMPONENT.value) {
          responses = await FormCustomComponentService.saveFormCustomComponent(
            modalElement.salesforceObject as FormCustomComponent
          );
        } else if (modalElement.tag === FormElementType.FORM_INPUT.value) {
          let { salesforceObject: formInput } = modalElement;
          formInput = preprocessFormInputOptionsBeforeSave(formInput);
          formInput = preprocessFormInputPropertiesBeforeSave(formInput);
          responses = await FormInputService.saveFormInput(
            formInput as SalesforceFormInput,
            modalElement?.featureId
          );
        } else if (modalElement.tag === FormElementType.MANAGED_CONTENT.value) {
          responses = [
            await ManagedContentService.saveManagedContent(
              modalElement.salesforceObject as SaleforceManagedContent
            ),
          ];
        }
        if (responses.every(({ success }) => success)) {
          toast.success("Successfully saved form element");
          onSubmit({
            ...modalElement,
            salesforceObject: {
              ...modalElement.salesforceObject,
              id: responses[0].id,
            },
          });
          return;
        }
        [
          { message: "Failed to save form element" },
          ...responses.flatMap(({ errors }) => errors || []),
        ].forEach(({ message }) => toast.error(message));
      } catch {
        toast.error("Failed to save form element");
      }
    });
  };

  const renderFormByTag = (tag: string) => {
    switch (tag) {
      case FormElementType.CUSTOM_COMPONENT.value:
        return (
          <fieldset id={`${id}-custom-component`}>
            <Row className="mb-3">
              <Col>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  id={`${id}-custom-component-name`}
                  type="text"
                  value={(modalElement?.salesforceObject?.name as string) ?? ""}
                  onChange={({ target: { value } }) => {
                    setModalElement((previousModalElement) => ({
                      ...previousModalElement,
                      salesforceObject: {
                        ...previousModalElement.salesforceObject,
                        name: value,
                      },
                      tag,
                    }));
                  }}
                />
              </Col>
            </Row>
          </fieldset>
        );
      case FormElementType.FORM_INPUT.value:
        return (
          <EditableFormInput
            id={`${id}-form-input`}
            formInput={modalElement}
            setFormInput={(getFormInput) => {
              setModalElement((previousModalElement) => {
                return {
                  ...previousModalElement,
                  ...getFormInput(previousModalElement),
                  tag,
                };
              });
            }}
          />
        );
      case FormElementType.MANAGED_CONTENT.value:
        // initialize feature id on managed content
        if (
          !modalElement?.salesforceObject?.featureId &&
          modalElement?.featureId
        ) {
          setModalElement((previousModalElement) => {
            return {
              ...previousModalElement,
              salesforceObject: {
                ...previousModalElement.salesforceObject,
                featureId: modalElement?.featureId,
              },
              tag,
            };
          });
        }
        return (
          <ManagedContentForm
            id={`${id}-managed-content`}
            managedContent={
              modalElement?.salesforceObject as SaleforceManagedContent
            }
            setManagedContent={(getManagedContent) => {
              setModalElement((previousModalElement) => {
                return {
                  ...previousModalElement,
                  salesforceObject: {
                    ...previousModalElement.salesforceObject,
                    ...getManagedContent(
                      previousModalElement.salesforceObject as SaleforceManagedContent
                    ),
                  },
                  tag,
                };
              });
            }}
          />
        );
      default:
        return <>{tag ? <div>Unsupported tag found: {tag}</div> : <></>}</>;
    }
  };

  return (
    <Modal
      id={id}
      show={show}
      title="Edit Form Element"
      body={
        <>
          <FormContext.Provider
            value={{
              initialRequest,
              setRequest: setModalElement as unknown as Dispatch<
                SetStateAction<FormRequest>
              >,
              disabled: false,
            }}
          >
            <Row className="mb-3">
              <Col>
                <FormInput
                  id={`${id}-tag`}
                  name="tag"
                  label="Form Element Type"
                  type="select"
                  required
                  options={FormElementType.values()}
                />
              </Col>
            </Row>
          </FormContext.Provider>
          {renderFormByTag(modalElement?.tag)}
        </>
      }
      onCancel={onCancel}
      onSubmit={handleSubmit}
    />
  );
};

export default FormElementEditModal;
