import { FC, ReactElement } from "react";
import { Form } from "react-bootstrap";
import FormLabel from "../../../FormLabel";
import { OtherFormControlProps } from "../../models/FormOtherProps";
import FormControl from "../FormControl";
import FormValidation from "../FormValidation";

const OtherFormControl: FC<OtherFormControlProps> = (
  props: OtherFormControlProps
): ReactElement => {
  const {
    targetValue,
    label,
    validationMessage,
    invalid,
    infoProps,
    ...formControlProps
  } = props;

  return (
    <Form.Group>
      <>
        <FormLabel
          label={label as string}
          inline
          required={formControlProps?.required}
          requiredMessage={formControlProps?.requiredMessage}
          infoProps={infoProps}
        />
        <div className={invalid ? "form-invalid-input" : ""}>
          <FormControl {...formControlProps} />
        </div>
        {validationMessage && <FormValidation message={validationMessage} />}
      </>
    </Form.Group>
  );
};

export default OtherFormControl;
