import _ from "lodash";
import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Alert, Button, Card, Form } from "react-bootstrap";
import GridLayout, { Layout, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { FormContext } from "../../../../components/Form/models/FormContext";
import useLoading from "../../../../hooks/useLoading";
import SectionColumn, {
  SectionColumnType,
} from "../../../../models/salesforce/SectionColumn";
import SectionRow from "../../../../models/salesforce/SectionRow";
import SectionColumnService from "../../../../services/forms/SectionColumnService";
import SectionRowService from "../../../../services/forms/SectionRowService";
import { EditableFormSectionContext } from "../../models/EditableFormSectionContext";
import {
  convertFormRowsToFormComponentProps,
  FormComponentProps,
  sortFormComponentPropsArray,
} from "../../models/FormComponentProps";
import { FormEditorContext } from "../../models/FormEditorContext";
import FormElementType from "../../models/FormElementType";
import { MAX_COLUMNS_PER_SECTION_ROW } from "../../models/FormGridConfiguration";
import ButtonsPanel from "../ButtonsPanel";
import FormElement from "../FormElement";
import "./styles.scss";

const ReactGridLayout = WidthProvider(GridLayout);

const EditableFormSectionButton = {
  ADD_ROW: "editable-form-section-add-row-button",
  ADD_MULTIPLE_ROWS: "editable-form-section-add-multiple-rows-button",
};

const EditableFormSection: FC = (): ReactElement => {
  const { form, formSection, refreshForm, setRequest } =
    useContext(FormEditorContext);
  const [formElements, setFormElements] = useState<FormComponentProps[]>([]);
  const loading = useLoading();
  const [showAddMultipleRowsModal, setShowAddMultipleRowsModal] =
    useState<boolean>(false);
  const [numRowsToAdd, setNumRowsToAdd] = useState<number>(0);
  const [draggable, setDraggable] = useState<boolean>(false);
  const initialRequest = useMemo(() => ({}), []);

  useEffect(() => {
    setFormElements(
      convertFormRowsToFormComponentProps(
        (formSection?.content as FormComponentProps[]) || [],
        form?.salesforceObject?.featureId as string
      )
    );
  }, [form, formSection]);

  const getLayouts = () => {
    return formElements.map(({ layout }) => layout);
  };

  const getSectionRowById = (sectionRowId: string): SectionRow | undefined => {
    return (
      (formSection?.salesforceObject?.sectionRows as SectionRow[]) || []
    ).find(({ id }) => id === sectionRowId);
  };

  const getSectionColumnById = (
    sectionColumnId: string
  ): SectionColumn | undefined => {
    return ((formSection?.salesforceObject?.sectionRows as SectionRow[]) || [])
      .flatMap(({ sectionColumns }) => sectionColumns || [])
      .find(({ id }) => id === sectionColumnId);
  };

  const updateSectionRowsOrder = async (layouts: Layout[]) => {
    const sortedFormComponents = sortFormComponentPropsArray([
      ...layouts.map((layout) => {
        const targetFormElement = formElements.find(
          ({ layout: { i } }) => i === layout.i
        );
        if (targetFormElement) {
          return {
            ...targetFormElement,
            layout,
          };
        }
        return {
          layout,
          tag: "",
        };
      }),
    ]);
    const rowIds = _.uniq(
      sortedFormComponents.map(({ rowId }) => rowId as string)
    );
    let currentOrder = 1.0;
    const updatedSectionRows: SectionRow[] = [];
    rowIds.forEach((rowId) => {
      const targetSectionRow = getSectionRowById(rowId);
      if (targetSectionRow?.order !== currentOrder) {
        updatedSectionRows.push({
          ...targetSectionRow,
          order: currentOrder,
        });
      }
      currentOrder += 1;
    });
    if (updatedSectionRows.length > 0) {
      await Promise.all(
        updatedSectionRows.map((sectionRow) =>
          SectionRowService.saveSectionRow(sectionRow)
        )
      );
      refreshForm();
    }
  };

  const setLayouts = async (layouts: Layout[]) => {
    const oldLayoutRows = _.groupBy(
      formElements.map(({ layout }) => layout),
      "y"
    );
    const newLayoutRows = _.groupBy(layouts, "y");
    if (_.keys(newLayoutRows).length < _.keys(oldLayoutRows).length) {
      const targetRowKey = _.keys(newLayoutRows).find(
        (y) => newLayoutRows[y].length > oldLayoutRows[y].length
      );
      if (targetRowKey) {
        const newColumn = newLayoutRows[targetRowKey].find(
          ({ i: iNew }) =>
            !oldLayoutRows[targetRowKey].map(({ i }) => i).includes(iNew)
        );
        if (newColumn) {
          const otherColumnsLayouts = oldLayoutRows[targetRowKey].filter(
            ({ i }) => i !== newColumn.i
          );
          const otherElements = formElements.filter(({ layout: { i } }) =>
            otherColumnsLayouts.some(({ i: iOther }) => i === iOther)
          );
          const newElement = formElements.find(
            ({ layout: { i } }) => i === newColumn?.i
          );
          const otherSectionColumns = otherElements
            .map(({ columnId }) => getSectionColumnById(columnId as string))
            .filter((x) => x) as SectionColumn[];
          const targetSectionColumn = getSectionColumnById(
            newElement?.columnId as string
          );
          if (targetSectionColumn) {
            const maxOrder =
              _.max(otherSectionColumns.map(({ order }) => order)) ?? 0;
            const response = (
              await SectionColumnService.saveSectionColumn(
                {
                  ...targetSectionColumn,
                  order: maxOrder + 1, // TODO: fix this to place in correct order
                  sectionRowId: otherElements[0]?.rowId,
                },
                0
              )
            )[0];
            if (response?.success) {
              toast.success("Successfully updated layout");
              refreshForm();
            } else {
              [
                { message: "Failed to update layout" },
                ...(response?.errors || []),
              ].forEach(({ message }) => toast.error(message));
            }
          }
        }
      }
    }
    updateSectionRowsOrder(layouts);
  };

  const deleteSectionRowById = async (
    sectionRowId: string
  ): Promise<boolean> => {
    let allSuccess = true;
    await loading(async () => {
      try {
        const responses = await SectionRowService.deleteSectionRowById(
          sectionRowId
        );
        if (responses.some(({ success }) => !success)) {
          allSuccess = false;
          [
            { message: "Failed to delete section row" },
            ...responses.flatMap(({ errors }) => errors || []),
          ].forEach(({ message }) => toast.error(message));
        }
      } catch {
        allSuccess = false;
        toast.error("Failed to delete section row");
      }
    });
    return allSuccess;
  };

  const deleteSectionColumnById = async (
    sectionColumnId: string
  ): Promise<boolean> => {
    let allSuccess = true;
    await loading(async () => {
      try {
        const responses = await SectionColumnService.deleteSectionColumnById(
          sectionColumnId
        );
        if (responses.some(({ success }) => !success)) {
          allSuccess = false;
          [
            { message: "Failed to delete section column" },
            ...responses.flatMap(({ errors }) => errors || []),
          ].forEach(({ message }) => toast.error(message));
        }
      } catch {
        allSuccess = false;
        toast.error("Failed to delete section column");
      }
    });
    return allSuccess;
  };

  const deleteFormElement = async (formElement: FormComponentProps) => {
    const sectionRow = getSectionRowById(formElement.rowId as string);
    if (!sectionRow) {
      toast.error("Failed to find section row for form element");
      return;
    }
    let success;
    if ((sectionRow.sectionColumns || [])?.length <= 1) {
      success = await deleteSectionRowById(sectionRow.id as string);
    } else {
      success = await deleteSectionColumnById(formElement.columnId as string);
    }
    if (!success) {
      return;
    }
    refreshForm();
  };

  const addMultipleRows = async (numRows: number) => {
    if (numRows <= 0) {
      toast.error(`Invalid number of rows to add: ${numRows}`);
      return;
    }
    const maxSectionRowOrder =
      _.max(
        (
          (formSection?.salesforceObject?.sectionRows as SectionRow[]) || []
        ).map(({ order }) => order)
      ) ?? 0;
    const promises = _.range(0, numRows).map((idx) =>
      SectionRowService.saveSectionRow({
        name: uuid(),
        order: maxSectionRowOrder + idx + 1,
        formSectionId: formSection?.salesforceObject?.id as string,
        sectionColumns: [
          {
            name: uuid(),
            order: 1.0,
          },
        ],
      })
    );
    const responses = _.flatten(await Promise.all(promises));
    if (responses.every(({ success }) => success)) {
      toast.success(`Successfully added ${numRows} new row(s)`);
      setShowAddMultipleRowsModal(false);
      refreshForm();
    } else {
      [
        { message: `Error adding ${numRows} new rows` },
        ...responses.flatMap(({ errors }) => errors || []),
      ].forEach(({ message }) => {
        toast.error(message);
      });
    }
  };

  const handleAddEditFormElement = async ({
    tag,
    columnId,
    salesforceObject: formElementSalesforceObject,
  }: FormComponentProps) => {
    const sectionColumn = getSectionColumnById(columnId as string);
    if (!sectionColumn) {
      toast.error("Failed to find section column for form element");
      return;
    }
    let newSectionColumn = sectionColumn;
    if (
      tag === FormElementType.CUSTOM_COMPONENT.value &&
      formElementSalesforceObject?.id &&
      !sectionColumn.formCustomComponentId
    ) {
      newSectionColumn = {
        ...sectionColumn,
        formCustomComponentId: formElementSalesforceObject?.id as string,
      };
    } else if (
      tag === FormElementType.FORM_INPUT.value &&
      formElementSalesforceObject?.id &&
      !sectionColumn.formInputId
    ) {
      newSectionColumn = {
        ...sectionColumn,
        formInputId: formElementSalesforceObject?.id as string,
      };
    } else if (
      tag === FormElementType.MANAGED_CONTENT.value &&
      formElementSalesforceObject?.id &&
      !sectionColumn.contentId
    ) {
      newSectionColumn = {
        ...sectionColumn,
        contentId: formElementSalesforceObject?.id as string,
      };
    }
    const responses = await SectionColumnService.saveSectionColumn(
      {
        ...newSectionColumn,
        type:
          tag === "FormCustomComponent"
            ? "CustomComponent"
            : (tag as SectionColumnType),
      },
      0
    );
    if (responses.every(({ success }) => success)) {
      refreshForm();
    } else {
      [
        {
          message: "Failed to update section column with child id",
        },
        ...responses.flatMap(({ errors }) => errors || []),
      ].forEach(({ message }) => toast.error(message));
    }
  };

  const renderButtonsPanel = (location: "top" | "bottom") => (
    <ButtonsPanel location={location}>
      <Button
        id={EditableFormSectionButton.ADD_ROW}
        variant="info"
        className="me-2"
        onClick={() => addMultipleRows(1)}
      >
        Add Row
      </Button>
      <Button
        id={EditableFormSectionButton.ADD_MULTIPLE_ROWS}
        variant="secondary"
        className="me-2"
        onClick={() => setShowAddMultipleRowsModal(true)}
      >
        Add Multiple Rows
      </Button>
    </ButtonsPanel>
  );

  const renderGrid = () => {
    if (getLayouts().length === 0) {
      return (
        <Alert variant="info" className="text-center mt-3">
          No Form Elements Defined
        </Alert>
      );
    }

    return (
      <FormContext.Provider
        value={{ initialRequest, setRequest, disabled: false }}
      >
        <EditableFormSectionContext.Provider
          value={{
            setGridLayoutDraggable: setDraggable,
          }}
        >
          <ReactGridLayout
            className="editable-form-section card mt-3"
            cols={MAX_COLUMNS_PER_SECTION_ROW}
            layout={getLayouts()}
            isDraggable={!showAddMultipleRowsModal && draggable}
            onLayoutChange={(layouts) => {
              if (layouts?.length) {
                setLayouts(layouts);
              }
            }}
          >
            {formElements.map((formElement) => {
              const {
                layout: { i: layoutKey },
                salesforceObject,
              } = formElement;
              return (
                <Card
                  key={layoutKey}
                  id={layoutKey}
                  className="overflow-scroll"
                >
                  <FormElement
                    id={salesforceObject?.id as string}
                    element={formElement}
                    handleDelete={() => deleteFormElement(formElement)}
                    handleAddEdit={handleAddEditFormElement}
                  />
                </Card>
              );
            })}
          </ReactGridLayout>
        </EditableFormSectionContext.Provider>
      </FormContext.Provider>
    );
  };

  return (
    <>
      {renderButtonsPanel("top")}
      {renderGrid()}
      {renderButtonsPanel("bottom")}
      {showAddMultipleRowsModal && (
        <ConfirmationModal
          id="editable-form-section-add-multiple-rows-modal"
          confirmButtonProps={{
            variant: "primary",
          }}
          show
          title="Add Multiple Rows"
          body={
            <Form.Group controlId="editable-form-section-add-multiple-rows-modal-numRowsToAdd">
              <Form.Label>Number of rows to add</Form.Label>
              <Form.Control
                type="number"
                name="numRowsToAdd"
                value={numRowsToAdd}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setNumRowsToAdd(parseInt(value, 10));
                }}
              />
            </Form.Group>
          }
          onCancel={() => setShowAddMultipleRowsModal(false)}
          onConfirm={() => addMultipleRows(numRowsToAdd)}
        />
      )}
    </>
  );
};

export default EditableFormSection;
