/* eslint-disable react/jsx-props-no-spreading */
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, {
  ChangeEvent,
  ElementType,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { Alert, Button, Card } from "react-bootstrap";
import GridLayout, { Layout, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { Link, LinkProps } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import useLoading from "../../../../hooks/useLoading";
import { ComponentProps } from "../../../../models";
import FormSection from "../../../../models/salesforce/FormSection";
import ComponentService from "../../../../services/forms/ComponentService";
import FormSectionService from "../../../../services/forms/FormSectionService";
import {
  convertFormSectionsToFormComponentProps,
  FormComponentProps,
  sortFormComponentPropsArray,
} from "../../models/FormComponentProps";
import { FormEditorContext } from "../../models/FormEditorContext";
import { MAX_COLUMNS_PER_FORM_SECTION } from "../../models/FormGridConfiguration";
import FormManagerPath from "../../models/FormManagerPath";
import ButtonsPanel from "../ButtonsPanel";
import EditableFormControl from "../EditableFormControl";
import FormSectionAddModal from "../FormSectionAddModal";
import "./styles.scss";

const ReactGridLayout = WidthProvider(GridLayout);

const EditableFormButton = {
  ADD_SECTION: "editable-form-add-section-button",
};

const EditableForm: FC = (): ReactElement => {
  const { formId, form, refreshForm } = useContext(FormEditorContext);
  const [formSections, setFormSections] = useState<FormComponentProps[]>([]);
  const [formSectionIdToDelete, setFormSectionIdToDelete] = useState<string>();
  const [showFormSectionAddModal, setShowFormSectionAddModal] =
    useState<boolean>(false);
  const loading = useLoading();

  useEffect(() => {
    const formContent = form?.content as ComponentProps[] | [];
    if (formContent?.length) {
      setFormSections(
        convertFormSectionsToFormComponentProps(
          formContent,
          form?.salesforceObject?.featureId as string
        )
      );
    }
  }, [form]);

  const getLayouts = () => formSections.map(({ layout }) => layout);

  const setLayouts = async (layouts: Layout[]) => {
    let currentOrder = 1.0;
    const updatedComponents: FormComponentProps[] = [];
    sortFormComponentPropsArray([
      ...layouts.map((layout) => {
        const formSection = formSections.find(
          ({ layout: { i } }) => i === layout.i
        );
        if (formSection) {
          return {
            ...formSection,
            layout,
          };
        }
        return {
          layout,
          tag: "FormSection",
        };
      }),
    ]).forEach((formComponent) => {
      if (formComponent?.salesforceObject?.order !== currentOrder) {
        updatedComponents.push({
          ...formComponent,
          salesforceObject: {
            ...(formComponent?.salesforceObject || {}),
            order: currentOrder,
          },
        });
      }
      currentOrder += 1;
    });
    if (updatedComponents.length > 0) {
      await Promise.all(
        updatedComponents.map(({ salesforceObject }) =>
          FormSectionService.saveFormSection(salesforceObject as FormSection)
        )
      );
      refreshForm();
    }
  };

  const deleteFormSectionById = async (formSectionId: string) => {
    const responses = await FormSectionService.deleteFormSectionById(
      formSectionId
    );
    if (responses.every(({ success }) => success)) {
      const {
        layout: { i: layoutKey },
      } = formSections.find(
        ({ salesforceObject }) => salesforceObject?.id === formSectionId
      ) || { layout: {} };
      const targetLayout = getLayouts().find(({ i }) => i === layoutKey);
      if (targetLayout) {
        // update row index for other layouts
        const otherLayouts = getLayouts().filter(({ i }) => i !== layoutKey);
        if (otherLayouts.every(({ y }) => y !== targetLayout.y)) {
          setLayouts(
            otherLayouts.map((layout) =>
              layout.y > targetLayout.y
                ? {
                    ...layout,
                    y: layout.y - 1,
                  }
                : layout
            )
          );
        }
        setFormSections((previousFormSections) => {
          const targetFormSection = previousFormSections.find(
            ({ layout: { i } }) => i === layoutKey
          );
          if (targetFormSection) {
            const otherFormSections = previousFormSections.filter(
              ({ layout: { i } }) => i !== layoutKey
            );
            return sortFormComponentPropsArray(otherFormSections);
          }
          return previousFormSections;
        });
      }
    }
    return responses;
  };

  const setFormSectionField = (
    layoutKey: string,
    field: string,
    value: string
  ) => {
    setFormSections((previousFormSections) => {
      const targetFormSection = previousFormSections.find(
        ({ layout: { i: key } }) => key === layoutKey
      );
      if (targetFormSection) {
        return sortFormComponentPropsArray([
          ...previousFormSections.filter(
            ({ layout: { i: key } }) => key !== layoutKey
          ),
          {
            ...targetFormSection,
            salesforceObject: {
              ...targetFormSection?.salesforceObject,
              [field]: value,
              modified: true,
            },
          },
        ]);
      }
      return previousFormSections;
    });
  };

  const handleConfirmDeleteFormSection = async (formSectionId: string) => {
    loading(async () => {
      try {
        setFormSectionIdToDelete(undefined);
        const responses = await deleteFormSectionById(formSectionId as string);
        if (responses.every(({ success }) => success)) {
          toast.success("Successfully deleted form section");
          setFormSectionIdToDelete(undefined);
        } else {
          setFormSectionIdToDelete(formSectionId);
          [
            { message: "Failed to delete form section" },
            ...responses.flatMap(({ errors }) => errors || []),
          ].forEach(({ message }) => {
            toast.error(message);
          });
        }
      } catch {
        toast.error("Failed to delete form section");
      }
    });
  };

  const handleSubmitAddFormSection = async (formSection: FormSection) => {
    const formSectionComponentProps =
      await ComponentService.getFormSectionComponentById(
        formSection.id as string
      );
    const yMax = _.max(getLayouts().map(({ y }) => y));
    setFormSections((previousFormSections) => {
      return sortFormComponentPropsArray([
        ...previousFormSections,
        ...convertFormSectionsToFormComponentProps(
          [formSectionComponentProps],
          form?.salesforceObject?.featureId as string,
          yMax
        ),
      ]);
    });
    setShowFormSectionAddModal(false);
    toast.success("Successfully added form section");
  };

  const handleSaveModifiedFormSectionOnBlur = async (layoutKey: string) => {
    const targetFormSection = formSections.find(
      ({ layout: { i: key } }) => key === layoutKey
    );
    if (targetFormSection?.salesforceObject?.modified) {
      await FormSectionService.saveFormSection(
        targetFormSection?.salesforceObject
      );
      refreshForm();
    }
  };

  const buttons = (
    <>
      <Button
        id={EditableFormButton.ADD_SECTION}
        variant="primary"
        className="me-2"
        onClick={() => setShowFormSectionAddModal(true)}
      >
        Add Section
      </Button>
    </>
  );

  return (
    <>
      <ButtonsPanel location="top">{buttons}</ButtonsPanel>
      {getLayouts().length === 0 ? (
        <Alert variant="info" className="text-center mt-3">
          No Sections Defined
        </Alert>
      ) : (
        <>
          <ReactGridLayout
            className="editable-form card mt-3"
            cols={MAX_COLUMNS_PER_FORM_SECTION}
            rowHeight={200}
            layout={getLayouts()}
            isDraggable={!formSectionIdToDelete && !showFormSectionAddModal}
            onLayoutChange={(layouts) => {
              if (layouts?.length) {
                setLayouts(layouts);
              }
            }}
          >
            {formSections.map((formSection) => {
              const {
                layout: { i: layoutKey },
                salesforceObject,
              } = formSection;
              return (
                <Card key={layoutKey} id={layoutKey}>
                  <Card.Header>
                    <EditableFormControl
                      id={`form-section-name-${layoutKey}`}
                      placeholder="Name"
                      value={(salesforceObject?.name as string) ?? ""}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        event.preventDefault();
                        event.stopPropagation();
                        const {
                          target: { value },
                        } = event;
                        setFormSectionField(layoutKey, "name", value);
                      }}
                      onBlur={() =>
                        handleSaveModifiedFormSectionOnBlur(layoutKey)
                      }
                    />
                  </Card.Header>
                  <Card.Body>
                    <EditableFormControl
                      id={`form-section-description-${layoutKey}`}
                      as="textarea"
                      placeholder="Description"
                      style={{ resize: "none" }}
                      value={(salesforceObject?.description as string) ?? ""}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                        event.preventDefault();
                        event.stopPropagation();
                        const {
                          target: { value },
                        } = event;
                        setFormSectionField(layoutKey, "description", value);
                      }}
                      onBlur={() =>
                        handleSaveModifiedFormSectionOnBlur(layoutKey)
                      }
                    />
                    <div className="d-flex justify-content-center mt-3">
                      <Button
                        variant="danger"
                        className="me-1"
                        onClick={() =>
                          setFormSectionIdToDelete(
                            salesforceObject?.id as string
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                      <Button
                        variant="info"
                        as={
                          ((linkProps: LinkProps) => (
                            <Link
                              {...linkProps}
                              to={FormManagerPath.formSection(
                                formId,
                                (salesforceObject as { id: string })?.id
                              )}
                            />
                          )) as ElementType & keyof JSX.IntrinsicElements
                        }
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              );
            })}
          </ReactGridLayout>
        </>
      )}
      <ButtonsPanel location="bottom">{buttons}</ButtonsPanel>
      {formSectionIdToDelete && (
        <ConfirmationModal
          id="editable-form-delete-form-section-modal"
          confirmButtonProps={{
            variant: "danger",
          }}
          show={formSectionIdToDelete !== undefined}
          title="Delete Form Section"
          body="Are you sure you want to delete this form section?"
          onCancel={() => setFormSectionIdToDelete(undefined)}
          onConfirm={() =>
            handleConfirmDeleteFormSection(formSectionIdToDelete)
          }
        />
      )}
      {showFormSectionAddModal && (
        <FormSectionAddModal
          id="editable-form-add-form-section-modal"
          formId={formId}
          order={
            (_.max(
              formSections.map(
                ({ salesforceObject }) =>
                  (salesforceObject?.order as number) || -1
              )
            ) || -1) + 1
          }
          show={showFormSectionAddModal}
          onCancel={() => setShowFormSectionAddModal(false)}
          onSubmit={handleSubmitAddFormSection}
        />
      )}
    </>
  );
};

export default EditableForm;
