import FormOption from "../../modules/shared/src/components/Form/components/FormInput/models/FormOption";
import Contact from "../../modules/shared/src/models/salesforce/Contact";

type MyEmoryContact = Contact &
  Partial<{
    contactRole?: string;
    contactRoleText?: string;
    contactType?: string;
    title?: string;
    preferredEmail?: string;
    preferredPhone?: string;
    officePhone?: string;
    alternateEmail?: string;
    alternateOfficePhone?: string;
    cellPhone?: string;
    eraCommons?: string;
    orcId?: string;
    studentId?: string;
  }>;

export default MyEmoryContact;

export const formatLastNameFirstName = (
  firstName: string | undefined,
  lastName: string | undefined
): string => {
  if (firstName && lastName) {
    return `${lastName}, ${firstName}`;
  }
  if (firstName) {
    return firstName;
  }
  if (lastName) {
    return lastName;
  }
  return "";
};

export const toFormOption = (
  contact?: MyEmoryContact
): FormOption<MyEmoryContact> | undefined => {
  if (contact) {
    return {
      label: contact.name,
      value: { ...contact },
    } as FormOption<MyEmoryContact>;
  }
  return undefined;
};
