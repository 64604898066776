/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, ReactElement } from "react";
import { ComponentProps } from "../../../../models";
import { renderContent } from "../../../Component/ComponentUtil";

export interface FormSectionProps {
  id: string;
  content: ComponentProps | ComponentProps[];
}

const FormSection: FC<FormSectionProps> = ({
  content,
  ...props
}: FormSectionProps): ReactElement => {
  return <fieldset {...props}>{renderContent(content)}</fieldset>;
};

export default FormSection;
