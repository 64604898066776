import { CacheAxiosResponse } from "axios-cache-interceptor";
import AppInfo from "../../models/AppInfo";
import BaseService from "../BaseService";

class AppService extends BaseService {
  static getAppInfo(): Promise<CacheAxiosResponse<AppInfo>> {
    return super.get<AppInfo>({ url: "/api/app-info" });
  }
}

export default AppService;
